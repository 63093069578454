import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { watchCollection } from '../../api/document-api/DocumentAPI';

/**
 * A general purpose autocomplete.
 * @param className An optional css classname
 * @param keyName The property keyname to use as the display/value within the dropdown
 * @param label The dropdown label name
 * @param selected The currently selected autocomplete option
 * @param sortKey The key to sort the ordering of the returned results
 * @param path The collection path to watch
 * @param onChange The handler for the event when the user changes their option.
 * @constructor
 */
const GenericAutocomplete = function (
  {
    className = '', keyName, label, selected, sortKey, path, onChange,
  }:
  {
    // eslint-disable-next-line react/require-default-props
    className?: string,
    keyName: string,
    label: string,
    selected: any,
    sortKey: string,
    path:string,
    onChange: (_ : SyntheticEvent, value: any) => void },
) {
  const [genericResults, setGenericResults] = useState<{}[]>([]);

  useEffect(() => {
    const unsubscribe = watchCollection(() => {}, setGenericResults, path);
    return () => unsubscribe();
  }, []);

  const sortName = (a: any, b: any) => (a[sortKey]).localeCompare((b[sortKey]), 'en');

  return (
    <Autocomplete
      className={`mt-2 ${className}`}
      disablePortal
      id={`auto-generic-select-${label}`}
      noOptionsText="Loading please wait..."
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
      value={selected}
      onChange={onChange}
      getOptionLabel={(currentData:any) => currentData[keyName] || ''}
      options={genericResults.sort(sortName)}
    />
  );
};

export default GenericAutocomplete;
