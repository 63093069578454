import React from 'react';
import { titleCase, translate } from '../../api/string-api/StringAPI';

/**
 * TitleSubtitle is a helper method designed to provide a consistent stylized
 * Title and subtitle pair. Allows for global modification of titles when necessary.
 * @param title The title of the page.
 * @param subtitle The subtitle of the page
 * @param titleSize The size of the title using tailwind css
 * @param subtitleSize The size of the subtitle using tailwind css
 * @param removeDivider remove the dividingLine
 * @constructor
 */
const TitleSubtitle = function (
  {
    title, subtitle, titleSize = 'text-3xl', subtitleSize = 'text-lg', removeDivider = false,
  }:
  // eslint-disable-next-line react/require-default-props
  { title:string, subtitle:string, titleSize?:string, subtitleSize?: string, removeDivider?: boolean },
) {
  return (
    <div>
      <span className={`font-medium ${titleSize}`}>{titleCase(translate(title))}</span>
      { !removeDivider
        ? (<hr className="mt-1 mb-2" />)
        : (<br />)}
      <span className={`${subtitleSize}`}>{translate(subtitle)}</span>
    </div>
  );
};

export default TitleSubtitle;
