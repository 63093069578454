import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { titleCase, translate } from '../../../api/string-api/StringAPI';

/**
 * Select roles takes a list of available roles and displays them over a checkbox selection
 * The checkboxes will be selected by default given the roles on the user in selectedUser
 * So if selectedUser has 'admin' then the 'admin' checkbox will be selected
 * @param roleResults
 * @constructor
 */
const SelectRoles = function (
  {
    availableRoles, userRoles, setUserRoles,
  } :
  {
    availableRoles: { [key:string]: string[] },
    userRoles: string[],
    setUserRoles: (arg0: string[]) => void
  },
) {
  // Whenever you check a box, adjust the selectedRoles to be the new list of roles
  return (
    <div className="flex">
      {Object.keys(availableRoles).sort().map((roleCategory) => (
        <FormGroup key={roleCategory} className="ml-12">
          <span className="text-lg">
            {`${translate('ADMIN_SELECT_ROLES')} - ${roleCategory}`}
            <hr />
          </span>
          {availableRoles[roleCategory].map((role) => (
            <FormControlLabel
              key={role}
              control={(
                <Checkbox
                  checked={(!!userRoles && userRoles.includes(role))}
                  onChange={(event) => (
                    event.target.checked
                      ? setUserRoles([...userRoles, role])
                      : setUserRoles(userRoles.filter((setRole) => setRole !== role))
                  )}
                />
              )}
              label={titleCase(role)}
            />
          ))}
        </FormGroup>
      ))}
    </div>
  );
};

export default SelectRoles;
