import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Container, TitleSubtitle } from '../../helper';
import { staggeredEntryContainer, staggeredEntryItem } from '../../api/framer-motion-api/FramerMotionAPI';
import SelectEngine from './select-engine/SelectEngine';
import SelectDependent from './select-dependent/SelectDependent';
import { OptionType } from '../../api/types/AssociationTypes';
import { getDocument } from '../../api/document-api/DocumentAPI';
import UpdateAssociation from './update-association/UpdateAssociation';
import SubmitCloudButton from '../submit-cloud-button/SubmitCloudButton';
import DeleteAssociations from './delete-associations/deleteAssociations';

/**
 * The AdjustAssociation component is an administrative component designed to allow Admin/Super Admin users to adjust
 * Associations made by other users. These associations can be searched, one at a time, to be modified for each
 * key value pair.
 * @constructor
 */
const AdjustAssociation = function () {
  const [engine, setEngine] = useState<string | null>('');
  const [filter, setFilter] = useState<{ [key:string]: any } | null>(null);
  const [matchedDoc, setMatchedDoc] = useState<{ [key:string]: any } | null>(null);
  const [matchFilterField, setMatchFilterField] = useState<string>('');
  const [matchDependents, setMatchDependents] = useState<{ [key: string]: string | null }>({});
  const [optionLookup, setOptionLookup] = useState<{ [key: string]: OptionType[] }>({});
  const [removeAssociation, setRemoveAssociation] = useState<boolean>(false);
  const [updatedPacket, setUpdatedPacket] = useState<{}>({});

  const updateMatchDoc = (key: string, value: any) => {
    setMatchedDoc({ ...matchedDoc, [key]: value });
  };

  const updateOptionLookup = (key: string, value: OptionType[]) => {
    const newUpdate = {
      ...optionLookup,
      [key]: value,
    };
    setOptionLookup(newUpdate);
    return newUpdate;
  };

  /*
  The Rejection handler is used for the optional prop by SubmitButton.
  It validates that necessary information is present before submitting.
 */
  const rejectionHandler = () => !(engine && matchedDoc);

  useEffect(() => {
    setFilter(null);
    setMatchedDoc(null);
    if (engine) {
      getDocument('engine', engine)
        .then((r: any) => {
          setMatchDependents(r.matchDeps);
          setMatchFilterField(r.filterField);
        });
    }
  }, [engine]);

  useEffect(() => {
    if (!matchedDoc) return;
    const updateObject: { [key:string]: any } = {};
    Object.entries(matchedDoc).forEach(([key, value]) => {
      updateObject[key] = value?.value || value;
    });
    delete updateObject.pdocId;
    setUpdatedPacket(updateObject);
  }, [matchedDoc]);

  return (
    <Container>
      <motion.div
        variants={staggeredEntryContainer}
        initial="hidden"
        animate="visible"
      >
        <motion.ol className="list-none">
          <motion.li variants={staggeredEntryItem}>
            {/* Title and Subtitle */}
            <TitleSubtitle title="ADMIN_MATCHES_TITLE" subtitle="ADMIN_MATCHES_SUBTITLE" />
          </motion.li>
          <motion.li variants={staggeredEntryItem}>
            <SelectEngine engine={engine} setEngine={setEngine} />
          </motion.li>
          <motion.li variants={staggeredEntryItem}>
            { engine && (

            <SelectDependent
              collectionPath={`engine/${engine}/associations`}
              depKey={(engineKey: string) => `${engineKey}dep`}
              engine={engine}
              selectValue={filter}
              label="Dependent Of"
              optionLookup={optionLookup}
              optionsLabelField="label"
              setValue={setFilter}
              updateOptionLookup={updateOptionLookup}
              queries={[{ fieldPath: 'dependentOf', opString: '==', value: null }]}
              useEffectRerender={engine}
            />
            )}

          </motion.li>
          <motion.li variants={staggeredEntryItem}>
            { engine && matchFilterField && filter && (
              <SelectDependent
                collectionPath={`engine/${engine}/matches`}
                depKey={(engineKey: string) => `${engineKey}match${filter.label}`}
                engine={engine}
                selectValue={matchedDoc}
                label={`Match - ${filter.label}`}
                optionLookup={optionLookup}
                optionsLabelField="pdocId"
                setValue={setMatchedDoc}
                updateOptionLookup={updateOptionLookup}
                queries={[
                  {
                    fieldPath: matchFilterField,
                    opString: 'in',
                    value: [`${filter.value}`, parseInt(filter.value, 10)],
                  },
                ]}
                useEffectRerender={filter}
              />
            )}
          </motion.li>
          <motion.li variants={staggeredEntryItem}>
            { matchedDoc && filter && (
              <UpdateAssociation
                collectionPath={`engine/${engine}/associations`}
                depKey={(engineKey: string) => `${engineKey}res${filter.value}`}
                engine={engine}
                matchDependents={matchDependents}
                matchedDoc={matchedDoc}
                updateOptionLookup={updateOptionLookup}
                updateMatchDoc={updateMatchDoc}
                queries={[{
                  fieldPath: 'dependentOf', opString: 'in', value: [`${filter.value}`, parseInt(filter.value, 10)],
                }]}
              />
            )}
          </motion.li>
          <motion.li variants={staggeredEntryItem}>
            <DeleteAssociations
              removeAssociation={removeAssociation}
              setRemoveAssociation={setRemoveAssociation}
            />
          </motion.li>
        </motion.ol>
        <div className="flex flex-row-reverse items-center">
          {/* The Button that will submit the data to Firebase */}
          <SubmitCloudButton
            functionHandler="updateAssociations"
            path={`engine/${engine}/matches`}
            docId={matchedDoc?.pdocId}
            rejectionHandler={rejectionHandler}
            updateValue={{ updateValue: updatedPacket, delete: removeAssociation }}
          />
        </div>
      </motion.div>
    </Container>
  );
};

export default AdjustAssociation;
