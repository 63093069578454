/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import {
  Autocomplete, Checkbox, FormControlLabel, FormGroup, TextField,
} from '@mui/material';
import {
  AssociationLineItems, OptionType,
} from '../../api/types/AssociationTypes';
import { translate } from '../../api/string-api/StringAPI';
import { loadOptionsIntoList } from '../../api/association-api/AssociationAPI';
import AssociationPdfButton from '../association-pdf-button/AssociationPdfButton';

/**
 * The AssociationLineItem component is responsible for displaying and processing the selections/options
 * of the association entry by the user. Properly assigns the selected option and generates the list for options
 * @param association: AssociationEntry : The value of the value that is being associated
 * @param oneTimeLink The denoting of a line item as a 'OTL' or a non-permanent association
 * @param rowIndex: number : The row index of the current place in the map
 * @param retrievedOptions
 * @param selectedInfo: SelectedInfoObject : The list of user selected options, used to render Autocomplete value
 * @param updateSelected: (matchIndex:number, rowIndex:number, updatedSelection: SelectedInfo) => void : Update selected state
 * @param updateOneTimeLink: (rowIndex: number, enabled: boolean) => void : Updates the one time check
 * @param updatedRetrieved
 * @constructor
 */
const AssociationLineItem = function ({
  association, oneTimeLink, rowIndex, retrievedOptions, selectedInfo,
  updateSelected, updateOneTimeLink, updatedRetrieved,
} : AssociationLineItems) {
  const sortAlphaNum = (a: OptionType, b: OptionType) => (a.label).localeCompare((b.label), 'en');

  const updateInfo = (value: OptionType | null, matchKey: string, matchIndex: number) => {
    // Reflect the option selected at the input selectionObj[rowKey][matchKey]
    updateSelected(matchIndex, rowIndex, {
      index: matchIndex,
      id: association.locationValue,
      engine: association.engine,
      matchKey,
      optionTypeSelection: value,
      requiredKey: association.requiredKeys[matchIndex],
      value: value?.value,
      jobIds: association.jobIds,
    });

    // Update the available options for the next in line based off dependencies.
    // IE: Property 4000 should only show units in that property.
    const nextKey = matchIndex + 1;
    if (value?.value && nextKey < association.matchKeys.length) {
      loadOptionsIntoList(association.engine, value?.value).then((res) => {
        updatedRetrieved([{
          key: `${association.engine}${value?.value}`,
          updateOptions: res as OptionType[],
        }]);
      });
    }
  };

  const validate = (entry : OptionType | null | undefined, matchIndex: number) => (
    !entry && association?.requiredKeys[matchIndex]
  );

  const noOptionText = (matchIndex: number) => {
    const comparisonIndex = matchIndex === 0 ? matchIndex : matchIndex - 1;
    return selectedInfo[rowIndex]?.[comparisonIndex]
      ? 'You need to enter the previous value first'
      : 'Loading... Please wait...';
  };
  return (
    <div className="items-center mt-8 flex w-full">
      <div className="flex">
        <AssociationPdfButton jobId={association.jobIds[0]} />
        <div className="flex flex-col align-items">
          <span className="w-32 mr-4">{translate(`ASSOCIATION_${association.engine}`)}</span>
          <span className="text-xs">{association.documentType}</span>
        </div>
      </div>

      <span className="w-60">{association.locationValue}</span>
      {association.matchKeys.map((matchKey, matchIndex) => {
        const dependent = matchIndex === 0 ? 'null' : selectedInfo[rowIndex]?.[matchIndex - 1]?.value;
        const key = `${association.engine}${dependent}`;
        const options = key in retrievedOptions ? retrievedOptions[key] : [];
        return (
          <Autocomplete
            key={matchKey}
            className="w-72 mr-4 w-1/6"
            disablePortal
            id="auto-complete-association"
            noOptionsText={noOptionText(matchIndex)}
            renderInput={(params) => (
              <TextField
                {...params}
                required={association?.requiredKeys[matchIndex]}
                error={validate(selectedInfo[rowIndex]?.[matchIndex]?.optionTypeSelection, matchIndex)}
                label={matchKey.charAt(0).toUpperCase() + matchKey.slice(1)}
              />
            )}
            options={options.sort(sortAlphaNum)}
            onChange={(event, value: OptionType | null) => (
              updateInfo(value, matchKey, matchIndex)
            )}
            value={selectedInfo[rowIndex]?.[matchIndex]?.optionTypeSelection || null}
          />

        );
      })}
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={oneTimeLink[rowIndex] || false}
              onClick={() => updateOneTimeLink(rowIndex, !oneTimeLink[rowIndex])}
            />
          )}
          label="One time link?"
        />
      </FormGroup>
    </div>
  );
};

export default AssociationLineItem;
