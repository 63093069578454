import React from 'react';
import { Button } from '@mui/material';
import loginImage from '../../assets/imgs/test.png';
import { signIn } from '../api/log-in-out-api/LogInOutAPI';
import { translate } from '../api/string-api/StringAPI';

/**
 *
 * The elements responsible for displaying and rendering the login screen.
 * Functionality handled by the LogInOutAPI
 * @constructor
 */
const LoginPage = function () {
  return (
    <div className="h-screen flex justify-center flex-col items-center">
      <div className="flex flex-row bg-white container h-1024 main-container shadow-lg ">
        <div className="flex flex-col items-center justify-center basis-1/3">
          <div className="absolute h-full w-full " />
          <span className="tracking-wide text-5xl font-semibold font-montserrat text-black">
            {translate('LOGIN_TITLE')}
          </span>
          <span className="mt-4 font-semibold mb-8 text-black font-montserrat">{translate('LOGIN_SUBTITLE')}</span>
          <Button onClick={signIn} style={{ backgroundColor: '#e9635c' }} size="large" variant="contained">
            {translate('LOGIN_BUTTON')}
          </Button>
          <div className="mb-20" />
        </div>
        <div className="test basis-2/3 bg-green-500">
          <img src={loginImage} alt="alt img" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
