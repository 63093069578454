import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  JobStatusMap,
  LocationMap, StatementPeriod,
  UploadTypes,
  ValidatedGenericTransaction,
} from './transactions-page-types';
import { Data } from '../api/types/JobStatusTypes';

/**
 * We use utc/timezone functionality to set a static timezone for statement periods, etc.
 */
dayjs.extend(utc);
dayjs.extend(timezone);
export const divvyTimezone = 'America/Denver';
export const rampTimezone = 'America/New_York';
const periodDateFormat = 'D MMM YYYY';

/**
 * Formats a number into a (USD) money string.
 * @param value number representing a dollar amount
 */
export const formatNumberAsMoney = (
  value: number,
) => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

/**
 * Parses a string date to a timezone.
 * @param date date string to be converted to Dayjs object in given timezone
 * @param timeZone the timezone of the date
 */
const parseDateToTimezone = (date: string, timeZone: string) => dayjs(date).tz(timeZone, true);

/**
 * Format Dayjs date to given format.
 * @param date dayjs date
 * @param timeZone timezone for date
 */
const formatPeriodDate = (date: Dayjs, timeZone: string) => date.tz(timeZone).format(periodDateFormat);

/**
 * Given an anchor range, calculates statement periods up to the period containing the current date.
 * @param anchorStartDate start date of anchor range
 * @param anchorEndDate end date of anchor range
 * @param timeZone the timezone of the periods
 */
const calculateStatementPeriods = (
  anchorStartDate: string,
  anchorEndDate: string,
  timeZone: string,
): StatementPeriod[] => {
  // Our anchor date range should reflect the relevant date range in Eastern Time - this timezone appears to align with Divvy the most.
  const originalDateRange: [Dayjs, Dayjs] = [
    parseDateToTimezone(anchorStartDate, timeZone), parseDateToTimezone(anchorEndDate, timeZone),
  ];
  const today = dayjs();

  const dates: [Dayjs, Dayjs][] = [originalDateRange];

  let endDate = null;
  // counter to prevent infinite while
  let counter = 0;
  // generate each date range starting from the anchor and stopping when the current date is in the range
  while (!today.isBefore(endDate) && counter <= 500) {
    const [lastStartDate, lastEndDate] = dates[dates.length - 1];
    const newStart = lastStartDate.add(1, 'month');
    const newEnd = lastEndDate.add(1, 'month');
    dates.push([newStart, newEnd]);
    endDate = newEnd;
    counter += 1;
  }
  // dates are inherently in ascending order, so we modify them in-place to descending
  dates.reverse();
  // we set the dates to the start of day - the original dates are at set at noon instead of 00:00.  this allows
  // for some intra-day fluctuation due to daylight savings without changing the day entirely.
  const periods = dates.map(([start, end]) => ([start.startOf('day'), end.startOf('day')]));

  return periods.map((period) => {
    const [start, end] = period;
    return {
      unixPeriod: [start.unix(), end.unix()],
      label: `${formatPeriodDate(start, timeZone)} - ${formatPeriodDate(end, timeZone)}`,
      period: [start, end],
    };
  });
};

/**
 * Anchor statement periods for different services.  These can be made later, but they should never be made earlier (to
 * avoid likelihood of duplicate uploads).
 */
const DIVVY_ANCHOR_PERIOD = ['2024-04-05T12:00:00', '2024-05-04T12:00:00'];
const RAMP_ANCHOR_PERIOD = ['2024-04-19T12:00:00', '2024-05-19T12:00:00'];

/**
 * Calculate Divvy statement periods.  Divvy periods will always start on the 5th and end on the 4th.
 */
export const getDivvyStatementPeriods = () => calculateStatementPeriods(
  DIVVY_ANCHOR_PERIOD[0],
  DIVVY_ANCHOR_PERIOD[1],
  divvyTimezone,
);

/**
 * Calculate Ramp statement periods.  Ramp periods will always start and end on the 19th.
 */
export const getRampStatementPeriods = () => calculateStatementPeriods(
  RAMP_ANCHOR_PERIOD[0],
  RAMP_ANCHOR_PERIOD[1],
  rampTimezone,
);

// Map of location (basically transaction origin) to various functions and values.
export const locationMap: LocationMap = {
  'divvy-invoice': {
    getTransactions: 'getDivvyTransactions',
    getReceipts: 'getDivvyReceipts',
    uploadBatchJob: 'initializeDivvyBatch',
    budgetColTitle: 'Budget',
    title: 'Divvy Invoices',
    getStatementPeriods: getDivvyStatementPeriods,
  },
  'ramp-invoice': {
    getTransactions: 'getRampTransactions',
    getReceipts: 'getRampReceipts',
    uploadBatchJob: 'initializeRampBatch',
    budgetColTitle: 'Department',
    title: 'Ramp Invoices',
    getStatementPeriods: getRampStatementPeriods,
  },
};

/**
 * Categorizes transactions as either invoices or journal entries based on invoiceTotal, then returns either or both
 * based on user selections.
 * @param transactions master pool of transactions
 * @param uploadTypeSelections user selections that designate which types of transactions they want returned
 */
export const getTxnsByUploadType = (
  transactions: ValidatedGenericTransaction[],
  uploadTypeSelections: UploadTypes[],
) => {
  const txnsToReturn = [];
  // filter txns as invoice or JE based on invoiceTotal
  const invoiceTxns = transactions.filter((txn) => txn.defaultUploadType === UploadTypes.INVOICE);
  const jeTxns = transactions.filter((txn) => txn.defaultUploadType === UploadTypes.JOURNAL_ENTRY);
  // return whatever transactions are included in uploadTypeSelections
  if (uploadTypeSelections.includes(UploadTypes.INVOICE)) txnsToReturn.push(...invoiceTxns);
  if (uploadTypeSelections.includes(UploadTypes.JOURNAL_ENTRY)) txnsToReturn.push(...jeTxns);
  return txnsToReturn;
};

/**
 * Given a list of job statuses, return some status metadata.
 * @param statuses list of all statuses related to a transaction
 */
const getTransactionStatusData = (statuses: Data[]) => {
  // if there has ever been a 'success' (or 'duplicate') status in the list of statuses, it means the transaction was successfully uploaded at least once
  const hasSuccessfullyUploaded = !!statuses.find(
    ({ status }) => status.includes('SUCCESS') || status.includes('DUPLICATE'),
  );
  // retrieve the most recent status from the list
  const mostRecentStatus = statuses.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)[0];
  return { hasSuccessfullyUploaded, mostRecentStatus };
};

/**
 * Given a list of all job statuses and all transactions, generate a mapping of each transaction to its status data
 * (if there is any).
 * @param statuses all statuses
 * @param transactions all transactions in the current date range
 */
export const getJobStatusesMap = async (
  statuses: Data[],
  transactions: ValidatedGenericTransaction[],
) => transactions.reduce((acc, txn) => {
  // using the job ID, pull the related job status doc (they have the same id)
  const relatedStatuses = statuses.filter(({ transactionId }) => txn.id === transactionId);
  // if the job has never run, don't map any data
  if (!relatedStatuses.length) return acc;
  acc[txn.id] = getTransactionStatusData(relatedStatuses);
  return acc;
}, {} as JobStatusMap);
