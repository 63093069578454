import React from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import { motion } from 'framer-motion';
import HappyRobotFace from '../../../assets/imgs/HappyRobotFace.svg';
import ErrorRobotFace from '../../../assets/imgs/ErrorRobotFace.svg';
import SlightSmileRobotFace from '../../../assets/imgs/SlightSmileRobotFace.svg';
import { uploadMetadata } from '../../api/upload-api/UploadAPI';
import { translate } from '../../api/string-api/StringAPI';

export type UploadBoxTypes = {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  getInputProps: <T extends DropzoneInputProps>(props?: (T | undefined)) => T
};

/**
 * The UploadBox is the zone responsible for handling user drag and drop.
 * Acts as the visual display for the user to inform them of success/failure status
 * @param isDragActive :boolean -> Is the user dragging over the dropzone?
 * @param isDragAccept :boolean -> Is the file type valid?
 * @param isDragReject :boolean -> Is the file type invalid?
 * @param getInputProps :boolean -> Dropzone Input Props
 * @constructor
 */
const UploadBox = function ({
  isDragActive, isDragAccept, isDragReject, getInputProps,
}: UploadBoxTypes) {
  return (
    <div className="flex flex-col items-center justify-center">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      {/* Valid and acceptable file drop */}
      { isDragActive && isDragAccept && (
      <motion.img
        animate={{ y: [0, -10, 10, 0] }}
        transition={{ duration: 0.47 }}
        src={HappyRobotFace}
        width="300"
        alt="A success state robot"
      />
      ) }
      { isDragActive && isDragAccept && <p className="mt-2">{translate('UPLOAD_READY')}</p>}

      {/* Invalid file, amount, or some other issue display */}
      { isDragActive && isDragReject
          && (
          <div className="flex flex-col items-center justify-center">
            <motion.img
              animate={{ x: [0, -10, 10, 0] }}
              transition={{ duration: 0.47 }}
              src={ErrorRobotFace}
              width="300"
              alt="A failed state robot"
            />
            <p className="mt-2">{translate('UPLOAD_INVALID_TYPE')}</p>
            <p className="mt-2">
              {translate('UPLOAD_VALID_TYPES')}
              {uploadMetadata.fileTypes.join(' OR ')}
            </p>
          </div>
          )}

      { !isDragActive
          && (
          <div className="flex flex-col items-center justify-center">
            <img src={SlightSmileRobotFace} width="300" alt="A robot slightly smiling" />
            <p className="mt-2">{translate('UPLOAD_INSTRUCTIONS')}</p>
            <p className="mt-2">{uploadMetadata.title}</p>
          </div>
          )}
    </div>

  );
};

export default UploadBox;
