import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getCollectionDocs } from '../../api/document-api/DocumentAPI';

/**
 * Filters jobs for Job Types (e.g. docsumo-general-bill, divvy-invoice, etc)
 * @param selectedJobTypes the job types selected by the user
 * @param updateSelectedJobTypes setter for selected job types
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const JobTypeFilter = function ({ selectedJobTypes, updateSelectedJobTypes }:{
  selectedJobTypes: string[],
  updateSelectedJobTypes: (jobTypes: string[]) => void
}) {
  const [jobTypeOptions, setJobTypeOptions] = useState<string[]>([]);

  // retrieve selection options from documentFields collection
  useEffect(() => {
    getCollectionDocs('documentFields')
      .then((docs) => {
        const jobTypes = docs.map((doc) => doc.pdocId);
        setJobTypeOptions(jobTypes);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <FormControl className="w-64">
      <InputLabel>Job Type</InputLabel>
      <Select
        value={selectedJobTypes}
        label="Job Type"
        multiple
        // have to create and flatten an array as default since technically value can be string | string[]
        onChange={(e: SelectChangeEvent<string[]>) => updateSelectedJobTypes([e.target.value].flat())}
      >
        {jobTypeOptions.map((option) => <MenuItem value={option}>{option}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
