/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/lab';
import { TextField } from '@mui/material';
import { translate } from '../../../api/string-api/StringAPI';
import { getCollectionDocs } from '../../../api/document-api/DocumentAPI';
import { OptionType } from '../../../api/types/AssociationTypes';
import { QueryType } from '../../../api/types/FirebaseTypes';

const UpdateAssociation = function (
  {
    collectionPath, depKey, engine, matchDependents,
    matchedDoc, updateMatchDoc, updateOptionLookup, queries,
  }: {
    collectionPath: string,
    depKey: (engineKey: string) => string
    engine: string | null,
    matchDependents: { [key: string]: string | null }
    matchedDoc: { [key: string] : any },
    updateMatchDoc: (key: string, value: any) => void
    updateOptionLookup: (key: string, value: OptionType[]) => { [key:string]: OptionType[] }
    queries?: QueryType[]
  },
) {
  const [currentValues, setCurrentValues] = useState<{ [key:string]: any }>({});
  const [currentOptions, setCurrentOptions] = useState<{ [key:string]: any }>({});

  useEffect(() => {
    if (matchedDoc) {
      const updatedCurrentValues = { ...matchedDoc };
      delete updatedCurrentValues.assignee;
      delete updatedCurrentValues.pdocId;
      setCurrentValues(updatedCurrentValues);
    }
  }, [matchedDoc]);

  useEffect(() => {
    if (currentValues) {
      Promise.all(Object.keys(currentValues).map(async (currentField) => {
        const dependentField = matchDependents[currentField];
        const dependentOfValues = dependentField !== null
          ? currentValues[dependentField]?.value || currentValues[dependentField]
          : [];

        const optionQuery: QueryType[] = dependentField === null
          ? [{ fieldPath: 'dependentOf', opString: '==', value: null }]
          : [{
            fieldPath: 'dependentOf',
            opString: 'in',
            value: [
              `${dependentOfValues}`,
              parseInt(dependentOfValues, 10),
            ],
          }];
        const optionDocs = await getCollectionDocs(collectionPath, optionQuery);
        return {
          key: currentField,
          options: optionDocs,
        };
      })).then((r) => {
        const updateOptions : { [key:string]: any[] } = {};
        r.forEach((option) => {
          updateOptions[option.key] = option.options;
        });
        setCurrentOptions(updateOptions);
      });
    }
  }, [currentValues]);

  useEffect(() => {
    getCollectionDocs(
      collectionPath,
      queries,
    ).then((r) => {
      updateOptionLookup(depKey(engine as string), r as OptionType[]);
    });
  }, []);
  console.log(currentOptions);
  return (
    <div className="flex w-full">
      {Object.entries(matchedDoc)
        .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
        .map(([matchKey, matchValue], index) => {
          const options = currentOptions[matchKey] && currentOptions[matchKey].length > 0
            ? currentOptions[matchKey].sort((a: { label: string; }, b: { label: any; }) => (
              a.label.localeCompare(b.label)
            ))
            : [];

          const selectedValue = currentValues[matchValue]?.label || currentValues[matchValue] || matchValue;

          return (
            <Autocomplete
              className={`mt-4 w-64 ${index !== 0 ? 'ml-2' : ''}`}
              disablePortal
              disabled={['pdocId', 'assignee'].includes(matchKey)}
              noOptionsText={translate('LOADING_AUTOCOMPLETE')}
              renderInput={(params) => (
                <TextField {...params} label={matchKey} />
              )}
              options={options}
              value={selectedValue}
              onChange={(event, value) => updateMatchDoc(matchKey, value)}
            />
          );
        })}
    </div>
  );
};
export default UpdateAssociation;
