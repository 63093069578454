import { Button, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { doc, setDoc } from 'firebase/firestore';
import { NumericFormat } from 'react-number-format';
import { generateCheckDateDocId } from './payroll-periods-helper';
import { useUser } from '../../../api/user-api/UserAPI';
import { CheckDateDoc, ManualGarnishments } from './payroll-period-types';
import { db } from '../../../../firebase-config';
import { PeriodConfigParams } from './payroll-periods-params';

/**
 * The maximum length of the manual garnishments description.  This is somewhat arbitrary - Entrata may be able
 * to accept a longer memo if necessary (would require further testing).
 */
const MAX_DESCRIPTION_LENGTH = 200;

/**
 * The default/initial value for manual garnishments.
 */
const defaultManualGarnishments: ManualGarnishments = { amount: 0, description: '' };

/**
 * Configuration section for a given payroll period.
 * @param alreadyUploaded Whether the payroll invoice has been successfully uploaded already
 * @param selectedCheckDate the check date of the given payroll period
 * @param selectedCompanyId the company ID for the given payroll period/invoice
 * @param companies all companies
 * @param selectedCheckDateDoc the config doc that we are modifying (if one exists)
 * @param setDisableManualUpload setter for boolean that controls the ability to manually upload
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const PeriodConfig = function ({
  alreadyUploaded,
  selectedCheckDate,
  selectedCompanyId,
  companies,
  selectedCheckDateDoc,
  setDisableManualUpload,
}: PeriodConfigParams) {
  // manual checks amount for the given check date/FEIN payroll
  const [manualChecks, setManualChecks] = useState<number>(0);
  // cash amount for the given check date/FEIN payroll
  const [cashAmount, setCashAmount] = useState<number>(0);
  // manual employee garnishments data
  const [manualGarnishments, setManualGarnishments] = useState<ManualGarnishments>(defaultManualGarnishments);
  const user = useUser();

  // set cash amount and manual checks once check date config doc is retrieved (or not found)
  useEffect(() => {
    // default to 0 if the checkDateDoc doesn't exist
    setManualChecks(selectedCheckDateDoc?.manualChecks || 0);
    setCashAmount(selectedCheckDateDoc?.cashAmount || 0);
    setManualGarnishments(selectedCheckDateDoc?.manualGarnishments || defaultManualGarnishments);
  }, [selectedCheckDateDoc]);

  /**
     * Updates config for currently selected check date and FEIN.
     */
  const handleUpdateConfig = () => {
    if (!selectedCheckDate || !user?.email) return;
    const relevantCompany = companies.find(({ clientId }) => clientId === selectedCompanyId);
    if (!relevantCompany) throw new Error(`Cannot find company with FEIN: ${selectedCompanyId}`);
    const payload: CheckDateDoc = {
      manualChecks,
      cashAmount,
      fein: selectedCompanyId,
      checkDate: selectedCheckDate,
      propertyCode: relevantCompany.propertyCode,
      feinName: relevantCompany.clientName,
      lastUpdatedBy: user.email,
      manualGarnishments,
    };
    const docId = generateCheckDateDocId(selectedCompanyId, selectedCheckDate);
    const docRef = doc(db, 'payroll/checkDate/configuration', docId);
    setDoc(docRef, payload)
      .then(() => enqueueSnackbar('Updated check date configuration', { variant: 'success' }))
      .catch(() => enqueueSnackbar('Failed to update check date configuration', { variant: 'error' }));
  };

  // description length limit is 200 chars
  const descriptionLengthExceedsMax = manualGarnishments.description.length > MAX_DESCRIPTION_LENGTH;

  // if the amounts or selections are invalid, or if the invoice has already uploaded, we disable updating the config
  const disableUpdateConfig = !selectedCompanyId || !selectedCheckDate || Number.isNaN(manualChecks)
        || Number.isNaN(cashAmount) || Number.isNaN(manualGarnishments.amount) || alreadyUploaded
      || descriptionLengthExceedsMax;

  // this is true if the user has modified (but not saved) changes to manualChecks or cashAmount or garnishments
  const configDirty = manualChecks !== selectedCheckDateDoc?.manualChecks
        || cashAmount !== selectedCheckDateDoc?.cashAmount
        || manualGarnishments.amount !== selectedCheckDateDoc?.manualGarnishments.amount
        || manualGarnishments.description !== selectedCheckDateDoc?.manualGarnishments.description;

  // if the config has been changed and not saved, or it is invalid, we should also disable manual upload
  useEffect(() => {
    setDisableManualUpload(disableUpdateConfig || configDirty);
  }, [disableUpdateConfig, configDirty]);

  return (
    <div className="flex flex-col gap-y-4 w-96">
      {/* Manual checks input */}
      <NumericFormat
        label="Manual checks"
        value={manualChecks}
        customInput={TextField}
        disabled={alreadyUploaded}
        decimalScale={2}
        thousandSeparator
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        onChange={(e) => {
          const parsedNumericString = e.target.value.replaceAll(',', '');
          setManualChecks(parseFloat(parsedNumericString));
        }}
      />
      {/* Cash amount input */}
      <NumericFormat
        label="Cash amount"
        value={cashAmount}
        customInput={TextField}
        disabled={alreadyUploaded}
        decimalScale={2}
        thousandSeparator
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        onChange={(e) => {
          const parsedNumericString = e.target.value.replaceAll(',', '');
          setCashAmount(parseFloat(parsedNumericString));
        }}
      />
      {/* Manual garnishments amount input */}
      <NumericFormat
        label="Manual employee garnishments"
        value={manualGarnishments.amount}
        customInput={TextField}
        disabled={alreadyUploaded}
        decimalScale={2}
        thousandSeparator
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        onChange={(e) => {
          const parsedNumericString = e.target.value.replaceAll(',', '');
          const amount = parseFloat(parsedNumericString);
          setManualGarnishments({ ...manualGarnishments, amount });
        }}
      />
      {/* Manual garnishments description input */}
      <TextField
        multiline
        label="Description of manual garnishments"
        rows={4}
        value={manualGarnishments.description}
        disabled={alreadyUploaded}
        error={descriptionLengthExceedsMax}
        helperText={descriptionLengthExceedsMax
            && `Description cannot be more than ${MAX_DESCRIPTION_LENGTH} characters`}
        onChange={(e) => {
          const description = e.target.value;
          setManualGarnishments({ ...manualGarnishments, description });
        }}
      />
      {/* Info text that describes whoever last updated the config */}
      {selectedCheckDateDoc?.lastUpdatedBy && (
        <div className="text-gray-700 text-sm">
          Last updated by:
          {' '}
          {selectedCheckDateDoc.lastUpdatedBy}
        </div>
      )}
      {/* Update config button */}
      <Button
        onClick={handleUpdateConfig}
        variant="contained"
        disabled={disableUpdateConfig}
      >
        Update config
      </Button>
    </div>
  );
};
