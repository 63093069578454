import React from 'react';
import { Container } from '../helper';
import DocumentCarousel from './document-carousel/DocumentCarousel';
import { associationDocuments } from './document-card/document-cards';
import { useAdmin } from '../api/user-api/UserAPI';

/**
 * The landing page a user will first arrive to when logging into DataLink.
 * Displays the potential association jobs to the user via card format
 * @constructor
 */
const LandingPage = function () {
  const isAdmin = useAdmin();

  // filter out admin restricted cards if user is not an admin
  const filteredCards = isAdmin ? associationDocuments : associationDocuments.filter((doc) => !doc.admin);

  return (
    <Container>
      <DocumentCarousel associationDocuments={filteredCards} />
    </Container>
  );
};

export default LandingPage;
