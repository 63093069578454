import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Container } from '../helper';
import { PayrollTab } from './payroll-types';
import { PayrollPeriods } from './tabs/payroll-periods/PayrollPeriods';
import { GlCategories } from './tabs/gl-categories/GlCategories';

// Payroll periods page tabs
const tabs: PayrollTab[] = [
  { tabName: 'GL Categories', tabComponent: <GlCategories /> },
  { tabName: 'Payroll Periods', tabComponent: <PayrollPeriods /> },
];

/**
 * Page through which user can configure payroll invoice uploads and manually upload payroll invoices.
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const PayrollPage = function () {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <Container>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={(e, value) => setSelectedTab(value)}
          >
            {tabs.map(({ tabName }) => (<Tab label={tabName} />))}
          </Tabs>
        </Box>
        {tabs[selectedTab].tabComponent}
      </div>
    </Container>
  );
};
