import React, { useEffect, useState } from 'react';
import Ping from 'ping-url';
import {
  Card, CardActionArea, CardContent, CardMedia, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { CardDataProp } from '../../api/types/DocumentTypes';
import { uploadMetadata } from '../../api/upload-api/UploadAPI';
import navigateTo from '../../api/navigation-api/NavigationAPI';
import { SnackbarAlert } from '../../helper';
import { translate } from '../../api/string-api/StringAPI';

/**
 * Given a CardDataProp cardInfo object, DocumentCard will populate
 * the title information, description, image, and metadata for upload within the object
 * as well as check that the system for that card is online.
 * If the system is not online the card image will be gray scaled, the link will be
 * disabled, and, onclick, the user will be notified that the card is disabled, and it will
 * check that the card is still disabled.
 * @param {CardDataProp} cardInfo - A CardDataProp object retrieved from firebase.
 * @constructor
 */
const DocumentCard = function ({ cardInfo }: CardDataProp) {
  const [active, setActive] = useState(true);
  const [errorOpen, setErrorOpen] = useState(false);

  // Calls the api associated with a given card, if the service responds, active is set to true.
  useEffect(() => {
    Ping.check(cardInfo.status).then((res) => {
      setActive(res.status);
    });
  }, [errorOpen]);

  // If card service is down, clicking on it activates a snackbar alert and does another get request.
  const onClickHandler = () => {
    if (active) {
      uploadMetadata.parseGivenMetadata(cardInfo.metadata, cardInfo.title);
      navigateTo(cardInfo.route);
    } else {
      setErrorOpen(true);
    }
  };

  return (
    <Card className="shadow-lg" sx={{ maxWidth: 345, marginBottom: 2, marginTop: 2 }}>
      <SnackbarAlert
        message={translate('CARD_DISABLED_MESSAGE')}
        open={errorOpen}
        setOpen={setErrorOpen}
        status="error"
      />
      {/* uploadMetadata from the upload API lets us maintain history quickly between views */}
      <CardActionArea onClick={onClickHandler}>
        {/* Snackbar alert for if the image is disabled */}
        {/* The Card Image is loaded here */}
        <CardMedia
          component="img"
          style={{ height: 120 }}
          className={active ? '' : 'grayscale'}
          image={cardInfo.img}
          alt={cardInfo.alt}
        />
        {/* The Card Text is loaded here */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <div className="flex justify-between items-center">
              {cardInfo.title}
              { (active) ? (<CheckCircleIcon className="success" />) : (<ErrorIcon className="error" />) }
            </div>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {cardInfo.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DocumentCard;
