import React from 'react';
import { CircularProgress } from '@mui/material';

/**
 * This component is designed for situations where loading
 * is required, and you want to implement a centered loader
 * in less lines
 */
const CenteredCircularProgress = function () {
  return (
    <div className="flex justify-center">
      <CircularProgress />
    </div>
  );
};

export default CenteredCircularProgress;
