import { getStorage, ref, getDownloadURL } from 'firebase/storage';

/**
 * getStorageImageUrl is used to retrieve the firebase internal url for
 * a given file in a given directory. Depending on set storage permissions,
 * this function may return an error.
 * @param directoryPath The directory path of the file
 * @param filename The desired file
 */
// eslint-disable-next-line import/prefer-default-export
export const getStorageImageUrl = async (directoryPath:string, filename:string) => {
  const storage = getStorage();
  const storsRef = ref(storage, `${directoryPath}/${filename}`);
  try {
    return await getDownloadURL(storsRef);
  } catch (error: unknown) {
    // Handle the error by returning an empty url.
    return '';
  }
};
