/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { getCollectionDocs } from '../../../api/document-api/DocumentAPI';
import { translate } from '../../../api/string-api/StringAPI';
import { OptionType } from '../../../api/types/AssociationTypes';
import { QueryType } from '../../../api/types/FirebaseTypes';

/**
 * SelectDependent is designed to retrieve a list of valid initial options for the user
 * @param collectionPath
 * @param depKey
 * @param engine The engine to use for the search
 * @param selectValue The
 * @param label
 * @param optionLookup
 * @param setFilter
 * @param updateOptionLookup
 * @param queries
 * @constructor
 */
const SelectDependent = function ({
  collectionPath, depKey, engine, label, optionLookup,
  optionsLabelField, selectValue, setValue, useEffectRerender,
  updateOptionLookup, queries,
}: {
  collectionPath: string,
  depKey: (engineKey: string) => string
  engine: string | null,
  label: string,
  optionLookup: { [key:string]: { [key: string] :any }[] }
  optionsLabelField: string,
  useEffectRerender: any,
  selectValue: { [key:string]: any } | null,
  setValue: (value: any) => void
  updateOptionLookup: (key: string, value: OptionType[]) => { [key:string]: OptionType[] }
  queries?: QueryType[]
}) {
  const [enginesList, setEnginesList] = useState<{ [key: string] :any }[]>([]);
  const [availableOptions, setAvailableOptions] = useState<{ [key:string]: any }[]>([]);

  const getOptions = (userLookup?: { [key:string]: OptionType[] }) => {
    if (!engine) return [];
    const lookUp = userLookup || optionLookup;
    const opt = depKey(engine) in lookUp
      ? lookUp[depKey(engine)]
      : enginesList;
    return opt;
  };

  /**
   * listCollections will retrieve the list of collection names within Firebase at a given path
   * Using this information, we can give the user a dropdown list of acceptable options to pick
   */
  useEffect(() => {
    if (!engine) {
      setAvailableOptions([]);
    }
    if (depKey(engine as string) in optionLookup) {
      setAvailableOptions(getOptions());
    } else {
      getCollectionDocs(
        collectionPath,
        queries,
      ).then((r) => {
        const res = updateOptionLookup(depKey(engine as string), r as OptionType[]);
        setEnginesList(r as OptionType[]);
        setAvailableOptions(getOptions(res));
      });
    }
  }, [useEffectRerender]);

  return (
    <div>
      {/* A simple Autocomplete to take a list of valid engines from the user */}
      {/* We take the new value, and tell the setSelection function to update 'selection.engine' to be the value */}
      <Autocomplete
        className="mt-4"
        disablePortal
        id={`auto-complete-association-${label}`}
        noOptionsText={translate('LOADING_AUTOCOMPLETE')}
        renderInput={(params) => (
          <TextField {...params} label={label} />
        )}
        getOptionLabel={(option: { [key:string] : any }) => option[optionsLabelField]}
        onChange={(event, value) => setValue(value)}
        options={availableOptions.sort((a, b) => a[optionsLabelField].localeCompare(b[optionsLabelField]))}
        value={selectValue}
      />
    </div>
  );
};

export default SelectDependent;
