import React from 'react';
import { Button } from '@mui/material';
import notFoundRobot from '../../assets/imgs/404-Robot.svg';
import navigateTo from '../api/navigation-api/NavigationAPI';
import { translate } from '../api/string-api/StringAPI';

/**
 * This component is responsible for the rendering of the not found page
 * Used whenever a user goes to an invalid route
 * @constructor
 */
const NotFound = function () {
  return (
    <div className="flex h-screen dark-charcoal justify-between">
      <div className="ml-24 flex flex-col justify-center">
        <span className="font-montserrat text-9xl westland-red">404</span>
        <span className="text-2xl text-white">
          {translate('NOT_FOUND_TEXT')}
          <Button
            onClick={() => navigateTo('/')}
            className="font-montserrat westland-red text-xl"
            variant="text"
          >
            {translate('NOT_FOUND_BUTTON')}
          </Button>
        </span>
      </div>

      <img className="ml-20 h-90vh" src={notFoundRobot} alt="A robot repairing itself, 404 page" />
    </div>
  );
};

export default NotFound;
