import React from 'react';
import { TitleSubtitle } from '../../../helper';

const AnalyticRowGrouping = function (
  { largeGraph, smallGraph }
  : { largeGraph: React.ReactNode, smallGraph: React.ReactNode },
) {
  return (
    <div className="grid grid-cols-3 mt-8">
      <div className="
        sm:col-span-3 sm:mr-0
        xl:col-span-2 xl:mr-8
        col-span-2 h-60vh bg-white rounded-md mt-2 mr-10 p-5 shadow-lg"
      >
        <TitleSubtitle
          title="ANALYTICS_TITLE"
          titleSize="text-lg"
          subtitle="ANALYTICS_SUBTITLE"
          subtitleSize="text-sm"
          removeDivider
        />
        {largeGraph}
      </div>
      <div className="
        sm:col-span-3
        xl:col-span-1
        h-60vh bg-white rounded-md mt-2 p-5 shadow-lg"
      >
        <TitleSubtitle
          title="ANALYTICS_TITLE"
          titleSize="text-lg"
          subtitle="ANALYTICS_SUBTITLE"
          subtitleSize="text-sm"
          removeDivider
        />
        {smallGraph}
      </div>
    </div>
  );
};

export default AnalyticRowGrouping;
