import React, { useMemo } from 'react';
import {
  DataGridPro, GridColDef, GridRenderCellParams, GridRowParams, GridRowSelectionModel, GridToolbar,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { Error } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { CustomDatagridFooter } from './CustomDatagridFooter';
import { TransactionDetail } from './TransactionDetail';
import {
  GenericTransactionDetail,
  JobStatusMap, UploadTypes,
  ValidatedGenericTransaction,
} from '../transactions-page-types';
import { formatNumberAsMoney, getTxnsByUploadType, locationMap } from '../transactions-page-helpers';
import { LastRunStatusCell } from '../datagrid-cells/LastRunStatusCell';
import { TransactionLinkCell } from '../datagrid-cells/TransactionLinkCell';
import { ValidatedCell } from '../datagrid-cells/ValidatedCell';
import { ReceiptCell } from '../datagrid-cells/ReceiptCell';
import { Data } from '../../api/types/JobStatusTypes';
import { TransactionsDataGridParams } from '../transactions-page-params';

// Represents maximum number of selections user can make at once
const maxSelectedRows = 500;

/**
 * Generates columns for Transactions DataGrid
 * @param statusMap map of transaction ID to status data
 * @param location specifies transaction origin e.g. divvy or ramp
 */
const getColumns = (statusMap: JobStatusMap, location: 'divvy-invoice' | 'ramp-invoice'): GridColDef[] => [
  {
    field: 'merchantName',
    headerName: 'Merchant Name',
    width: 150,
    renderCell: (
      { row }: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => <ValidatedCell value={row.merchantName} />,
  },
  {
    field: 'invoiceTotal',
    headerName: 'Total',
    width: 100,
    align: 'right',
    renderCell: (
      { row }: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => <ValidatedCell value={formatNumberAsMoney(row.invoiceTotal)} />,
  },
  {
    field: 'postMonth',
    headerName: 'Post Month',
    width: 100,
    align: 'right',
    renderCell: (
      { row }: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => <ValidatedCell value={row.postMonth} />,
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    width: 125,
    align: 'right',
    renderCell: (
      { row }: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => <ValidatedCell value={row.invoiceDate} />,
  },
  {
    field: 'budgetCategory',
    headerName: locationMap[location].budgetColTitle,
    width: 150,
  },
  {
    field: 'note',
    headerName: 'Memo',
    width: 150,
    renderCell: (
      { row }: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => <ValidatedCell value={row.note} />,
  },
  {
    field: 'details',
    headerName: 'Items',
    width: 75,
    align: 'right',
    valueGetter: (value: GenericTransactionDetail[]) => value.length,
  },
  {
    field: 'receiptIds',
    headerName: 'Receipts',
    width: 75,
    renderCell: (
      params: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => <ReceiptCell params={params} location={location} />,
  },
  {
    field: 'hasRequiredFields',
    headerName: 'Incomplete',
    width: 100,
    renderCell: (
      { row }: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => (row.hasRequiredFields ? null : <Error className="text-yellow-500 my-auto h-full" />),
  },
  {
    field: 'url',
    headerName: 'Link',
    width: 75,
    renderCell: (
      { row }: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => <TransactionLinkCell url={row.url} />,
  },
  {
    field: 'lastRunStatus',
    headerName: 'Last Run Status',
    width: 200,
    valueGetter: (value, row: ValidatedGenericTransaction) => statusMap[row.id]?.mostRecentStatus.status,
    renderCell: (
      params: GridRenderCellParams<ValidatedGenericTransaction>,
    ) => {
      // most recent status can be undefined since only transactions with previous run statuses exist in the statusMap
      const mostRecentStatus: Data | undefined = statusMap[params.row.id]?.mostRecentStatus;
      return mostRecentStatus && <LastRunStatusCell lastRunStatus={mostRecentStatus} />;
    },
  },
  {
    field: 'lastRunTimestamp',
    headerName: 'Last Run Update',
    width: 200,
    valueGetter: (value, row: ValidatedGenericTransaction) => {
      const unixTimestamp = statusMap[row.id]?.mostRecentStatus.timestamp.seconds;
      return unixTimestamp ? dayjs.unix(unixTimestamp).format('M/D/YYYY h:mm:ss a') : '';
    },
  },
];

/**
 * Gets all relevant rows to display in transactions DataGrid.
 * @param transactions all transactions pulled for the current date range
 * @param jobStatusesMap map of transaction id to job status data
 * @param uploadTypeSelections selected upload types (used to filter txns)
 * @param includePreviouslyUploadedTxns whether to include previously successfully uploaded txns
 */
const getRows = (
  transactions: ValidatedGenericTransaction[],
  jobStatusesMap: JobStatusMap,
  uploadTypeSelections: UploadTypes[],
  includePreviouslyUploadedTxns: boolean,
) => {
  // first filter txns by upload type (journal entry vs invoice)
  const transactionsPool = getTxnsByUploadType(transactions, uploadTypeSelections);
  // get the list of txns that have never been successfully uploaded
  const txnsNeverSuccessfullyUploaded = transactionsPool.filter((txn) => {
    const previousUpload = jobStatusesMap[txn.id];
    // if the txn was previously successfully uploaded, we want to exclude it
    return !previousUpload?.hasSuccessfullyUploaded;
  });
  return includePreviouslyUploadedTxns ? transactionsPool : txnsNeverSuccessfullyUploaded;
};

/**
 * Datagrid of relevant Transactions.
 * @param jobStatusesMap map of txn id => job status data
 * @param transactions all transactions for current date range
 * @param location basically transactions category i.e. divvy or ramp
 * @param selectedTransactions currently selected transactions
 * @param loading datagrid loading state
 * @param updateSelectedTransactions setter for selectedTxns
 * @param includePreviouslyUploadedTxns whether to include previously successfully uploaded txns in datagrid
 * @param uploadTypeSelections upload type filter (e.g. JE vs invoice or both)
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const TransactionsDataGrid = function ({
  jobStatusesMap, transactions, location, selectedTransactions, loading, updateSelectedTransactions,
  includePreviouslyUploadedTxns, uploadTypeSelections,
}: TransactionsDataGridParams) {
  // generates datagrid columns - dependent on jobStatusMap being created since we display status data in the DataGrid
  const columns = useMemo(
    () => (
      jobStatusesMap ? getColumns(jobStatusesMap, location) : []
    ),
    [jobStatusesMap, location],
  );
  // generates visible rows - also dependent on jobStatusMap
  const rows = useMemo(
    () => (
      jobStatusesMap ? getRows(transactions, jobStatusesMap, uploadTypeSelections, includePreviouslyUploadedTxns) : []
    ),
    [transactions, jobStatusesMap, uploadTypeSelections, includePreviouslyUploadedTxns],
  );

  /**
   * When row selections change, we want to limit the number of selections that can be made at once - this acts as a
   * de facto ceiling on the number of jobs that can be initialized simultaneously.
   * @param selectedIds selected row ids
   */
  const onRowSelectionChange = (selectedIds: GridRowSelectionModel) => {
    const selectedTxns = transactions.filter((txn) => selectedIds.includes(txn.id));
    // if the user has selected more than the limit, automatically truncate their selection and display a warning notification
    if (selectedTxns.length > maxSelectedRows) {
      enqueueSnackbar(`Cannot select more than ${maxSelectedRows} transactions at once`, { variant: 'warning' });
    }
    updateSelectedTransactions(selectedTxns.slice(0, maxSelectedRows));
  };

  // the sum of all invoiceTotals of selected transactions
  const selectedSum = selectedTransactions.reduce((acc, txn) => acc + txn.invoiceTotal, 0);
  // sum of all txns in selected date range
  const sumOfTxnsInRange = transactions.reduce((acc, txn) => acc + txn.invoiceTotal, 0);

  return (
    <Box sx={{ height: 520, width: '100%' }}>
      <DataGridPro
        slots={{ toolbar: GridToolbar, footer: () => CustomDatagridFooter(selectedSum, sumOfTxnsInRange) }}
        columns={columns}
        rows={rows}
        loading={loading}
        checkboxSelection
        // Transaction Detail is the dropdown content for each row
        getDetailPanelContent={({ row }) => TransactionDetail({ details: row.details })}
        getDetailPanelHeight={() => 'auto'}
        getRowId={(row: ValidatedGenericTransaction) => row.id}
        // row should only be selectable (and thus upload-able) if it has all required fields
        isRowSelectable={(params: GridRowParams<ValidatedGenericTransaction>) => params.row.hasRequiredFields}
        pagination
        pageSizeOptions={[25, 50, 100, 250]}
        onRowSelectionModelChange={onRowSelectionChange}
        rowSelectionModel={selectedTransactions.map((txn) => txn.id)}
      />
    </Box>
  );
};
