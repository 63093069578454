import {
  Box, Button, Modal, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { GlCategoryRules } from './GlCategoryRules';
import {
  CategoryRules, GlCategory, GlCategoryType,
} from '../gl-category-types';
import { db } from '../../../../../firebase-config';
import { getDocumentNull } from '../../../../api/document-api/DocumentAPI';
import { GlCategoryConfigModalParams } from '../gl-category-params';
import { getModalStyle } from './modal-helpers';

const style = getModalStyle(400);

const defaultRules: CategoryRules = {
  calculatedInBillBack: false,
  reconcilesToCash: false,
  glType: GlCategoryType.OVERHEAD,
  passthrough: false,
  erContributions: false,
  offsetFromCash: false,
  glCode: '',
  offsetGl: '',
  reimbursementGl: '',
};

/**
 * Modal to configure the "rules" for a given GL category (can create or edit category).
 * @param setEditingCategoryId setter for ID of category currently being edited
 * @param editingCategoryId the ID of category currently being edited or created (controls modal)
 * @param glCategories gl categories
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const GlCategoryConfigModal = function (
  { setEditingCategoryId, editingCategoryId, glCategories }: GlCategoryConfigModalParams,
) {
  const [categoryName, setCategoryName] = useState<string>('');
  const [rules, setRules] = useState<CategoryRules>(defaultRules);
  const updateRules = (updatedRules: CategoryRules) => setRules(updatedRules);

  // get the existing gl category (assuming the user is editing and not creating a category)
  useEffect(() => {
    getDocumentNull('payroll/glCategories/categories', editingCategoryId)
      .then((document) => {
        const categoryDoc = document as GlCategory | null;
        setCategoryName(categoryDoc?.displayName || '');
        setRules(categoryDoc?.rules || defaultRules);
      })
      .catch(() => enqueueSnackbar('Failed to retrieve category', { variant: 'error' }));
  }, [editingCategoryId]);

  /**
   * Create or update the current category.
   */
  const updateGlCategory = () => {
    // check if there is another gl category with different doc id but with the same name (this indicates a naming conflict)
    const categoryNameConflict = !!glCategories.find(
      ({ displayName, pdocId }) => displayName === categoryName && editingCategoryId !== pdocId,
    );
    if (categoryNameConflict) {
      enqueueSnackbar('Category with same name already exists', { variant: 'error' });
      return;
    }
    // create or update the category in Firestore
    const docRef = doc(db, 'payroll/glCategories/categories', editingCategoryId);
    const payload = { displayName: categoryName, rules };
    setDoc(docRef, payload)
      .then(() => {
        enqueueSnackbar('Configured GL category', { variant: 'success' });
        // close the modal
        setEditingCategoryId('');
      })
      .catch(() => enqueueSnackbar('Failed to configure GL category', { variant: 'error' }));
  };

  return (
    <Modal
      open
      onClose={() => setEditingCategoryId('')}
    >
      <Box sx={style}>
        {/* Header */}
        <div style={{ fontSize: '20px', marginBottom: '20px' }}>GL Category Configuration</div>
        {/*  Set category name */}
        <TextField
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          style={{ marginBottom: '20px' }}
          label="Category name"
        />
        {/* Set category rules */}
        <GlCategoryRules
          rules={rules}
          setRules={updateRules}
        />
        {/* Update category button */}
        <Button
          style={{ marginTop: '20px' }}
          variant="contained"
          disabled={!categoryName || !rules.glType}
          onClick={updateGlCategory}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};
