import React, { Dispatch, SetStateAction } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { translate } from '../../api/string-api/StringAPI';

/**
 * AssociationsConfirm serves as a warning/HUD that the user is about to submit an association
 * grouping without completing all the available assocations. This operation is considered safe
 * within the operations of the Datalink, but we should alert the user that they missed on just to be
 * explicit.
 * @param open The state of the confirmation dialogue
 * @param setOpen The handler to set the open state to closed or open
 * @param setConfirmation The confirmation status state. Used as an override for required fields
 * @constructor
 */
const AssociationsConfirm = function ({ open, setOpen, setConfirmation } :
{
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  setConfirmation: Dispatch<SetStateAction<boolean>>
}) {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setConfirmation(true);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{translate('ASSOCIATION_CONFIRM_TITLE')}</DialogTitle>
      <DialogContent dividers>
        {translate('ASSOCIATION_CONFIRM_BODY_ONE')}
        <br />
        <br />
        {translate('ASSOCIATION_CONFIRM_BODY_TWO')}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {translate('ASSOCIATION_CONFIRM_DENY')}
        </Button>
        <Button onClick={handleOk}>
          {translate('ASSOCIATION_CONFIRM_ACCEPT')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssociationsConfirm;
