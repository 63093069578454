import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { GlCategoryDoc, PayrollCodeMappingDoc } from '../../gl-category-types';
import { db } from '../../../../../../firebase-config';
import { AssignGlCategoryCellParams } from '../../gl-category-params';

/**
 * Update the GL category mapped to a given payroll code.
 * @param payrollCode the payroll code being mapped to
 * @param category the GL category being mapped to the payroll code
 */
const updatePayrollMapping = (payrollCode: string, category: GlCategoryDoc | null) => {
  const docRef = doc(db, 'payroll/payrollCodes/mappings', payrollCode);
  const payload = { glCategory: category?.pdocId || '' };
  setDoc(docRef, payload)
    .then(() => enqueueSnackbar('Successfully mapped payroll code to GL category', { variant: 'success' }))
    .catch(() => enqueueSnackbar('Failed to map payroll code to GL category', { variant: 'error' }));
};

/**
 * Given a payroll code, returns the mapped GL category (if there is one).
 * @param payrollCode the payroll code in question
 * @param payrollCodeMappings list of payroll code mappings
 * @param glCategories list of gl categories
 */
const getGlCategory = (
  payrollCode: string,
  payrollCodeMappings: PayrollCodeMappingDoc[],
  glCategories: GlCategoryDoc[],
) => {
  // get relevant payroll mapping (if it exists)
  const payrollMapping = payrollCodeMappings.find(({ pdocId }) => pdocId === payrollCode);
  if (!payrollMapping) return null;
  // using gl category ID from payroll mapping, get gl category
  const glCategory = glCategories.find(({ pdocId }) => pdocId === payrollMapping.glCategory);
  return glCategory || null;
};

/**
 * DataGrid cell used to assign a GL Category to a given payroll code.
 * @param payrollCode the payroll code we are mapping to
 * @param glCategories list of GL categories
 * @param payrollCodeMappings list of existing payroll code mappings
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const AssignGlCategoryCell = function ({
  payrollCode, glCategories, payrollCodeMappings,
}: AssignGlCategoryCellParams) {
  // the currently mapped GL category
  const glCategory = getGlCategory(payrollCode, payrollCodeMappings, glCategories);

  return (
    <Autocomplete
      options={glCategories}
      style={{ paddingTop: 10, paddingBottom: 10 }}
      sx={{ width: 300 }}
      value={glCategory}
      onChange={(e, val) => updatePayrollMapping(payrollCode, val)}
      renderInput={(inputParams) => <TextField {...inputParams} label="GL Category" />}
      getOptionLabel={(option) => option.displayName}
    />
  );
};
