import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import {
  CenteredCircularProgress,
  Container, GenericSelectCheckbox, TitleSubtitle,
} from '../helper';
import { watchCollection, watchDocument } from '../api/document-api/DocumentAPI';
import BarChart from './visualizations/bar-chart/BarChart';
import DateSelection from './date-selection/DateSelection';
import Sankey from './visualizations/sankey/Sankey';
import { JobStatus } from '../api/types/JobStatusTypes';
import ScoreCardGrouping from './groupings/score-card-grouping/ScoreCardGrouping';
import AnalyticRowGrouping from './groupings/analytic-row-grouping/AnalyticRowGrouping';
import {
  getAutoAssociationAnalytic, getJobBreakdown, generateStat, mergeOnField, generatePercentageOf,
} from '../api/analyticis-api/AnalyticsAPI';
import PieChart from './visualizations/pie-chart/PieChart';
import {
  DateFilter, IDValue, StatBreakdown, Stats,
} from '../api/types/AnalyticsTypes';
import LineChart from './visualizations/line-chart/LineChart';
// import LineChart from './visualizations/line-chart/LineChart';

/**
 * The analytics page is responsible for displaying and rending information pertaining to job statistics.
 * The current two display modes are by user completion rates and by job type completion rates.
 * @constructor
 */
const AnalyticsPage = function () {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<Stats>();

  const [adjustedData, setAdjustedData] = useState<StatBreakdown>([]);
  const [completeStats, setCompleteStats] = useState<StatBreakdown>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [sortByJobType, setSortByJobType] = useState(false);
  const [jobStatus, setJobStatus] = useState<JobStatus[]>();

  const [dateFilter, setDateFilter] = useState<DateFilter>({
    // One week ago
    startDate: new Date(Date.now() - (3600 * 1000 * 24 * 7)),
    startDatePeriod: new Date(Date.now() - (3600 * 1000 * 24 * 7)),
    endDate: new Date(),
    endDatePeriod: new Date(),
  });

  const getNewPeriod = (startDate: Date, endDate: Date) => {
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInDays = Math.round((endDate.getTime() - startDate.getTime()) / oneDay);
    // The end date period is one date BEFORE the set start date
    const endDatePeriod : Date = new Date(startDate.valueOf());
    endDatePeriod.setDate(startDate.getDate() - 1);
    endDatePeriod.setHours(23, 59, 59, 59);

    const startDatePeriod : Date = new Date(endDatePeriod.valueOf());
    startDatePeriod.setDate(endDatePeriod.getDate() - diffInDays);
    startDatePeriod.setHours(0, 0, 0, 0);

    return {
      startDatePeriod,
      endDatePeriod,
    };
  };

  const changeDate = (newDate : Date, field: keyof DateFilter) => {
    const updatedDates = { ...dateFilter };
    if (field === 'startDate') {
      updatedDates.startDate = newDate;
    } else {
      updatedDates.endDate = newDate;
    }
    const updatedPeriods = getNewPeriod(updatedDates.startDate, updatedDates.endDate);
    setDateFilter({ ...updatedDates, ...updatedPeriods });
  };

  // Pie Charts
  const [jobResult, setJobResult] = useState<IDValue[]>();
  const [autoAssociation, setAutoAssociation] = useState<IDValue[]>();

  useEffect(() => {
    if (dateFilter.startDate && dateFilter.endDate) {
      dateFilter.startDate.setHours(0, 0, 0, 0);
      dateFilter.endDate.setHours(23, 59, 59, 59);
    }
  }, []);

  useEffect(() => {
    if (stats) {
      // Filter out the categories where the selectedUsers are not present
      setAdjustedData(
        mergeOnField(completeStats, 'name')
          .filter((item) => (
            selectedUsers.length === 0 || selectedUsers.includes(item.name)
          )),
      );
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (jobStatus && jobStatus.length > 0) {
      const res = getJobBreakdown(
        jobStatus,
        ['UPLOAD-SUCCESS', 'UPLOAD-DUPLICATE', 'UPLOAD-ERROR', 'THRESHOLD-EXCEEDED'],
      );
      const autoAssociationStat = getAutoAssociationAnalytic(jobStatus);
      setJobResult(Object.values(res));
      setAutoAssociation(Object.values(autoAssociationStat));
    }
  }, [jobStatus]);

  useEffect(() => {
    if (stats) {
      // Generate the base stats and store them to reduce calculations
      const generatedStats: StatBreakdown = generateStat(stats) as StatBreakdown;
      setCompleteStats(generatedStats);
      setAdjustedData(mergeOnField(generatedStats, 'name'));
    }
  }, [stats]);

  useEffect(() => {
    watchDocument(setLoading, setStats, 'general/stats');
    watchCollection(() => {}, setJobStatus, 'jobStatus', [
      { fieldPath: 'timestamp', opString: '>=', value: dateFilter.startDate },
      { fieldPath: 'timestamp', opString: '<=', value: dateFilter.endDate },
    ]);
  }, [dateFilter]);

  return (
    <Container backgroundOverride>
      <TitleSubtitle title="ANALYTICS_TITLE" subtitle="ANALYTICS_SUBTITLE" />
      {loading && (
        <CenteredCircularProgress />
      )}

      {!loading && adjustedData && jobStatus && (
        <>
          <DateSelection
            endDate={dateFilter.endDate}
            handleEndDateChange={(value) => changeDate(value as Date, 'endDate')}
            startDate={dateFilter.startDate}
            handleStartDateChange={(value) => changeDate(value as Date, 'startDate')}
          />
          <ScoreCardGrouping jobStatus={jobStatus} />
          <AnalyticRowGrouping
            largeGraph={(
              <BarChart
                data={sortByJobType ? mergeOnField(completeStats, 'documentCategory') : adjustedData}
                indexBy={sortByJobType ? 'documentCategory' : 'name'}
              />
            )}
            smallGraph={<PieChart data={jobResult || []} />}
          />
          <AnalyticRowGrouping
            largeGraph={(
              <Sankey jobStatus={jobStatus || []} />
            )}
            smallGraph={<PieChart data={autoAssociation || []} />}
          />
          <AnalyticRowGrouping
            largeGraph={(
              <LineChart
                data={generatePercentageOf(jobStatus)}
              />
            )}
            smallGraph={<PieChart data={autoAssociation || []} />}
          />

        </>
      )}

      { jobStatus && (
        <div className="flex flex-col h-max">

          <div className="flex justify-center items-center">
            <GenericSelectCheckbox
              selectKey="displayName"
              collection="usersPublic"
              label="Users"
              selectedObjects={selectedUsers}
              setSelectedObjects={setSelectedUsers}
            />
            <FormGroup className="ml-12 ">
              <FormControlLabel
                control={<Checkbox checked={sortByJobType} onChange={() => setSortByJobType(!sortByJobType)} />}
                label="Sort by JobType"
              />
            </FormGroup>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AnalyticsPage;
