import React from 'react';
import { uploadMetadata } from '../../api/upload-api/UploadAPI';

export type UploadTitle = {
  counter: number;
};

/**
 * Simple component just to make readability easier.
 * Displays the title counter to the user
 * @param counter :number -> Integer amount uploaded
 * @constructor
 */
const UploadTitleCounter = function ({ counter } : UploadTitle) {
  return (
    <div className="text-3xl mb-2">
      Uploaded
      {' '}
      {counter}
      {' '}
      files to
      {' '}
      {uploadMetadata.title}
    </div>
  );
};

export default UploadTitleCounter;
