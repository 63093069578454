import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { watchCollection } from '../../api/document-api/DocumentAPI';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * A generic selection dropdown checkbox utility that works for general purpose
 * checkbox based inputs in combination with a firebase collection.
 * @param label The dropdown label
 * @param collection The firebase collection name
 * @param selectKey The key to use as the label
 * @param selectedObjects The objects currently selected by the checkbox
 * @param setSelectedObjects The handler for the above selected objects
 * @constructor
 */
const GenericSelectCheckbox = function (
  {
    label, collection, selectKey, selectedObjects, setSelectedObjects,
  }:
  { label: string,
    collection: string,
    selectKey: string,
    selectedObjects: string[],
    setSelectedObjects: Dispatch<SetStateAction<string[]>>
  },
) {
  const [objectList, setObjectList] = useState<{ [key:string]: string }[]>([]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const unsubscribe = watchCollection(() => {}, setObjectList, collection);
    return () => unsubscribe();
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof selectedObjects>) => {
    const {
      target: { value },
    } = event;
    setSelectedObjects(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="select-user-checkbox-label"
          id="select-user-multiple-checkbox"
          multiple
          value={selectedObjects}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={() => selectedObjects.join(', ')}
          MenuProps={MenuProps}
        >
          {objectList.map((user) => (
            <MenuItem key={user[selectKey]} value={user[selectKey]}>
              <Checkbox checked={selectedObjects.indexOf(user[selectKey]) > -1} />
              <ListItemText primary={user[selectKey]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default GenericSelectCheckbox;
