import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { translate } from '../../../api/string-api/StringAPI';

const DeleteAssociations = function (
  { removeAssociation, setRemoveAssociation }:
  { removeAssociation: boolean, setRemoveAssociation: React.Dispatch<React.SetStateAction<boolean>> },
) {
  return (
    <FormGroup className="ml-2">
      <FormControlLabel
        control={(
          <Checkbox
            checked={removeAssociation}
            onChange={() => setRemoveAssociation(!removeAssociation)}
          />
        )}
        label={translate('ADMIN_ASSOCIATION_DELETE')}
      />
    </FormGroup>
  );
};

export default DeleteAssociations;
