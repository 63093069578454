import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Container, SnackbarAlert } from '../helper';
import errorRobot from '../../assets/imgs/Error-Robot.svg';
import ErrorDescription from './error-description/ErrorDescription';
import errorAPI from '../api/error-api/ErrorAPI';
import navigateTo from '../api/navigation-api/NavigationAPI';
import { translate } from '../api/string-api/StringAPI';

/**
 * The error page is the catch all location for error handling within the UI.
 * Make use of the ErrorsAPI. User can send an error report, upon sending
 * they are navigated to the home page and alerted.
 * @constructor
 */
const ErrorPage = function () {
  // Used to open the snackbar
  // Doubles up as the disabler for the email button
  const [open, setOpen] = useState(false);
  const emailNotifyReroute = () => {
    errorAPI.sendEmail();
    setOpen(true);
    setTimeout(() => { navigateTo('', true); }, 2000);
  };

  return (
    <Container>
      <SnackbarAlert
        message={translate('ERROR_EMAIL_MESSAGE')}
        open={open}
        setOpen={setOpen}
        status="success"
      />
      <div className="font-roboto flex flex-col justify-center items-center">
        <ErrorDescription />
        <img className="mt-2 ml-20" width="375" src={errorRobot} alt="A broken robot" />
        <div className="mt-4">
          <Button disabled={open} onClick={emailNotifyReroute} className="error-button" variant="outlined">
            {translate('ERROR_BUTTON')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ErrorPage;
