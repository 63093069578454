import React, { useEffect, useState } from 'react';
import { Loop } from '@mui/icons-material';
import {
  Box, Fade, IconButton, Modal, Table, TableContainer,
} from '@mui/material';
import ReactJson from 'react-json-view';
import { httpsCallable } from 'firebase/functions';
import { AlertColor } from '@mui/material/Alert/Alert';
import { watchCollection } from '../../api/document-api/DocumentAPI';
import { CenteredCircularProgress, SnackbarAlert } from '../../helper';
import TableHeader from '../table-header/TableHeader';
import JobRow from '../job-row/JobRow';
import { getComparator } from '../../api/status-api/StatusAPI';
import { Data } from '../../api/types/JobStatusTypes';
import { translate } from '../../api/string-api/StringAPI';
import { useAdmin } from '../../api/user-api/UserAPI';
import { functions } from '../../../firebase-config';

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 6,
};

function JobModal({
  jobId, open, handleClose, row,
}: { jobId: string, open: boolean, handleClose: () => void, row: Data }) {
  const [loading, setLoading] = useState(true);
  const [jobStatuses, setJobStatuses] = useState<Data[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState<AlertColor>('success');
  const [alertMessage, setAlertMessage] = useState('ERROR_FAILED_UPDATE');

  const isAdmin = useAdmin();

  const updateAlert = (success: boolean, message: string) => {
    setAlertOpen(true);
    setAlertStatus(success ? 'success' : 'error');
    setAlertMessage(message);
  };
  const [openJson, setOpenJson] = useState<{ [key:string] : boolean }>({});

  const handleJson = (openJobId: string, enable: boolean, index: number) => {
    const updatedOpenJson = { ...openJson };
    updatedOpenJson[`${openJobId}${index}`] = enable;
    setOpenJson(updatedOpenJson);
  };

  const rerunJob = (functionName: string, payload: { [key:string]: any }) => {
    const cloudFunction = httpsCallable(functions, functionName);
    return cloudFunction(payload)
      .then((res) => {
        const { status }: { status: string } = res.data as any;
        if (status === 'SUCCESS') {
          updateAlert(true, 'ADMIN_UPDATED_INFO');
        } else {
          updateAlert(false, 'ERROR_FAILED_UPDATE');
        }
      })
      .catch(() => {
        updateAlert(false, 'ERROR_FAILED_UPDATE');
      });
  };

  useEffect(() => {
    if (open) {
      const unsubscribe = watchCollection(setLoading, setJobStatuses, `jobStatus/${jobId}/status`);
      return () => unsubscribe();
    }
    return () => {};
  }, [open]);

  return (
    <>
      <SnackbarAlert
        message={translate(`${alertMessage}`)}
        open={alertOpen}
        setOpen={setAlertOpen}
        status={alertStatus}
      />
      <Modal
        className="flex flex-col justify-center items-center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="w-3/4 h-3/4" sx={style}>
            { loading && <CenteredCircularProgress />}
            { !loading && (
              <>
                <TableContainer className="h-full" sx={{ height: '60vh' }}>
                  <Table>
                    <TableHeader onRequestSort={() => {}} order="desc" orderBy="timestamp" />
                    {jobStatuses.sort(getComparator('desc', 'timestamp'))
                      .map((job: Data, index: number) => (
                        <>
                          <Modal
                            aria-labelledby="json-modal-title"
                            aria-describedby="json-modal-description"
                            open={openJson[`${job.jobId}${index}`]}
                            onClose={() => handleJson(job.jobId, false, index)}
                            closeAfterTransition
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Box sx={style}>
                              <ReactJson src={job.metadata} />
                            </Box>
                          </Modal>
                          <JobRow row={job} handleOpen={() => handleJson(job.jobId, true, index)} />
                        </>
                      ))}
                  </Table>
                </TableContainer>
                <div className="flex justify-around items-center">
                  {/*  Currently, the re-run job button only exists for Docsumo - it's not really needed
                       for other uploads since Docsumo is the only one that requires manual image upload   */}
                  { isAdmin && row.jobType.includes('docsumo') && (
                    <IconButton onClick={async () => {
                      await rerunJob('resendToDocsumo', { jobId, filename: `${jobId}.pdf` });
                    }}
                    >
                      <span className="font-montserrat mr-4">{translate('JOB_STATUS_RERUN')}</span>
                      <Loop />
                    </IconButton>
                  )}
                </div>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </>

  );
}

export default JobModal;
