import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';

export type SnackbarType = {
  message: string,
  open: boolean,
  setOpen: (status: boolean) => void,
  status: AlertColor
};

/**
 * Displays a message to the top right of the screens
 * @param message :string -> The message to display
 * @param open :boolean -> The display state of the message
 * @param setOpen :func -> The function for setting the open state
 * @param status :SnackbarType -> The border color of the alert
 * @constructor
 */
const SnackbarAlert = function ({
  message, open, setOpen, status,
} : SnackbarType) {
  const vertical = 'top';
  const horizontal = 'right';

  // If the user clicks on the 'X', close.
  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        {/* @ts-ignore */}
        <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarAlert;
