import { Dayjs } from 'dayjs';
import { Data } from '../api/types/JobStatusTypes';

/**
 * Represents a detail (line item) of a transaction
 */
export interface GenericTransactionDetail {
  rate: number,
  property: string,
  unit?: string,
  glAccount: string,
  description: string,
  formattedPropertyUnit: { value: string }
}

/**
 * Represents different types of data uploads to Entrata, Datalink jobs can be handled as either.
 */
export enum UploadTypes {
  INVOICE = 'INVOICE',
  JOURNAL_ENTRY = 'JOURNAL-ENTRY',
}

/**
 * Represents a transaction
 */
export interface GenericTransaction {
  id: string,
  url: string,
  budgetCategory: string,
  merchantName: string,
  receiptIds: string[],
  postMonth: string,
  invoiceDate: string,
  invoiceTotal: number,
  invoiceNumber: string,
  note: string,
  selectedUploadType: UploadTypes | null,
  defaultUploadType: UploadTypes
  details: GenericTransactionDetail[],
}

/**
 * Represents a validated transaction (contains validation field)
 */
export interface ValidatedGenericTransaction extends GenericTransaction {
  hasRequiredFields: boolean
}

/**
 * Represents response from one of the initializeBatch endpoints
 */
export interface InitializeBatchJobResponse {
  errors: number,
  successes: number
}

/**
 * Represents a map of transaction id to job status-related data
 */
export interface JobStatusMap {
  [transactionId: string]: {
    hasSuccessfullyUploaded: boolean,
    mostRecentStatus: Data
  }
}

/**
 * Represents the different locations for a transaction (basically the different transaction origin categories)
 */
export type TransactionLocation = 'divvy-invoice' | 'ramp-invoice';

/**
 * Represents a mapping of Transaction 'location' (location = 'divvy-invoice','ramp-invoice') to various location-specific values
 */
export interface LocationMap {
  [key: string]: {
    getTransactions: string,
    getReceipts: string,
    uploadBatchJob: string,
    budgetColTitle: string
    title: string
    getStatementPeriods: () => StatementPeriod[]
  }
}

/**
 * Represents display names for associations for a given Transaction invoice upload.
 */
export interface TransactionJobAssociations {
  payeeName: string,
}

/**
 * Represents a statement period date range.  Transactions are grouped by statement period.
 */
export interface StatementPeriod {
  unixPeriod: [number, number]
  label: string
  period: [Dayjs, Dayjs]
}
