import history from '../history/History';

/**
 * A helper method to provide navigation to endpoints.
 * @param location: string
 * @param absolute: boolean Use absolute path
 * @param state: optional state
 */
const navigateTo = (location: string, absolute?:boolean, state?: { [key:string]: unknown }) => {
  history.push(absolute ? `/${location}` : location, state);
};

export default navigateTo;
