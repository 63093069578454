/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { User } from '../../../api/types/UserTypes';
import { translate } from '../../../api/string-api/StringAPI';
import { GenericAutocomplete } from '../../../helper';

/**
 * SelectUsers provides an autocomplete dropdown for the available users in the users collection
 * Users to select a singular user into the useState for 'setUser'
 * @param allUsers
 * @param setUser
 * @param setUserRoles
 * @constructor
 */
const SelectUsers = function (
  { user, setUser, setUserRoles }:
  { user: User | null, setUser: (arg0: User | null) => void, setUserRoles: (arg0: string[]) => void },
) {
  // When you choose a user, update the new user roles to be the roles attached to the user
  return (
    <div className="w-72">
      <span className="text-lg">
        {translate('ADMIN_SELECT_USER')}
        <hr />
      </span>
      <GenericAutocomplete
        keyName="displayName"
        label="User"
        path="users"
        onChange={(_, value:User | null) => {
          setUserRoles(value?.association_permissions || []);
          setUser(value);
        }}
        sortKey="displayName"
        selected={user}
      />
    </div>
  );
};

export default SelectUsers;
