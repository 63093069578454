import React, { useEffect, useState } from 'react';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { CircularProgress, IconButton } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { DocumentData } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { functions } from '../../../firebase-config';
import { getStorageImageUrl } from '../../api/storage-api/StorageApi';
import { GenericTransaction } from '../../transactions-page/transactions-page-types';
import { getJobDoc } from '../../api/document-api/DocumentAPI';
import { locationMap } from '../../transactions-page/transactions-page-helpers';

/**
 * Retrieve the image url for a GenericTransaction job
 * @param jobData data of job
 */
const getTransactionImage = async (jobData: DocumentData) => {
  const sourceData = jobData.sourceData as GenericTransaction;
  const getReceiptsFuncName = locationMap[jobData.location].getReceipts;
  const getReceiptsFunc = httpsCallable(functions, getReceiptsFuncName);
  const resp = await getReceiptsFunc({ transactionId: sourceData.id });
  // we only really need to show the first image (for now)
  const [url] = resp.data as string[];
  return url;
};

/**
 * Retrieves the url for the job image.
 * @param gcsImageUrl existing image url (if the image was upload to GCS)
 * @param jobData data of job containing receipt ids (if there are any)
 */
const getImageUrl = async (
  gcsImageUrl: string,
  jobData: DocumentData | null,
) => gcsImageUrl || (jobData ? await getTransactionImage(jobData) : '');

/**
 * AssociationPdfButton provides a button for users to click during the association section.
 * This button, upon clicking, will retrieve the related invoice images.
 * This allows the user to view the given image pertaining to
 * an association to see if there is an error.
 * @param jobId the id of the job
 * @constructor
 */
function AssociationPdfButton({ jobId } : { jobId: string }) {
  const [jobData, setJobData] = useState<DocumentData | null>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [gcsImageUrl, setGcsImageUrl] = useState<string>('');

  // retrieves stored url (if there is one) and job data (if there is a doc yet).
  // we do this because docsumo images are uploaded to GCS (by necessity) but images for other jobs (like transactions) are not uploaded to GCS
  useEffect(() => {
    // this will be empty string if there is no data at that location
    getStorageImageUrl('invoices', `${jobId}.pdf`)
      .then((imageUrl) => setGcsImageUrl(imageUrl))
      .catch((err) => console.error(err));
    // retrieves job doc for job (if there is one - docsumo jobs will not have job doc during initial upload)
    getJobDoc(jobId)
      .then((data) => { if (data) setJobData(data); })
      .catch((err) => console.error(err));
  }, [jobId]);

  const jobDataHasImages = !!jobData && !!jobData.sourceData?.receiptIds?.length;
  const hasImage = !!gcsImageUrl || jobDataHasImages;

  // open image url when user clicks button
  const onClick = () => {
    setLoadingImage(true);
    getImageUrl(gcsImageUrl, jobData)
      .then((url) => window.open(url))
      .catch(() => enqueueSnackbar('Failed to get image', { variant: 'error' }))
      .finally(() => setLoadingImage(false));
  };

  const icon = hasImage ? <ImageSearchIcon /> : <ImageNotSupportedIcon />;

  return (
    <div className="mr-2">
      <IconButton
        disabled={!hasImage}
        onClick={onClick}
      >
        {loadingImage ? <CircularProgress className="my-auto" size={20} /> : icon}
      </IconButton>
    </div>
  );
}

export default AssociationPdfButton;
