import React from 'react';
import { Box } from '@mui/material';
import NavigationBar from '../../navigation/Navigation';

type Children = {
  children: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  backgroundOverride?: boolean
};

/**
 * A wrapper method for placing props inside a container.
 * Incorporates the navigation bar into everything that uses the navbar.
 * Checks if a user is logged in, if not, redirects them to the landing page.
 * @param children
 * @param backgroundOverride remove the default background
 * @constructor
 */
const Container = function ({ children, backgroundOverride = false }: Children) {
  return (
    <Box sx={{ display: 'flex' }}>
      <NavigationBar />
      { backgroundOverride
        ? (<div className="w-full m-5">{children}</div>)
        : (
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
          >
            <div className=" flex justify-center container-height">
              <div className="container shadow-lg p-10 mt-5 mb-5 container-color">
                {children}
              </div>
            </div>
          </Box>
        )}

    </Box>

  );
};

export default Container;
