import { CardData } from '../../api/types/DocumentTypes';
import docsumoLogo from '../../../assets/imgs/docsumo.png';
import divvyLogo from '../../../assets/imgs/divvy-logo.png';
import rampLogo from '../../../assets/imgs/ramp-logo.webp';
import payrollIcon from '../../../assets/imgs/payroll.jpeg';

// These represent the selection cards on the Landing Page from which a user selects what kind of job they'd like to initiate.
// eslint-disable-next-line import/prefer-default-export
export const associationDocuments: CardData[] = [
  {
    alt: 'Auto Classify Docsumo Logo',
    category: 'docsumo',
    description: 'Upload summary bills into Docsumo to be processed into Entrata',
    img: docsumoLogo,
    metadata: {
      allowedFileTypes: [
        'application/pdf',
        '.pdf',
      ],
      location: 'docsumo-auto-classify',
    },
    status: '',
    title: 'Summary Bills',
    route: '/upload',
    admin: false,
  },
  {
    alt: 'General Bill Docsumo Logo',
    category: 'docsumo',
    description: 'Upload your invoices into Docsumo to be processed into Entrata',
    img: docsumoLogo,
    metadata: {
      allowedFileTypes: [
        'application/pdf',
        '.pdf',
      ],
      location: 'docsumo-general-bill',
    },
    status: '',
    title: 'Single Bills',
    route: '/upload',
    admin: false,
  },
  {
    alt: 'Vendor Statement Docsumo Logo',
    category: 'docsumo',
    description: 'Upload your vendor statements into Docsumo to be processed into BigQuery',
    img: docsumoLogo,
    metadata: {
      allowedFileTypes: [
        'application/pdf',
        '.pdf',
      ],
      location: 'docsumo-vendor-statement',
    },
    status: '',
    title: 'Vendor Statements',
    route: '/upload',
    admin: false,
  },
  {
    alt: 'Divvy Logo',
    category: 'transactions',
    description: 'Upload Divvy invoices to Entrata',
    img: divvyLogo,
    metadata: {
      allowedFileTypes: [],
      location: 'divvy-invoice',
    },
    status: '',
    title: 'Divvy Invoices',
    route: '/divvy-transactions',
    admin: false,
  },
  {
    alt: 'Ramp Logo',
    category: 'transactions',
    description: 'Upload Ramp invoices to Entrata',
    img: rampLogo,
    metadata: {
      allowedFileTypes: [],
      location: 'ramp-invoice',
    },
    status: '',
    title: 'Ramp Invoices',
    route: '/ramp-transactions',
    admin: false,
  },
  {
    alt: 'Payroll Logo',
    category: 'scheduled',
    description: 'Configure and review automatic payroll invoices uploader',
    img: payrollIcon,
    metadata: {
      allowedFileTypes: [],
      location: 'payroll-invoice',
    },
    status: '',
    title: 'Payroll Invoices',
    route: '/payroll-invoice',
    admin: true,
  },
];
