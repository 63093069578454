import React, { useState } from 'react';
import { Button } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { AlertColor } from '@mui/material/Alert/Alert';
import { SubmitButton } from '../../api/types/AssociationTypes';
import { translate } from '../../api/string-api/StringAPI';
import { SnackbarAlert } from '../../helper';
import { functions } from '../../../firebase-config';

const SubmitCloudButton = function ({
  docId, functionHandler, path, rejectionHandler, updateValue,
} : SubmitButton) {
  const [open, setOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState<AlertColor>('success');
  const [alertMessage, setAlertMessage] = useState('ERROR_FAILED_UPDATE');

  const updateAlert = (success: boolean, message: string) => {
    setOpen(true);
    setAlertStatus(success ? 'success' : 'error');
    setAlertMessage(message);
  };

  const submitChanges = () => {
    if (rejectionHandler && rejectionHandler(updateValue)) {
      updateAlert(false, 'SUBMIT_REJECTED');
      return () => {};
    }
    updateAlert(true, 'SUBMIT_PENDING');
    const cloudFunction = httpsCallable(functions, functionHandler);
    return cloudFunction({ docId, path, ...updateValue })
      .then((res) => {
        const { status }: { status: string } = res.data as any;
        if (status === 'SUCCESS') {
          updateAlert(true, 'ADMIN_UPDATED_INFO');
        } else {
          updateAlert(false, 'ERROR_FAILED_UPDATE');
        }
      })
      .catch(() => {
        updateAlert(false, 'ERROR_FAILED_UPDATE');
      });
  };
  return (
    <div>
      <SnackbarAlert
        message={translate(`${alertMessage}`)}
        open={open}
        setOpen={setOpen}
        status={alertStatus}
      />
      <Button className="westland-red" onClick={submitChanges}>
        {translate('ADMIN_BUTTON')}
      </Button>
    </div>
  );
};

export default SubmitCloudButton;
