import { GridFooter, GridFooterContainer } from '@mui/x-data-grid-pro';
import React from 'react';
import { formatNumberAsMoney } from '../transactions-page-helpers';

/**
 * Custom footer for Transaction datagrid.
 * @param selectedSum the sum of all currently selected transactions
 * @param sumOfTxnsInRange the sum of all txns in current date range
 */
// eslint-disable-next-line import/prefer-default-export
export const CustomDatagridFooter = function (selectedSum: number, sumOfTxnsInRange: number) {
  const selectedSumText = `Sum of selected: ${formatNumberAsMoney(selectedSum)}`;
  const rangeSumText = `Sum of all transactions in date range: ${formatNumberAsMoney(sumOfTxnsInRange)}`;

  return (
    <GridFooterContainer>
      <div className="ml-4">{selectedSumText}</div>
      <div className="ml-4">{rangeSumText}</div>
      <GridFooter />
    </GridFooterContainer>
  );
};
