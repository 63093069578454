import { CheckDateDoc } from './payroll-period-types';
import { getDocumentNull } from '../../../api/document-api/DocumentAPI';

/**
 * Generates config doc ID for given check date/FEIN.
 * @param companyId FEIN
 * @param checkDate check date
 */
export const generateCheckDateDocId = (companyId: string, checkDate: string) => `${companyId}_${checkDate}`;

/**
 * Retrieves check date config doc from Firestore given FEIN and check date.
 * @param clientId FEIN
 * @param checkDate check date
 */
export const getCheckDateDoc = async (clientId: string, checkDate: string) => {
  const docId = generateCheckDateDocId(clientId, checkDate);
  return await getDocumentNull('payroll/checkDate/configuration', docId) as CheckDateDoc | null;
};
