import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import {
  FormControlLabel, FormGroup, Switch, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { enqueueSnackbar } from 'notistack';
import { Container } from '../helper';
import { functions } from '../../firebase-config';
import {
  JobStatusMap, StatementPeriod, TransactionLocation, UploadTypes, ValidatedGenericTransaction,
} from './transactions-page-types';
import { getJobStatusesMap, locationMap } from './transactions-page-helpers';
import { watchCollection } from '../api/document-api/DocumentAPI';
import { Data } from '../api/types/JobStatusTypes';
import { TransactionsDateFilters } from './transactions-date-filters/TransactionsDateFilters';
import { CreateBatchJobButton } from './create-batch-job-button/CreateBatchJobButton';
import { TransactionsDataGrid } from './transactions-datagrid/TransactionsDataGrid';

/**
 * Page which displays Transactions for a given location (transaction origin).  On this page, users can initialize
 * Datalink jobs for these transactions (jobs that will ultimately upload these transactions to Entrata).
 * @param location specifies transactions origin e.g. divvy or ramp
 * @constructor
 */
const TransactionsPage = function ({ location }:{ location: TransactionLocation }) {
  // all transactions for the given date range
  const [transactions, setTransactions] = useState<ValidatedGenericTransaction[]>([]);
  // transaction rows that the user has selected
  const [selectedTransactions, setSelectedTransactions] = useState<ValidatedGenericTransaction[]>([]);
  // what upload types the user has selected - this filters the visible transactions
  const [
    uploadTypeSelections, setUploadTypeSelections,
  ] = useState<UploadTypes[]>([UploadTypes.INVOICE, UploadTypes.JOURNAL_ENTRY]);
  // selected date range used to pull transactions
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  // the selected statement period (basically a preset date range) - this will reset the date range when changed
  const [selectedStatementPeriod, setSelectedStatementPeriod] = useState<StatementPeriod | null>(null);
  // job statuses document collection in firestore (watcher)
  const [jobStatuses, setJobStatuses] = useState<Data[]>([]);
  // map of txn id => related job status data
  const [jobStatusesMap, setJobStatusesMap] = useState<JobStatusMap | null>(null);
  // whether to show txns that have previously been uploaded
  const [includePreviouslyUploadedTxns, setIncludePreviouslyUploadedTxns] = useState<boolean>(false);
  const [datagridLoading, setDatagridLoading] = useState<boolean>(true);

  const [startDate, endDate] = dateRange;

  const updateSelectedStatementPeriod = (period: StatementPeriod | null) => setSelectedStatementPeriod(period);
  const updateDateRange = (range: DateRange<Dayjs>) => setDateRange(range);
  const updateSelectedTransactions = (txns: ValidatedGenericTransaction[]) => setSelectedTransactions(txns);

  // pulls all transactions in the selected date range
  useEffect(() => {
    if (!startDate || !endDate) return;
    setDatagridLoading(true);
    const unixDateRange = [startDate.unix(), endDate.unix()];
    const getTransactions = httpsCallable(functions, locationMap[location].getTransactions);
    getTransactions({ dateRange: unixDateRange })
      .then((res) => setTransactions(res.data as ValidatedGenericTransaction[]))
      .catch(() => enqueueSnackbar('Failed to retrieve transactions', { variant: 'error' }))
      .finally(() => setDatagridLoading(false));
  }, [dateRange]);

  // watches the job statuses collection so that we get real-time updates
  useEffect(() => {
    const unsubscribe = watchCollection(() => {}, setJobStatuses, 'jobStatus');
    return () => unsubscribe();
  }, []);

  // change date range to selected statement period when selected statement period changes.  this is just a starting value
  // for date range tho - user can further narrow down range
  useEffect(() => {
    if (!selectedStatementPeriod) return;
    setDateRange(selectedStatementPeriod.period);
  }, [selectedStatementPeriod]);

  // generate job statuses map from job statuses docs.  we cannot generate job statuses without transactions being pulled
  useEffect(() => {
    if (!transactions.length) return;
    getJobStatusesMap(jobStatuses, transactions)
      .then((mapping) => { setJobStatusesMap(mapping); })
      .catch(() => enqueueSnackbar('Unable to retrieve job statuses', { variant: 'error' }));
  }, [jobStatuses, transactions]);

  return (
    <Container>
      <div className="flex flex-row justify-between items-center mb-2">
        {/* Title */}
        <div className="text-2xl mb-4">{locationMap[location].title}</div>
        {/* Toggle button group to select upload type */}
        <ToggleButtonGroup
          value={uploadTypeSelections}
          onChange={(e, val) => { setUploadTypeSelections(val); }}
          className="h-10"
          color="primary"
        >
          <ToggleButton value={UploadTypes.INVOICE} aria-label="left aligned">Invoices</ToggleButton>
          <ToggleButton value={UploadTypes.JOURNAL_ENTRY} aria-label="left aligned">Journal Entries</ToggleButton>
        </ToggleButtonGroup>
        {/* Toggle to show previously uploaded transactions */}
        <FormGroup>
          <FormControlLabel
            control={(
              <Switch
                disabled={!jobStatusesMap}
                checked={includePreviouslyUploadedTxns}
                onChange={(event, checked) => setIncludePreviouslyUploadedTxns(checked)}
              />
            )}
            label="Show previously uploaded transactions?"
          />
        </FormGroup>
      </div>
      {/* Transactions DataGrid */}
      <TransactionsDataGrid
        jobStatusesMap={jobStatusesMap}
        transactions={transactions}
        location={location}
        selectedTransactions={selectedTransactions}
        loading={datagridLoading}
        updateSelectedTransactions={updateSelectedTransactions}
        uploadTypeSelections={uploadTypeSelections}
        includePreviouslyUploadedTxns={includePreviouslyUploadedTxns}
      />
      <div className="flex flex-row justify-between">
        {/* Statement period and granular date range selector */}
        <TransactionsDateFilters
          selectedStatementPeriod={selectedStatementPeriod}
          updateSelectedStatementPeriod={updateSelectedStatementPeriod}
          dateRange={dateRange}
          updateDateRange={updateDateRange}
          location={location}
        />
        {/* Button to upload invoices */}
        <CreateBatchJobButton
          selectedTransactions={selectedTransactions}
          location={location}
        />
      </div>
    </Container>
  );
};

export default TransactionsPage;
