import React from 'react';
// @ts-ignore
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Line } from '@nivo/line';
import { LineAnalyticsType } from '../../../api/types/AnalyticsTypes';
import { AnalyticColors } from '../../../api/analyticis-api/AnalyticsAPI';

const LineChart = function ({ data }: { data: LineAnalyticsType[] }) {
  console.log(data);
  return (
    <AutoSizer>
      {/* eslint-disable-next-line react/no-unused-prop-types */}
      {({ height, width }: { height: number, width: number }) => (
        <Line
          height={height}
          width={width}
          data={data}
          margin={{
            top: 50, right: 110, bottom: 80, left: 60,
          }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          colors={AnalyticColors.lineChart}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </AutoSizer>
  );
};

export default LineChart;
