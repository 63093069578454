import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { httpsCallable } from 'firebase/functions';
import { FormGroup } from '@mui/material';
import { FirestoreError } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { CenteredCircularProgress, TitleSubtitle } from '../helper';
import {
  AssociationDocumentData, AssociationEntry, EngineKey, Predictions,
  SelectedInfo, SelectedInfoObject, UpdatedOption,
} from '../api/types/AssociationTypes';
import AssociationLineItem from './association-line-item/AssociationLineItem';
import AssociationJobLoading from './association-job-loading/AssociationJobLoading';
import { AssociationText } from '../api/framer-motion-api/FramerMotionAPI';
import AssociationSubmit from './association-submit/AssociationSubmit';
import errorAPI from '../api/error-api/ErrorAPI';
import { loadOptionsIntoList, processAssociationData } from '../api/association-api/AssociationAPI';
import { AssociationPrediction } from './association-prediction/AssociationPrediction';
import { functions } from '../../firebase-config';

/**
 * The AssociationPage component is the entry point for displaying the returned job information
 * and requiring the user to assign preselected values to a select entry.
 * @constructor
 */
const AssociationPage = function () {
  const [loading, setLoading] = useState(true);

  // Handles the data from the collection watch
  const [associations, setAssociations] = useState<AssociationEntry[]>([]);
  const [documentData, setDocumentData] = useState<AssociationDocumentData>();

  // Handles user input and visual displays
  const [displayState, setDisplayState] = useState('visible');
  const [selectedInfo, setSelectedInfo] = useState<SelectedInfoObject>({});
  const [oneTimeLink, setOneTimeLink] = useState<{ [key:string]: boolean }>({});
  const [retrievedOptions, setRetrievedOptions] = useState<EngineKey>({} as EngineKey);
  const [loadingRefreshVendors, setLoadingRefreshVendors] = useState<boolean>(false);

  /**
   * Fetch data returns user owned job data.
   * Which kicks off the process.
   * Internally uses the context to determine user id to restrict access.
   */
  const fetchData = () => {
    const getJobs = httpsCallable(functions, 'getJobs');
    getJobs()
      .then((res) => { setDocumentData(res as AssociationDocumentData); })
      .catch((error: FirestoreError) => {
        const { code } = error;
        const { message } = error;
        errorAPI.setAndSend(code, message);
      });
  };

  /**
   * The updater for handling which rows will be/will not
   * receiving their passed information as a one time transaction
   * @param rowIndex The object 'row' index
   * @param enabled Whether or not it's a oneTimeLink
   */
  const updateOneTimeLink = (rowIndex: number, enabled: boolean) => {
    const updatedLinks = { ...oneTimeLink };
    updatedLinks[rowIndex] = enabled;
    setOneTimeLink(updatedLinks);
  };

  /**
   * Updates the Display for the framer motion animation.
   * This allows the screen to cycle during loading periods
   * @param state visible or hidden, updates the state of framer motion
   */
  const updateDisplay = (state: string) => setDisplayState(state);

  const updateRetrievedOptions = (updatedOption: UpdatedOption[]) => {
    const updatedRetrieved = { ...retrievedOptions };
    updatedOption.forEach((update) => {
      updatedRetrieved[update.key] = update.updateOptions;
    });
    setRetrievedOptions(updatedRetrieved);
  };

  /**
   * Updates the selection made by the user.
   * Zeroes anything out after the matchIndex (column) of the selected entry.
   * This helps to prevent mismatched data
   * @param matchIndex The 'column' of the object
   * @param rowIndex The 'row' of the object
   * @param updatedSelection The updated information to put into the row/column
   */
  const updatedSelected = (matchIndex:number, rowIndex: number, updatedSelection: SelectedInfo) => {
    const adjustedSelected: SelectedInfoObject = { ...selectedInfo };
    adjustedSelected[rowIndex][matchIndex] = updatedSelection;
    Object.keys(adjustedSelected[rowIndex]).forEach((matchKey) => {
      // If the matchKey exceeds the matchIndex we know that this
      // entry takes place after the modified value, so we want to 0 it out values and optionTypeSelection.
      if (parseInt(matchKey, 10) > matchIndex) {
        adjustedSelected[rowIndex][matchKey] = {
          ...selectedInfo[rowIndex][matchKey],
          value: undefined,
          optionTypeSelection: undefined,
        };
      }
    });
    setSelectedInfo(adjustedSelected);
  };

  const resetInputs = () => { setOneTimeLink({}); setSelectedInfo({}); fetchData(); };

  // Get jobs associated to user
  useEffect(() => {
    fetchData();
  }, []);

  // When the document watcher detects a change, update the job information
  useEffect(() => {
    try {
      if (documentData) {
        const { jobStage, jobs } = documentData.data;
        if (jobStage !== -1) {
          const assocRequired = processAssociationData(jobs, jobStage);
          setAssociations(assocRequired);
          setLoading(false);
        }
      }
    } catch (e) {
      errorAPI.setAndSend('Exception in Association Page', (e as Error).message);
    }
  }, [documentData]);

  useEffect(() => {
    const fetchOptions = async () => {
      const keysUsed: string[] = [];
      const selectionData: SelectedInfoObject = {};
      const updateOptions = await Promise.all(associations.map(async ({
        engine, jobIds, locationValue, matchKeys, requiredKeys,
      }, rowIndex) => {
        matchKeys.forEach((matchKey, matchIndex) => {
          if (!(rowIndex in selectionData)) selectionData[rowIndex] = {};
          selectionData[rowIndex][matchIndex] = {
            index: matchIndex,
            id: locationValue,
            engine,
            matchKey,
            optionTypeSelection: undefined,
            value: undefined,
            requiredKey: requiredKeys[matchIndex],
            jobIds,
          };
        });

        const key = `${engine}null`;
        if (!(keysUsed.includes(key))) {
          // Maintain a running list of initial loads
          keysUsed.push(key);
          return {
            key,
            updateOptions: await loadOptionsIntoList(engine, null),
          };
        }
        return null;
      }));
      const filteredOptions = updateOptions.filter((option) => (
        option !== null
      )) as UpdatedOption[];
      updateRetrievedOptions(filteredOptions);
      setSelectedInfo(selectionData);
    };
    fetchOptions()
      .catch(console.error);
    // Keys used keeps track of the initial load list to prevent multiple fetches to the same place
  }, [associations]);

  /**
   * Refreshes vendors on associations page by re-pulling them from Entrata and saving to Firestore.
   */
  const refreshVendors = () => {
    setLoadingRefreshVendors(true);
    const refreshVendorsFunc = httpsCallable(functions, 'triggerUpdateVendors');
    const successText = 'Vendors refreshing... Please wait up to 30s and then refresh the page';
    refreshVendorsFunc()
      .then(() => { enqueueSnackbar(successText, { variant: 'success' }); })
      .catch(() => { enqueueSnackbar('Failed to refresh vendors', { variant: 'error' }); })
      .finally(() => { setLoadingRefreshVendors(false); });
  };

  return (
    <div>
      { loading && <CenteredCircularProgress />}
      {!loading && (
        <div className="flex flex-col h-full w-full">
          {/* Displays the job animation when the user confirms changes */}
          <AssociationJobLoading displayState={displayState} />
          <motion.div
            className="flex flex-col h-full w-full"
            variants={AssociationText}
            initial="hidden"
            animate={displayState}
          >
            <TitleSubtitle title="ASSOCIATION_TITLE" subtitle="ASSOCIATION_SUBTITLE" />
            <div>
              Associations remaining: &nbsp;
              {documentData?.data.associationsRemaining || '0'}
            </div>
            {/* Button to refresh vendors */}
            <div className="w-full flex">
              <LoadingButton
                className="ml-auto"
                onClick={refreshVendors}
                loading={loadingRefreshVendors}
                variant="contained"
              >
                Refresh Vendors
              </LoadingButton>
            </div>
            <FormGroup>
              {
                associations.map((association, rowIndex) => (
                  <>
                    <AssociationLineItem
                      key={`${association.locationValue}-${association.engine}`}
                      association={association}
                      oneTimeLink={oneTimeLink}
                      rowIndex={rowIndex}
                      retrievedOptions={retrievedOptions}
                      selectedInfo={selectedInfo}
                      updateSelected={updatedSelected}
                      updateOneTimeLink={updateOneTimeLink}
                      updatedRetrieved={updateRetrievedOptions}
                    />
                    {association.usePrediction && (
                      <AssociationPrediction prediction={association.predictionValue as Predictions} />
                    )}
                  </>
                ))
              }
              {!!associations.length && (
              <AssociationSubmit
                oneTimeLink={oneTimeLink}
                selectedInfo={selectedInfo}
                resetInputs={resetInputs}
                updateDisplay={updateDisplay}
              />
              )}
            </FormGroup>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default AssociationPage;
