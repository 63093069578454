import { httpsCallable } from 'firebase/functions';
import { MutableRefObject } from 'react';
import { FirestoreError } from 'firebase/firestore';
import {
  AssociationEntry, AssociationJob, Engines, OptionType, SelectedInfoObject,
} from '../types/AssociationTypes';
import errorHistory from '../error-api/ErrorAPI';
import { functions } from '../../../firebase-config';

/**
 * Using our StringAPI we can convert these into actual lines.
 */
export const LoadingTips = [
  'LOADING_TIP_1',
  'LOADING_TIP_2',
  'LOADING_TIP_3',
  'LOADING_TIP_4',
  'LOADING_TIP_5',
];

/**
 * We don't want user to have to assign the same job multiple time.
 * This function excludes duplicates from appearing as they will ultimately both
 * benefit from just one assignment
 * @param jobs: The list of user jobs
 */
const removeDuplicates = (jobs: AssociationEntry[]) => {
  const updateJobObject : { [key:string]: AssociationEntry } = {};
  const validationArray: string[] = [];
  Object.values(jobs).forEach((job) => {
    const validationString = `${job.engine}${job.locationValue}`;

    if (!validationArray.includes(validationString)) {
      updateJobObject[validationString] = job;
      validationArray.push(validationString);
    } else {
      updateJobObject[validationString].jobIds.push(...job.jobIds);
    }
  });
  return Object.values(updateJobObject);
};

/**
 * The handler for processing returned association data for the association engine.
 * Takes an array of associationSchema jobs and the current job stage. Breaks the job
 * down into a flattened non-duplicated array
 * @param jobs An array of [associationSchema] jobs
 * @param jobStage The current job stage to know which job to extract from
 */
export const processAssociationData = (jobs: AssociationJob[], jobStage: number) => {
  const arrOfJobArr : AssociationEntry[][] = jobs.map((associationJob: AssociationJob) => (
    associationJob.association.jobs[jobStage]
      .map((association: AssociationEntry) => ({
        ...association,
        jobIds: [associationJob.jobId],
        documentType: associationJob.documentType,
      }))
      .filter((association) => (
        association.needsAssociation
      ))
  ));

  const flattenedJobs = arrOfJobArr.flat();
  return removeDuplicates(flattenedJobs);
};

/**
 * Given an engine and a dependent, give return the list
 * of valid options for the user to select.
 * @param engine The Association Engine the job should use
 * @param dependant The dependant value that an option may be reliant on.
 */
export const loadOptionsIntoList = async (
  engine: Engines,
  dependant: string | null,
) => {
  const getPayload = {
    enginePath: `engine/${engine}/associations`,
    query: {
      key: 'dependentOf',
      operator: '==',
      value: dependant,
    },
  };
  if (dependant === undefined) throw Error('Dependant is undefined');

  const getOptions = httpsCallable(functions, 'getOptions');
  return getOptions(getPayload)
    .then((res) => {
      const { data } = res as { data: { options: OptionType[] } };
      const options : OptionType[] = data?.options;
      return options;
    })
    .catch((error: FirestoreError) => {
      const { code } = error;
      const { message } = error;
      errorHistory.setAndSend(code, message);
    });
};

/**
 *
 * @param payload
 * @param updateJobStatus
 * @param isMounted
 */
export const createAssociation = (
  payload: { selectedInfo: SelectedInfoObject, oneTimeLink: { [key:string]: boolean } },
  updateJobStatus: (arg0: any) => void,
  isMounted : MutableRefObject<boolean>,
) => {
  const getOptions = httpsCallable(functions, 'createAssociation');
  getOptions(payload)
    .then((res) => {
      if (isMounted?.current) updateJobStatus(res);
    })
    .catch((error: FirestoreError) => {
      const { code } = error;
      const { message } = error;
      errorHistory.setAndSend(code, message);
    });
};
