import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { httpsCallable } from 'firebase/functions';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { Box, Button, Modal } from '@mui/material';
import { formatNumberAsMoney, locationMap } from '../transactions-page-helpers';
import { functions } from '../../../firebase-config';
import { InitializeBatchJobResponse, UploadTypes, ValidatedGenericTransaction } from '../transactions-page-types';
import { CreateBatchJobButtonParams } from '../transactions-page-params';

// Confirmation modal style
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

/**
 * Splits transactions into debits and credits and returns the split lists and their sums.
 * @param transactions transactions that we are categorizing/summing
 */
const getTransactionsSums = (transactions: ValidatedGenericTransaction[]) => {
  const journalEntries = transactions.filter(
    (transaction) => transaction.defaultUploadType === UploadTypes.JOURNAL_ENTRY,
  );
  const invoices = transactions.filter(
    (transaction) => transaction.defaultUploadType === UploadTypes.INVOICE,
  );

  const journalEntriesSum = journalEntries.reduce((acc, je) => acc + je.invoiceTotal, 0);
  const invoicesSum = invoices.reduce((acc, invoice) => acc + invoice.invoiceTotal, 0);

  return {
    journalEntries, journalEntriesSum, invoices, invoicesSum,
  };
};

/**
 * Generates payload for initializeBatch function given the selected transactions
 * @param selectedTransactions transactions that users wants to initialize jobs for
 */
const generateCreateBatchPayload = (selectedTransactions: ValidatedGenericTransaction[]) => {
  const transactionIds = selectedTransactions.map(({ id }) => id);
  // get date range of transactions
  const unixDates = selectedTransactions.map(({ invoiceDate }) => dayjs(invoiceDate).unix());
  // sort dates in ascending order
  const sortedDates = unixDates.sort((a, b) => a - b);
  // margin = one day in unix seconds
  const margin = 24 * 60 * 60;
  // add a margin on either side of range as a safety buffer
  const dateRange = [sortedDates[0] - margin, sortedDates[sortedDates.length - 1] + margin];
  return { transactionIds, dateRange };
};

/**
 * Enqueues a snackbar reflective of how many jobs succeeded and/or failed to initialize.
 * @param data response data which contains job successes and failures
 */
const handleInitializeBatchResp = (data: InitializeBatchJobResponse) => {
  const { errors, successes } = data;
  const errorsText = errors ? `but failed to initialize ${errors} jobs` : '';
  const snackbarText = `Successfully initialized ${successes} jobs ${errorsText}`;
  enqueueSnackbar(snackbarText, { variant: 'success' });
};

/**
 * Generates text for confirmation modal body.
 * @param selectedTransactions transactions that user is trying to initialize jobs for
 */
const getModalBodyText = (selectedTransactions: ValidatedGenericTransaction[]) => {
  const {
    journalEntriesSum, journalEntries, invoicesSum, invoices,
  } = getTransactionsSums(selectedTransactions);

  return `You will upload ${invoices.length} invoice(s) with a sum of 
  ${formatNumberAsMoney(invoicesSum)} and ${journalEntries.length} journal entrie(s) with a sum of
   ${formatNumberAsMoney(journalEntriesSum)}`;
};

/**
 * Button to instantiate a batch job of selected transactions.
 * @param selectedTransactions Transactions for which to instantiate jobs
 * @param location basically transactions subtype e.g. Divvy or Ramp
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const CreateBatchJobButton = function ({ selectedTransactions, location }: CreateBatchJobButtonParams) {
  const [loadingCreateBatch, setLoadingCreateBatch] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  /**
   * Creates a batch job with the selected transactions.
   */
  const createBatchJob = () => {
    setLoadingCreateBatch(true);
    const initBatchFuncName = locationMap[location].uploadBatchJob;
    const initializeBatch = httpsCallable(functions, initBatchFuncName);
    const payload = generateCreateBatchPayload(selectedTransactions);
    initializeBatch(payload)
      .then((res) => { handleInitializeBatchResp(res.data as InitializeBatchJobResponse); })
      .catch(() => { enqueueSnackbar('Failed to create batch job', { variant: 'error' }); })
      .finally(() => {
        setLoadingCreateBatch(false);
        setConfirmationModalOpen(false);
      });
  };

  const modalBodyText = getModalBodyText(selectedTransactions);

  return (
    <>
      {/* Start batch job button - opens confirmation modal */}
      <Button
        className="bg-blue-500 text-white hover:bg-blue-400 h-12 mt-auto disabled:bg-gray-300"
        onClick={() => setConfirmationModalOpen(true)}
        disabled={!selectedTransactions.length}
      >
        Run batch
      </Button>
      {/* Confirmation modal - displays batch job summary */}
      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Box sx={style} className="flex flex-col">
          <div style={{ fontSize: '20px', marginBottom: '20px' }}>Run batch job?</div>
          <div style={{ marginBottom: '20px' }}>{modalBodyText}</div>
          {/* Button which actually runs batch */}
          <LoadingButton
            loading={loadingCreateBatch}
            onClick={createBatchJob}
            variant="contained"
          >
            Run batch
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};
