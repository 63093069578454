import React from 'react';
import { DatePicker } from '../../helper';
import { AnalyticsDateSelection } from '../../api/types/AnalyticsTypes';

const DateSelection = function ({
  startDate, endDate, handleEndDateChange, handleStartDateChange,
}: AnalyticsDateSelection) {
  return (
    <div className="flex mt-2 mb-4 items-center items-start">
      <DatePicker
        additionalClass="bg-white rounded-md mt-2 mr-5 shadow-lg"
        date={startDate}
        handleChange={handleStartDateChange}
        label="Start Date"
      />
      <div className="mr-3">
        ---
      </div>
      <DatePicker
        additionalClass="bg-white rounded-md mt-2 mr-10 shadow-lg"
        date={endDate}
        handleChange={handleEndDateChange}
        label="End Date"
      />
    </div>
  );
};

export default DateSelection;
