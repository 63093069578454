import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './components/api/user-api/UserAPI';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <Router>
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  </StyledEngineProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
