import React from 'react';

/**
 * This cell basically just shows up as "MISSING VALUE" in red if the string value passed to the cell is falsy.
 * @param value the value supposed to be displayed in the cell
 */
// eslint-disable-next-line import/prefer-default-export
export const ValidatedCell = function ({ value }:{ value: string }) {
  return value ? <div>{value}</div> : <div className="text-red-500 bg-red-100 text-center">MISSING VALUE</div>;
};
