import React from 'react';
import { motion } from 'framer-motion';
import DocumentCard from '../document-card/DocumentCard';
import { CardsProp, CardData } from '../../api/types/DocumentTypes';
import { titleCase } from '../../api/string-api/StringAPI';
import { staggeredEntryContainer, staggeredEntryItem } from '../../api/framer-motion-api/FramerMotionAPI';

/**
 * Based on the category property inside the array
 * create a key value pair of {category: CardData[]}
 * to iterate over
 * @param documents: CardData[]
 */
const splitIntoCategories = (documents: CardData[]) => {
  const categoryMap : { [key: string]: CardData[] } = {};
  documents.forEach((doc) => {
    if (!Object.prototype.hasOwnProperty.call(categoryMap, doc.category)) {
      categoryMap[doc.category] = [];
    }
    categoryMap[doc.category].push(doc);
  });
  return categoryMap;
};

/**
 * The document carousel display for the card options
 * @param associationDocuments :CardProps -> The list of selection options for the user to pick from
 * @constructor
 */
const DocumentCarousel = function ({ associationDocuments }: CardsProp) {
  const categoryMap: { [key: string]: CardData[] } = splitIntoCategories(associationDocuments);

  return (
    <div>
      {/* Loops over every category. Allows for dynamic displays based on data */}
      {Object.keys(categoryMap).map((category) => (
        <motion.div
          key={category}
          className="container mb-12"
          variants={staggeredEntryContainer}
          initial="hidden"
          animate="visible"
        >
          <div className="text-4xl font-semibold font-montserrat">{titleCase(category)}</div>
          <hr />
          <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {/* Creates a card for every item inside a category */}
            {categoryMap[category].map((doc) => (
              <motion.div
                key={doc.title}
                className="snap-center mr-12 shrink-0 scale-100"
                variants={staggeredEntryItem}
              >
                <DocumentCard cardInfo={doc} />
              </motion.div>
            ))}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default DocumentCarousel;
