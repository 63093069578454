import React from 'react';
import {
  collection, doc, onSnapshot, query, where, orderBy, getDoc, getDocs,
  DocumentData, QueryOrderByConstraint, QueryFieldFilterConstraint,
} from 'firebase/firestore';
import { db } from '../../../firebase-config';
import errorHistory from '../error-api/ErrorAPI';
import { QueryType } from '../types/FirebaseTypes';

/**
 * Returns all collection items in an array with their respective IDs. Used for
 * getting the list of collections and using the name to expand deeper.
 * @param setLoading Takes the useState function to update loading
 * @param setResult Takes the useState function to update the result
 * @param collectionPath The collection to search
 */
export const listCollections = (
  setLoading: (arg0: boolean) => void,
  setResult: (arg0: string[]) => void,
  collectionPath: string,
) => onSnapshot(query(collection(db, collectionPath)), (((snapshot) => {
  const collectionNames: string[] = [];
  snapshot.forEach((single_doc) => {
    collectionNames.push(single_doc.id);
  });
  setResult(collectionNames);
  setLoading(false);
})));

/**
 * Watches a collection of documents for a change, updating the result
 * into the setResult parameter.
 * @param setLoading Takes the useState function to update loading
 * @param setResult Takes the useState function to update the result
 * @param collectionPath The collection to search
 * @param queries a list of filters to apply
 * @param order an object with the desired field and direction
 */
export const watchCollection = (
  setLoading: (arg0: boolean) => void,
  setResult: React.Dispatch<React.SetStateAction<any>>,
  collectionPath: string,
  queries?: QueryType[],
  order?: { field: string, direction: 'asc' | 'desc' },
) => {
  const generatedQueries: (QueryFieldFilterConstraint | QueryOrderByConstraint) [] = queries
    ? queries.map((queryData) => where(queryData.fieldPath, queryData.opString, queryData.value))
    : [];

  if (order) {
    generatedQueries.push(orderBy(order.field, order.direction));
  }

  const qu = query(collection(db, collectionPath), ...generatedQueries);
  return onSnapshot(qu, (((snapshot) => {
    const docCollection: DocumentData[] = [];
    snapshot.forEach((single_doc) => {
      docCollection.push({ pdocId: single_doc.id, ...single_doc.data() });
    });
    setResult(docCollection);
    setLoading(false);
  })));
};

/**
 * Watches a single document for changes and updates the change into setResult
 * @param path The path to the document
 * @param setLoading Takes the useState function to update loading
 * @param setResult Takes the useState function to update the result
 */
export const watchDocument = (
  setLoading: (arg0: boolean) => void,
  setResult: React.Dispatch<React.SetStateAction<any>>,
  path: string,
) => (
  onSnapshot(doc(db, path), (single_doc) => {
    setResult(single_doc.data());
    setLoading(false);
  }, (error) => {
    const { code } = error;
    const { message } = error;
    errorHistory.setAndSend(code, message);
  }));

/**
 * Returns a one time, non-live, document. Best used in situations where you don't need to watch
 * changes in a document or need an updating list of information.
 * @param path The path to the document
 * @param docId The document id of the requested document
 */
export const getDocument = async (path: string, docId: string) => {
  const docRef = doc(db, path, docId);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};

/**
 * Tries to retrieve a document, if it doesn't exist, returns null.
 * @param path path of doc
 * @param docId doc id
 */
export const getDocumentNull = async (path: string, docId: string) => {
  const docRef = doc(db, path, docId);
  try {
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch {
    return null;
  }
};

/**
 * Retrieves a job doc given the job id.  Depending on what stage a job is at, it will either be in the
 * 'jobs' or 'completedJobs' collection (it gets moved entirely during status change so it won't be in both at the same time).
 * In order to retrieve a job, status-agnostic, we query both collections and return whichever is found.
 * @param jobId id of the job we are retrieving
 */
export const getJobDoc = async (jobId: string) => {
  const job = await getDocumentNull('jobs', jobId);
  const completedJob = await getDocumentNull('completedJobs', jobId);
  return job || completedJob;
};

/**
 * Returns a one time, non-live, collection of documents.
 * Best used in situations where you don't need to watch
 * changes in a document or need an updating list of information.
 * @param collectionPath The collection to search
 * @param queries a list of filters to apply
 */
export const getCollectionDocs = async (
  collectionPath: string,
  queries?: QueryType[],
) => {
  const generatedQueries = queries
    ? queries.map((queryData) => where(queryData.fieldPath, queryData.opString, queryData.value))
    : [];

  const qu = query(collection(db, collectionPath), ...generatedQueries);
  const querySnapshot = await getDocs(qu);
  const docCollection: DocumentData[] = [];
  querySnapshot.forEach((single_doc) => {
    // doc.data() is never undefined for query doc snapshots
    docCollection.push({ pdocId: single_doc.id, ...single_doc.data() });
  });
  return docCollection;
};
