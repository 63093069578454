import { Timestamp } from 'firebase/firestore';

/**
 * Gets the current date of today with all time set to 0
 */
export const getToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return Timestamp.fromDate(today);
};

/**
 * Converts a date into a firebase date
 * @param date a provided JavaScript datetime
 */
export const firebaseDateConvert = (date: Date | null | undefined) => (date ? Timestamp.fromDate(date) : null);
