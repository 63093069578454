import { Box, Modal } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { enqueueSnackbar } from 'notistack';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GlCategoryDoc, PayrollCodeMappingDoc, PayrollCodeRow } from '../gl-category-types';
import { functions } from '../../../../../firebase-config';
import { watchCollection } from '../../../../api/document-api/DocumentAPI';
import { AssignGlCategoryCell } from './cells/AssignGlCategoryCell';
import { EditPayrollCodeMappingsModalParams } from '../gl-category-params';
import { getModalStyle } from './modal-helpers';

// modal style object
const style = getModalStyle(800);

/**
 * Generates text which describes the number of unmapped payroll codes remaining.
 * @param payrollCodes all payroll codes
 * @param payrollCodeMappings existing payroll code mappings
 */
const getUnmappedCodesText = (payrollCodes: string[], payrollCodeMappings: PayrollCodeMappingDoc[]) => {
  const mappedPayrollCodes = payrollCodeMappings
  // filter out mappings with empty gl category
    .filter(({ glCategory }) => !!glCategory)
  // return payroll code (doc id)
    .map(({ pdocId }) => pdocId);
  // get all unmapped codes filtering out mapped codes from list of total codes
  const unmappedCodes = payrollCodes.filter((code) => !mappedPayrollCodes.includes(code));
  return `${unmappedCodes.length} codes unmapped`;
};

/**
 * Generates DataGrid columns for payroll mappings DataGrid.
 * @param glCategories gl categories
 * @param payrollCodeMappings existing payroll code mappings
 */
const getColumns = (glCategories: GlCategoryDoc[], payrollCodeMappings: PayrollCodeMappingDoc[]): GridColDef[] => [
  {
    field: 'code',
    headerName: 'Payroll Code',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Assign GL Category',
    flex: 1,
    valueGetter: (val, { code }: PayrollCodeRow) => {
      // for sorting purposes, we want to pull the mapped GL Category name
      const mapping = payrollCodeMappings.find(({ pdocId }) => pdocId === code);
      if (!mapping) return '';
      const mappedCategory = glCategories.find(({ pdocId }) => pdocId === mapping.glCategory);
      return mappedCategory?.displayName || '';
    },
    renderCell: ({ row }: GridRenderCellParams<PayrollCodeRow>) => (
      <AssignGlCategoryCell
        payrollCode={row.code}
        glCategories={glCategories}
        payrollCodeMappings={payrollCodeMappings}
      />
    ),
  },
];

/**
 * Modal which allows user to edit payroll code mappings.
 * @param setEditPayrollCodes setter for boolean value which controls whether modal is open
 * @param glCategories all gl categories
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const EditPayrollCodeMappingsModal = function (
  { setEditPayrollCodes, glCategories }: EditPayrollCodeMappingsModalParams,
) {
  // list of all payroll codes
  const [payrollCodes, setPayrollCodes] = useState<string[]>([]);
  // existing payroll-code-to-gl-category mappings
  const [payrollCodeMappings, setPayrollCodeMappings] = useState<PayrollCodeMappingDoc[]>([]);
  // loading states
  const [loadingPayrollCodeMappings, setLoadingPayrollCodeMappings] = useState<boolean>(true);
  const [loadingPayrollCodes, setLoadingPayrollCodes] = useState<boolean>(true);

  // get all payroll codes
  useEffect(() => {
    setLoadingPayrollCodes(true);
    const getPayrollCodes = httpsCallable(functions, 'getPayrollEarningsCodes');
    getPayrollCodes()
      .then((res) => setPayrollCodes(res.data as string[]))
      .catch(() => { enqueueSnackbar('Failed to retrieve payroll codes', { variant: 'error' }); })
      .finally(() => setLoadingPayrollCodes(false));
  }, []);

  // set watcher on payroll code mappings collection
  useEffect(() => {
    const unsubscribe = watchCollection(
      setLoadingPayrollCodeMappings,
      setPayrollCodeMappings,
      'payroll/payrollCodes/mappings',
    );
    return () => unsubscribe();
  }, []);

  const columns = useMemo(() => getColumns(glCategories, payrollCodeMappings), [glCategories, payrollCodeMappings]);
  // generate object rows from payroll codes
  const rows = payrollCodes.map((code) => ({ code }));
  // text which describes how many payroll codes are still unmapped
  const unmappedCodesText = getUnmappedCodesText(payrollCodes, payrollCodeMappings);

  const loadingDatagrid = loadingPayrollCodes || loadingPayrollCodeMappings;

  return (
    <Modal
      open
      onClose={() => setEditPayrollCodes(false)}
    >
      <Box sx={style}>
        {/* Modal header */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '20px',
          justifyContent: 'space-between',
        }}
        >
          <div style={{ fontSize: '20px' }}>Edit Payroll Code Mappings</div>
          <div>{unmappedCodesText}</div>
        </div>
        {/* Payroll code mappings DataGrid */}
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            loading={loadingDatagrid}
            rows={rows}
            rowHeight={80}
            columns={columns}
            getRowId={(row: PayrollCodeRow) => row.code}
          />
        </Box>
      </Box>
    </Modal>
  );
};
