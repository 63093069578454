/* eslint-disable react/jsx-props-no-spreading */
import React, { createRef, useCallback, useEffect } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { Button } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import { Container, SnackbarAlert } from '../helper';
import {
  uploadFiles, fileValidator, invalidFile, uploadMetadata,
} from '../api/upload-api/UploadAPI';

import UploadTitleCounter from './upload-title-counter/UploadTitleCounter';
import UploadBox from './upload-box/UploadBox';
import UploadContainer from './upload-container/UploadContainer';
import navigateTo from '../api/navigation-api/NavigationAPI';
import { translate } from '../api/string-api/StringAPI';

/**
 * The upload page is responsible for the grouping of components relating to and
 * handling of the upload process.
 *
 * UploadTitleCounter --> Displays the number of files successfully uploaded
 * UploadContainer --> The styling that wraps the upload box
 * @constructor
 */
const UploadPage = function () {
  // The below useStates are in the parent element so that the children may modify them.
  // A display used to notify the user how many successful uploads have been processed.
  const [counter, setCounter] = React.useState(0);
  // Sets the snackbar alert message to display. Both for negative and positive states.
  const [message, setMessage] = React.useState('');
  // Open and close for the snackbar alert
  const [open, setOpen] = React.useState(false);
  // The coloring for the alert bar
  const [status, setStatus] = React.useState<AlertColor>('success');

  const dropzoneRef : React.RefObject<DropzoneRef> = createRef();

  // Responsible for triggering a callback for when the user drops a file into the dropzone
  const onDrop = useCallback((acceptedFiles) => {
    uploadFiles(
      {
        acceptedFiles, counter, setCounter, setMessage, setOpen, setStatus,
      },
    );
  }, []);

  /**
    * This metadata comes from when you click on the card to navigate into upload.
    * The information is covertly attached to the card which allows us to target specific endpoints.
    * This method validates that we came through a card and not by typing the url.
    * Button reroutes to home.
    */
  useEffect(() => {
    if (!uploadMetadata.location || uploadMetadata.location === '') navigateTo('/');
  });

  return (
    <Container>
      <SnackbarAlert message={message} open={open} setOpen={setOpen} status={status} />
      <UploadTitleCounter counter={counter} />
      <Dropzone
        maxFiles={50}
        validator={fileValidator}
        accept={uploadMetadata.fileTypes}
        onDrop={onDrop}
        ref={dropzoneRef}
      >
        {({
          fileRejections, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject,
        }) => (
          <UploadContainer
            className="h-96 flex flex-col justify-content items-center"
            {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          >
            <UploadBox
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
              getInputProps={getInputProps}
            />
            {/* fileRejections is increased by the Dropzone component whenever an invalid filed is used. This displays a warning */}
            {fileRejections.length > 0 && invalidFile(fileRejections, setMessage, setStatus, setOpen)}
          </UploadContainer>
        )}
      </Dropzone>
      <div className="flex flex-row-reverse">
        <Button
          className="upload-button mt-8 mr-4"
          variant="contained"
          onClick={() => navigateTo('', true)}
        >
          {translate('UPLOAD_BUTTON')}
        </Button>
      </div>
    </Container>
  );
};

export default UploadPage;
