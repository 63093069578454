import { useLocation } from 'react-router-dom';
import { AlertColor } from '@mui/material/Alert/Alert';
import navigateTo from '../navigation-api/NavigationAPI';

/**
 * successNav is a wrapper for navigateTo, providing additional
 * state to the navigation. successNav is a default shorthand for
 * applying the severity of 'success' to the Snackbar component
 * @param location The route to navigate too.
 * @param message The state message to pass
 * @param absolute Use absolute pathing or not
 */
export const successNav = (location: string, message: string, absolute = false) => {
  navigateTo(location, absolute, {
    snackbarMessage: message,
    severity: 'success',
  });
};

/**
 * infoNav is a wrapper for navigateTo, providing additional
 * state to the navigation. infoNav is a default shorthand
 * for applying the severity of 'info' to the Snackbar
 * component
 * @param location The route to navigate too.
 * @param message The state message to pass
 * @param absolute Use absolute pathing or not
 */
export const infoNav = (location: string, message: string, absolute = false) => {
  navigateTo(location, absolute, {
    snackbarMessage: message,
    severity: 'info',
  });
};

/**
 * warningNav is a wrapper for navigateTo, providing additional
 * state to the navigation. infoNav is a default shorthand
 * for applying the severity of 'warning' to the Snackbar
 * component
 * @param location The route to navigate too.
 * @param message The state message to pass
 * @param absolute Use absolute pathing or not
 */
export const warningNav = (location: string, message: string, absolute = false) => {
  navigateTo(location, absolute, {
    snackbarMessage: message,
    severity: 'info',
  });
};

/**
 * errorNav is a wrapper for navigateTo, providing additional
 * state to the navigation. errorNav is a default shorthand
 * for applying the severity of 'error' to the Snackbar
 * component
 * @param location The route to navigate too.
 * @param message The state message to pass
 * @param absolute Use absolute pathing or not
 */
export const errorNav = (location: string, message: string, absolute = false) => {
  navigateTo(location, absolute, {
    snackbarMessage: message,
    severity: 'error',
  });
};

/**
 * Retrieves the snackbarMessage.
 * Returns '' if not defined.
 */
export const snackbarMessage = () => {
  const location = useLocation() as { state?: { snackbarMessage?: string } };
  return location?.state?.snackbarMessage || '';
};

/**
 * Retrieves the snackbarSeverity.
 * Return 'success' if not defined.
 */
export const snackbarSeverity = () => {
  const location = useLocation() as { state?: { severity?: string } };
  return location?.state?.severity as AlertColor || 'success' as AlertColor;
};
