import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { wrap } from 'popmotion';
import {
  loadingJobStatus, loadingJobTip,
  loadingJobTitle,
} from '../../api/framer-motion-api/FramerMotionAPI';
import { LoadingTips } from '../../api/association-api/AssociationAPI';
import { translate } from '../../api/string-api/StringAPI';

/**
 * AssociationJobLoading is used as a holding page for users after they submit a job.
 * The purpose of this page it to wait for associations to be required (if there are non currently ready)
 * before sending the user back into associations. This is because we don't want users to assign one job at a time,
 * so instead we wait for an arbitrary amount of jobs to come back within a given countdown.
 * @param displayState The string state display to use
 * @constructor
 */
const AssociationJobLoading = function (
  { displayState }: { displayState:string },
) {
  // If the main page is hidden, this page should be visible.
  const displayStateFlip = displayState === 'visible' ? 'hidden' : 'visible';
  const jobStatusController = useAnimation();

  const [tipNumber, setTipNumber] = useState(0);
  const [loadingTips, setLoadingTips] = useState(displayStateFlip);

  // Wrap allows for infinite pagination, anytime the number hits 5, it loops back to 0.
  // This is useful for allowing AnimatePresence to successfully exit each new key
  const tipIndex = wrap(0, 5, tipNumber);

  // Initiates the animation displays, starting with the title
  // Finishing by setting the loading tips animation to be displayed
  const sequence = async (animationString: string) => {
    await jobStatusController.start(animationString);
    setLoadingTips(displayStateFlip);
  };

  // We want to run the sequence whenever the display is flipped
  useEffect(() => {
    sequence(displayStateFlip).catch(() => { console.error('Failed to change text'); });
  }, [displayStateFlip]);

  useEffect(() => {
    const timer = () => {
      setTipNumber((prevTipNumber: number) => prevTipNumber + 1);
    };
    const intervalFunc = setInterval(timer, 4000);

    return () => { clearInterval(intervalFunc); };
  }, []);

  return (
    <div className="font-montserrat text-2xl flex flex-col justify-center items-center">
      <motion.div
        className="flex justify-center"
        variants={loadingJobTitle}
        initial="hidden"
        animate={displayStateFlip}
      >
        Running datalink
      </motion.div>

      <motion.div
        className="text-lg"
        variants={loadingJobStatus}
        initial="hidden"
        animate={jobStatusController}
      >
        Running Job(s)
      </motion.div>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={tipNumber}
          className="text-sm mt-4"
          variants={loadingJobTip}
          initial="hidden"
          animate={loadingTips}
          exit="exit"
        >
          {translate(LoadingTips[tipIndex])}
        </motion.div>
      </AnimatePresence>

    </div>
  );
};
export default AssociationJobLoading;
