import React from 'react';
// @ts-ignore
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Sankey as NivoSankey } from '@nivo/sankey';
import { AnalyticColors, getSankeyAnalytic } from '../../../api/analyticis-api/AnalyticsAPI';
import { JobStatus } from '../../../api/types/JobStatusTypes';

const Sankey = function ({ jobStatus }: { jobStatus: JobStatus[] | undefined }) {
  const jobStatusData = jobStatus || [];
  const data = getSankeyAnalytic(jobStatusData) || [];

  return (
    <AutoSizer>
      {/* eslint-disable-next-line react/no-unused-prop-types */}
      {({ height, width }: { height: number, width: number }) => (
        (data.nodes.length > 0 && (
          <NivoSankey
            height={height}
            width={width}
            data={data}
            margin={{
              top: 40, right: 150, bottom: 60, left: 70,
            }}
            align="justify"
            colors={AnalyticColors.sankey}
            nodeOpacity={1}
            nodeHoverOthersOpacity={0.35}
            nodeThickness={18}
            nodeSpacing={24}
            nodeBorderWidth={0}
            nodeBorderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.8,
                ],
              ],
            }}
            enableLabels
            labelOrientation="horizontal"
            nodeBorderRadius={3}
            linkOpacity={0.5}
            linkHoverOthersOpacity={0.1}
            linkContract={3}
            enableLinkGradient
            labelPosition="outside"
            labelPadding={16}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.5,
                ],
              ],
            }}
          />
        )
        )

      )}
    </AutoSizer>
  );
};

export default Sankey;
