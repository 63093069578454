import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { getJobStatusIcon } from '../../job-status/job-row/JobRow';
import { Data, UploadStatusMetadata } from '../../api/types/JobStatusTypes';
import { getCollectionDocs } from '../../api/document-api/DocumentAPI';
import { LastRunStatusModal } from './LastRunStatusModal';

/**
 * Retrieves the most recent status doc for a given job.  Status docs are structured with a parent doc and a collection
 * of child status docs under said parent, where each child represents a stage the job was at.  We want to pull the
 * most recent child doc, since that contains detailed metadata about the current status of the job.
 * @param jobId id of job we are getting status for
 */
const getMostRecentStatusDoc = async (jobId: string) => {
  const statusDocs = await getCollectionDocs(`jobStatus/${jobId}/status`);
  // sort in descending order so first is most recent
  const sortedStatuses = statusDocs.sort((a, b) => b.timestamp - a.timestamp);
  const mostRecentStatus = sortedStatuses[0];
  return mostRecentStatus.metadata as UploadStatusMetadata;
};

/**
 * Displays the last run status of the given transaction
 * @param lastRunStatus the last run status of a transaction
 */
// eslint-disable-next-line import/prefer-default-export
export const LastRunStatusCell = function ({ lastRunStatus }:{ lastRunStatus: Data }) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [statusData, setStatusData] = useState<UploadStatusMetadata | null>(null);
  const { status, jobId } = lastRunStatus;

  const updateModalOpen = (open: boolean) => setModalOpen(open);

  // retrieves last run status metadata
  useEffect(() => {
    getMostRecentStatusDoc(jobId)
      .then((data) => setStatusData(data))
      .catch(() => enqueueSnackbar('Failed to pull most recent status metadata', { variant: 'error' }));
  }, []);

  // show modal if status has a severity (this means it attempted upload)
  const disabled = !statusData?.severity;
  const icon = getJobStatusIcon(status);

  return (
    <>
      <div className="h-full flex">
        <Button
          variant={disabled ? 'text' : 'outlined'}
          className="flex flex-row items-center my-auto text-xs disabled:text-black disabled:font-normal"
          onClick={() => setModalOpen(true)}
          disabled={disabled}
        >
          {icon}
          <div className="ml-3">{status}</div>
        </Button>
      </div>
      {/* Modal which displays last run status metadata (if relevant) */}
      {!disabled && (
      <LastRunStatusModal
        statusData={statusData}
        modalOpen={modalOpen}
        updateModalOpen={updateModalOpen}
        jobId={jobId}
      />
      )}
    </>
  );
};
