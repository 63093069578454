import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { PositionCodeMapping } from '../../gl-category-types';
import { db } from '../../../../../../firebase-config';
import { AssignGlCodeCellParams } from '../../gl-category-params';

/**
 * Update the GL code being mapped to the given position/division code and gl category.
 * @param glCategory current gl category
 * @param positionCode current position code
 * @param divisionCode current division code
 * @param payload the mapping payload containing updated GL code
 */
const updatePositionCodeMapping = (
  glCategory: string,
  positionCode: string,
  divisionCode: string,
  payload: PositionCodeMapping,
) => {
  const docId = `${glCategory}_${positionCode}_${divisionCode}`;
  const docRef = doc(db, 'payroll/positionCodes/mappings', docId);
  setDoc(docRef, payload)
    .then(() => { enqueueSnackbar('Successfully updated position code mapping', { variant: 'success' }); })
    .catch(() => { enqueueSnackbar('Failed to update position code mapping', { variant: 'error' }); });
};

/**
 * Given a list of existing mappings and the current position/division code and gl category, return the currently
 * mapped GL code (if there is one).
 * @param mappings existing position code mappings
 * @param glCategory current gl category
 * @param positionCode current position code
 * @param divisionCode current division code
 */
const getExistingGl = (
  mappings: PositionCodeMapping[],
  glCategory: string,
  positionCode: string,
  divisionCode: string,
) => {
  const existingMapping = mappings.find(
    (mapping) => mapping.glCategory === glCategory
          && mapping.positionCode === positionCode && mapping.divisionCode === divisionCode,
  );
  return existingMapping?.glCode || '';
};

/**
 * DataGrid cell used to assign a GL code to a given position code.
 * @param row the row representing the position code/division code
 * @param positionCodeMappings existing position code mappings
 * @param selectedGlCategory the gl category the mapping is under
 * @param glOptions the list of GL code options
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const AssignGlCodeCell = function ({
  row, positionCodeMappings, selectedGlCategory, glOptions,
}: AssignGlCodeCellParams) {
  // these are the division and position codes that the GL code is being mapped to
  const divisionCode = row.divisionCode?.DivisionCode.toString() || '';
  const positionCode = row.positionCode.PositionCode.toString();
  // the existing GL code that is mapped to the above position/division code (if there is one)
  const existingGl = getExistingGl(positionCodeMappings, selectedGlCategory, positionCode, divisionCode);

  /**
   * Update the GL code currently mapped to the given position/division code.
   * @param glCode newly mapped GL code
   */
  const setPositionMappingGlCode = (glCode: string | null) => {
    // the mapping data
    const payload: PositionCodeMapping = {
      glCategory: selectedGlCategory,
      positionCode,
      glCode: glCode || '',
      divisionCode,
    };
    updatePositionCodeMapping(
      selectedGlCategory,
      positionCode,
      divisionCode,
      payload,
    );
  };

  return (
    <Autocomplete
      style={{ paddingTop: 10, paddingBottom: 10 }}
      value={existingGl}
      options={glOptions}
      renderInput={(params) => <TextField {...params} label="GL Code" />}
      onChange={(e, val) => setPositionMappingGlCode(val)}
    />
  );
};
