import {
  Box, Button, Modal,
} from '@mui/material';
import React from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';
import { db } from '../../../../../firebase-config';
import { getCollectionDocs } from '../../../../api/document-api/DocumentAPI';
import { DeleteGlCategoryConfirmationModalParams } from '../gl-category-params';
import { getModalStyle } from './modal-helpers';

// Modal style object
const style = getModalStyle(400);

/**
 * Given a Firestore collection path and the GL category ID to delete, deletes all mappings containing this
 * GL category ID, in effect deleting all mappings related to the now-deleted category.
 * @param collection a Firestore collection containing mappings
 * @param glCategoryId the ID of the GL category which we are deleting mappings to
 */
const deleteMappingsToGlCategory = async (collection: string, glCategoryId: string) => {
  const collectionDocs = await getCollectionDocs(collection);
  // delete all docs with field "glCategory" equaling the given gl category ID
  const relevantMappings = collectionDocs.filter((mappingDoc) => mappingDoc.glCategory === glCategoryId);
  const deletePromises = relevantMappings.map(
    (mapping) => deleteDoc(doc(db, collection, mapping.pdocId)),
  );
  await Promise.all(deletePromises);
};

/**
 * Deletes the GL category with passed ID, as well as all mappings involving this category.
 * @param glCategoryId the id of the gl category to delete
 */
const deleteGlCategory = async (glCategoryId: string) => {
  // delete the gl category doc
  await deleteDoc(doc(db, 'payroll/glCategories/categories', glCategoryId));
  // delete all payroll code mappings made to the now-deleted category
  await deleteMappingsToGlCategory('payroll/payrollCodes/mappings', glCategoryId);
  // delete all position code mappings made to the now-deleted category
  await deleteMappingsToGlCategory('payroll/positionCodes/mappings', glCategoryId);
};

/**
 * Confirmation modal which pops up when user tries to delete GL Category.
 * @param setDeleteCategoryIdModal setter for ID of gl category to delete (controls modal)
 * @param deleteCategoryIdModal id of gl category to delete
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const DeleteGlCategoryConfirmationModal = function (
  { setDeleteCategoryIdModal, deleteCategoryIdModal }: DeleteGlCategoryConfirmationModalParams,
) {
  /**
   * Deletes the selected GL category and all associated mappings.
   */
  const deleteCategory = () => {
    deleteGlCategory(deleteCategoryIdModal)
      .then(() => {
        // close modal
        setDeleteCategoryIdModal('');
        enqueueSnackbar('Successfully deleted GL category', { variant: 'success' });
      })
      .catch(() => { enqueueSnackbar('Failed to delete GL category', { variant: 'error' }); });
  };

  return (
    <Modal
      open
      onClose={() => setDeleteCategoryIdModal('')}
    >
      <Box sx={style}>
        <div style={{ fontSize: '20px', marginBottom: '20px' }}>Delete GL Category?</div>
        <div>
          Deleting this category cannot be undone, and will undo all payroll & position code mappings to this category.
        </div>
        <Button
          style={{ marginTop: '20px' }}
          variant="contained"
          className="bg-red-500"
          onClick={deleteCategory}
        >
          Delete
        </Button>
      </Box>
    </Modal>
  );
};
