import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

/**
 * This information isn't private, since it's the database security rules that protect access.
 * It is safe because only authenticated users can read/write
 */
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CREDS || '');

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
// conditionally point local frontend to emulated backend
if (process.env.REACT_APP_USE_LOCAL_FUNCTIONS === 'TRUE') connectFunctionsEmulator(functions, 'localhost', 5001);
