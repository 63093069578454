import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/**
 * The wrapper for date selection. Used in filtering by date within the
 * JobStatus Page
 * @param date The datetime object
 * @param handleChange The handler for datetime changes
 * @param label The label to display in the selector
 * @param additionalClass Additional classes to add
 * @constructor
 */
function DatePickerLocal(
  {
    date, handleChange, label, additionalClass = '',
  } :
  {
    date: Date | null,
    handleChange: (newDate: Date | null) => void,
    label:string
    // eslint-disable-next-line react/require-default-props
    additionalClass?: string
  },
) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={`ml-2 ${additionalClass}`}>
        <DatePicker
          label={label}
          value={date}
          onChange={handleChange}
        />
      </div>
    </LocalizationProvider>
  );
}

export default DatePickerLocal;
