import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuid } from 'uuid';
import { FileError, FileRejection } from 'react-dropzone';
import { FirestoreError } from 'firebase/firestore';
import { getUser } from '../user-api/UserAPI';
import { CardMetaData } from '../types/DocumentTypes';
import {
  SetMessage, SetOpen, SetStatus, UploadFilesTypes,
} from '../types/UploadTypes';
import errorHistory from '../error-api/ErrorAPI';

/**
 * The UploadMetadata class keeps running track of the most recently accessed
 * upload card. Using metadata provided from firebase, we can create a page with
 * the necessary upload information
 */
class UploadMetadata {
  location: string;

  fileTypes: string[];

  title: string;

  constructor() {
    this.location = '';
    this.fileTypes = [];
    this.title = '';
  }

  // eslint-disable-next-line class-methods-use-this
  parseGivenMetadata(metadata: CardMetaData, title: string) {
    this.location = metadata.location;
    this.fileTypes = metadata.allowedFileTypes;
    this.title = title;
  }

  // ===== Getters ===== //

  get getLocation() { return this.location; }

  // ===== Setters ===== //

  set updateLocation(location: string) { this.location = location; }
}

export const uploadMetadata = new UploadMetadata();

/**
 * Uploads the file to the designated area
 * @param acceptedFiles
 * @param counter
 * @param setCounter
 * @param setMessage
 * @param setOpen
 * @param setStatus
 */
export const uploadFiles = ({
  acceptedFiles, counter, setCounter, setMessage, setOpen, setStatus,
}: UploadFilesTypes) => {
  let customCounter = counter;

  acceptedFiles.forEach((file: { name: string }) => {
    const metadata = {
      customMetadata: {
        owner: `${getUser()?.uid || 'Unknown'}`,
        location: uploadMetadata.location,
      },
    };
    const storage = getStorage();
    const storageRef = ref(storage, `invoices/${uuid()}.pdf`);
    uploadBytes(storageRef, file as unknown as Blob, metadata)
      .then(() => {
        setMessage(`Uploaded ${file.name} successfully!`);
        setStatus('success');
        customCounter += 1;
        setCounter(customCounter);
        setOpen(true);
      })
      .catch((error: FirestoreError) => {
        const { code } = error;
        const { message } = error;
        errorHistory.setAndSend(code, message);
      });
  });
};

/**
 * A validator used by the Dropzone component.
 * Handles size restrictions.
 * @param file
 */
export const fileValidator = (file: File): FileError | FileError[] | null => {
  const maxSize = 1073741824;
  if (file.size > maxSize) {
    return {
      code: 'file-too-large',
      message: `File is larger than ${maxSize} bytes`,
    };
  }
  return null;
};

/**
 * A wrapper designed to raise an alert within the UploadPage.
 * Sets the necessary to display the SnackbarAlert component
 * @param {FileRejection[]} files - The problematic files returned by fileRejections
 * @param {SetMessage} setMessage - React.useState for message
 * @param {SetStatus} setStatus - React.useState for status
 * @param {SetOpen} setOpen - React.useState for open
 */
export const invalidFile = (files: FileRejection[], setMessage: SetMessage, setStatus: SetStatus, setOpen: SetOpen) => {
  setOpen(false);
  // TODO JACOB Review this
  files.forEach((file) => {
    setOpen(false);
    setStatus('error');
    setMessage(`Failed to upload ${file.file.name}. Reason: ${file.errors[0].message}`);
    setOpen(true);
  });

  // We reset the length, so we can redisplay a warning another bad file is used.
  // eslint-disable-next-line no-param-reassign
  files.length = 0;
};
