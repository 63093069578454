import React, { useEffect, useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {
  Alert, Badge, Box, Button, CircularProgress, IconButton,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { httpsCallable } from 'firebase/functions';
import { enqueueSnackbar } from 'notistack';
import { AlertTitle } from '@mui/lab';
import { GlCategoryConfigModal } from './modals/GlCategoryConfigModal';
import { watchCollection } from '../../../api/document-api/DocumentAPI';
import { GlCategoryDoc, MissingMappings } from './gl-category-types';
import { EditPayrollCodeMappingsModal } from './modals/EditPayrollCodeMappingsModal';
import { DeleteGlCategoryConfirmationModal } from './modals/DeleteGlCategoryConfirmationModal';
import { EditPositionCodeMappingsModal } from './modals/EditPositionCodeMappingsModal';
import { functions } from '../../../../firebase-config';

/**
 * Generates columns for GL Categories DataGrid.
 * @param updateEditingCategoryId setter for ID of gl category being edited (this controls edit gl category modal)
 * @param updateDeleteCategoryIdModal setter for ID of gl category being deleted (this control delete gl category modal)
 */
const getGlCategoryColumns = (
  updateEditingCategoryId: (id: string) => void,
  updateDeleteCategoryIdModal: (id: string) => void,
): GridColDef[] => [
  {
    field: 'displayName',
    headerName: 'Category Name',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Edit Rules',
    flex: 1,
    renderCell: (params) => (
      <IconButton
        onClick={() => updateEditingCategoryId(params.id.toString())}
      >
        <Edit />
      </IconButton>
    ),
  },
  {
    field: 'delete',
    headerName: 'Delete Category',
    flex: 1,
    renderCell: (params) => (
      <IconButton
        onClick={() => updateDeleteCategoryIdModal(params.id.toString())}
      >
        <Delete />
      </IconButton>
    ),
  },
];

// Alert if there are missing mappings
const mappingsMissingAlert = (
  <Alert severity="warning">
    <AlertTitle>You are missing mappings!</AlertTitle>
    Mappings are required to process next payroll run.
  </Alert>
);

// Alert if all mappings are complete i.e. no missing mappings
const mappingsCompletedAlert = (
  <Alert severity="success">
    <AlertTitle>All mappings completed!</AlertTitle>
    All mappings have been completed for the next payroll run.
  </Alert>
);

/**
 * Displays GL categories within a DataGrid.
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const GlCategories = function () {
  const [glCategories, setGlCategories] = useState<GlCategoryDoc[]>([]);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
  // the ID of the gl category currently being edited
  const [editingCategoryId, setEditingCategoryId] = useState<string>('');
  // whether we are editing payroll code mappings
  const [editPayrollCodes, setEditPayrollCodes] = useState<boolean>(false);
  // the ID of the gl category currently being deleted (controls confirmation modal)
  const [deleteCategoryIdModal, setDeleteCategoryIdModal] = useState<string>('');
  // whether we are editing position code mappings
  const [editPositionCodes, setEditPositionCodes] = useState<boolean>(false);
  // record of missing mappings (e.g. position code or payroll code mappings, or missing GL categories)
  const [missingMappings, setMissingMappings] = useState<MissingMappings | null>(null);
  const [loadingMissingMappings, setLoadingMissingMappings] = useState<boolean>(true);

  const updateEditingCategoryId = (id: string) => setEditingCategoryId(id);
  const updateEditPayrollCodes = (edit: boolean) => setEditPayrollCodes(edit);
  const updateDeleteCategoryIdModal = (id: string) => setDeleteCategoryIdModal(id);
  const updateEditPositionCodes = (edit: boolean) => setEditPositionCodes(edit);

  // watch gl categories collection
  useEffect(() => {
    const unsubscribe = watchCollection(setLoadingCategories, setGlCategories, 'payroll/glCategories/categories');
    return () => unsubscribe();
  }, []);

  // get missing mappings, if any
  useEffect(() => {
    // if either of these are true, it means a modal is open - we only want to re-pull missing mappings upon closing the modal (so these values would be false)
    if (editPayrollCodes || editPositionCodes) return;
    setLoadingMissingMappings(true);
    httpsCallable(functions, 'isMissingMappings')()
      .then((res) => { setMissingMappings(res.data as MissingMappings); })
      .catch(() => { enqueueSnackbar('Failed to retrieve missing mappings data', { variant: 'error' }); })
      .finally(() => setLoadingMissingMappings(false));
  }, [glCategories, editPayrollCodes, editPositionCodes]);

  // generate datagrid columns
  const columns = getGlCategoryColumns(updateEditingCategoryId, updateDeleteCategoryIdModal);

  // whether there are missing global mappings (i.e. unmapped payroll codes or unmapped position codes)
  const hasMissingMappings = !!missingMappings?.unmappedPayrollCodes?.length
      || !!missingMappings?.unmappedPositions?.length;

  // alert which displays whether there are any missing global mappings
  const mappingsStaticAlert = hasMissingMappings ? mappingsMissingAlert : mappingsCompletedAlert;

  return (
    <div>
      <div className="flex flex-row justify-between items-center mt-4">
        {/* Description */}
        <div>
          <div className="text-lg">Create, edit and delete GL categories.</div>
          {/* Missing GL categories static alert (if there are any missing) */}
          {!!missingMappings?.missingGlCategories?.length && (
          <Alert severity="warning">
            {`Missing GL categories: ${missingMappings.missingGlCategories.join(', ')}`}
          </Alert>
          )}
        </div>
        {/* Missing mappings static alert (i.e. position code and payroll code mappings) */}
        {loadingMissingMappings && (<CircularProgress className="mx-20" />)}
        {!loadingMissingMappings && mappingsStaticAlert}
      </div>
      {/* GL categories datagrid */}
      <Box sx={{ height: 520, width: '100%', marginTop: '20px' }}>
        <DataGridPro
          loading={loadingCategories}
          rows={glCategories}
          columns={columns}
          getRowId={(row: GlCategoryDoc) => row.pdocId}
        />
      </Box>
      <div className="flex flex-row justify-between mt-4">
        {/* Add GL Category button */}
        <Button
          startIcon={<Add />}
          variant="contained"
          onClick={() => setEditingCategoryId(uuid())}
        >
          Add GL Category
        </Button>
        <div className="flex gap-x-4">
          {/* Edit position code mappings button */}
          <Badge
            badgeContent={missingMappings?.unmappedPositions?.length || 0}
            color="error"
          >
            <Button
              startIcon={<Edit />}
              variant="contained"
              className="bg-teal-500"
              onClick={() => setEditPositionCodes(true)}
            >
              Edit Position Code Mappings
            </Button>
          </Badge>
          {/* Edit payroll code mappings button */}
          <Badge
            badgeContent={missingMappings?.unmappedPayrollCodes?.length || 0}
            color="error"
          >
            <Button
              startIcon={<Edit />}
              variant="contained"
              className="bg-green-500"
              onClick={() => setEditPayrollCodes(true)}
            >
              Edit Payroll Code Mappings
            </Button>
          </Badge>
        </div>
      </div>
      {/*  Create GL Category modal */}
      {editingCategoryId && (
      <GlCategoryConfigModal
        setEditingCategoryId={updateEditingCategoryId}
        editingCategoryId={editingCategoryId}
        glCategories={glCategories}
      />
      )}
      {/*  Edit payroll code mappings modal */}
      {editPayrollCodes && (
      <EditPayrollCodeMappingsModal
        setEditPayrollCodes={updateEditPayrollCodes}
        glCategories={glCategories}
      />
      )}
      {/*  Confirmation modal for deleting a GL category */}
      {deleteCategoryIdModal && (
      <DeleteGlCategoryConfirmationModal
        deleteCategoryIdModal={deleteCategoryIdModal}
        setDeleteCategoryIdModal={updateDeleteCategoryIdModal}
      />
      )}
      {/*  Edit position code mappings modal */}
      {editPositionCodes && (
      <EditPositionCodeMappingsModal
        setEditPositionCodes={updateEditPositionCodes}
        glCategories={glCategories}
      />
      )}
    </div>
  );
};
