type StringType = { [key: string]: { [key:string]: string } };

/**
 * A JSON object holding a key value pair of language code and
 * string keys. Allows for strings to be translated into the correct
 * language when assigned
 */
const STRING_CONF: StringType = {
  en: {
    ADMIN_TITLE: 'Admin Menu',
    ADMIN_SUBTITLE: 'Select your operation from the list below',

    ADMIN_ADJUST_CATEGORIES_TITLE: 'Job Categories',
    ADMIN_ADJUST_CATEGORIES_SUBTITLE: 'Update information pertaining to job categories',
    ADMIN_ASSOCIATION_DELETE: 'Delete Association',
    ADMIN_BUTTON: 'Submit Changes',
    ADMIN_CREATE_TITLE: 'Update User Information',
    ADMIN_CREATE_SUBTITLE: 'Select a user to adjust their permissions',
    ADMIN_RERUN_TITLE: 'Rerun Jobs',
    ADMIN_RERUN_SUBTITLE: 'Rerun specific (or all) jobs!',
    ADMIN_SELECT_CATEGORY: 'Job Category Ownership',
    ADMIN_SELECT_ROLES: 'Permission',
    ADMIN_SELECT_USER: 'User to adjust',
    ADMIN_MATCHES_TITLE: 'Association Configuration Page',
    ADMIN_MATCHES_SUBTITLE: 'Select an engine to begin configuration on an association',
    ADMIN_UPDATED_INFO: 'Success: Information Changed',

    ANALYTICS_TITLE: 'Analytics Page',
    ANALYTICS_SUBTITLE: 'View analytics about user jobs and categories',

    ASSOCIATION_TITLE: 'Make any required associations',
    ASSOCIATION_SUBTITLE: '',

    ASSOCIATION_CONFIRM_ACCEPT: 'Yes, Proceed',
    ASSOCIATION_CONFIRM_BODY_ONE: 'Not all of the required field are filled (those outlined in red or with an *). '
      + 'You may continue the association anyway. However, the skipped fields will not be processed and will need to be'
      + ' assigned in the future',
    ASSOCIATION_CONFIRM_BODY_TWO: 'Would you like to proceed?',
    ASSOCIATION_CONFIRM_DENY: 'No, Cancel',
    ASSOCIATION_CONFIRM_TITLE: 'WARNING: Not all required fields are filled',

    ASSOCIATION_PROCESSING_TITLE: 'Gathering Jobs before starting association',
    ASSOCIATION_PROCESSING_SUBTITLE: 'Jobs are still coming in...',
    ASSOCIATION_PROCESSING_BUTTON: 'Skip the wait and start!',
    ASSOCIATION_PROCESSING_COUNT: ' - Processing',
    ASSOCIATION_ASSOCIATING_COUNT: ' - Ready to Associate',

    ASSOCIATION_creditCard: 'Credit card',
    ASSOCIATION_remittanceVendor: 'Remittance Vendor',
    ASSOCIATION_propertyUnit: 'Property and Unit',
    ASSOCIATION_cashAccount: 'Cash Account',
    ASSOCIATION_payee: 'Vendor/Payee',
    ASSOCIATION_invoicePaymentType: 'Invoice Type',
    ASSOCIATION_FINISH_BUTTON: 'Finish Associations',

    CARD_DISABLED_MESSAGE: 'This service is currently disabled, please try again later.',

    LOADING_AUTOCOMPLETE: 'Loading please wait...',
    LOADING_START: 'Starting the process',
    LOADING_TIP_1: 'Assigning Connections',
    LOADING_TIP_2: 'Testing Routes',
    LOADING_TIP_3: 'Validating Information',
    LOADING_TIP_4: 'Processing associations',
    LOADING_TIP_5: 'Finalizing Data',
    LOADING_TIP_6: 'Test 6',
    LOADING_TIP_7: 'Test 7',

    LOGIN_TITLE: 'WELCOME BACK!',
    LOGIN_SUBTITLE: 'We look forward to helping you!',
    LOGIN_BUTTON: 'Sign into DataLink',

    ERROR_TITLE_ONE: 'Oh no! ',
    ERROR_TITLE_TWO: 'We\'ve fallen apart!',
    ERROR_SUBTITLE: 'Don\'t worry, we\'ve got it covered.',
    ERROR_MESSAGE: 'Error Message: ',
    ERROR_CODE: 'Error Code: ',
    ERROR_EMAIL_MESSAGE: 'Report sent, our developers will be right on that',
    ERROR_BUTTON: 'Send us a report',
    ERROR_REQUIRED_FIELDS: 'Please fill out all required field',
    ERROR_FAILED_UPDATE: 'Failed to set update information',

    JOB_TITLE: 'Pending Associations: ',
    JOB_TITLE_NONE: 'No Associations!',
    JOB_STATUS_APPROVE_DUPLICATE: 'Mark Duplicate as Final',
    JOB_STATUS_RERUN: 'Rerun Job From Beginning',
    JOB_STATUS_RERUN_ALL: 'Reupload all Docsumo docs',
    JOB_SUBTITLE: 'In order for the application to continue, you will need to fill out the rest of the associations',
    JOB_SUBTITLE_NONE: 'It looks like you\'re all set for now! Check out home to upload some invoices and get started!',

    NOT_FOUND_TEXT: 'This page looks dark! Did you mean to go',
    NOT_FOUND_BUTTON: 'ELSEWHERE?',

    SUBMIT_PENDING: 'Success: Your action is being processed. Please wait.',
    SUBMIT_REJECTED: 'Failure: Not all required fields are filled',

    UPLOAD_INVALID_TYPE: 'Oh no! I cant take that file type',
    UPLOAD_INSTRUCTIONS: 'Drag and drop your files here!',
    UPLOAD_READY: 'Drop the files when you\'re ready!',
    UPLOAD_VALID_TYPES: 'Valid types are: ',
    UPLOAD_BUTTON: 'All done!',

    TEST_STRING_FOR_DEBUG: 'english version!',
  },
  'test-only': {
    TEST_STRING_FOR_DEBUG: 'test version!',
  },
};

/**
 * Helper function to assist in legibility.
 * Determines if a given key is present in an object
 * @param key
 * @param object
 */
const containsKey = (key: string, object: StringType | { [key: string] : string }) => (
  Object.keys(object).includes(key)
);
/**
 * Given a list of user preferred languages return the first successful hit.
 * Otherwise, return english
 * @param languageArray
 */
const getAvailableLanguage = (languageArray?: string[]) => {
  const defaultLanguage = 'en';
  const arrayOfLanguages = languageArray || navigator.languages;
  const availableLanguages = arrayOfLanguages.filter((language) => containsKey(language, STRING_CONF));
  return availableLanguages.length > 0 ? availableLanguages[0] : defaultLanguage;
};

/**
 * Translates a given StringKey into the desired string in the desired
 * language if it is present. English is the default.
 * @param stringToTranslate The key to translate EX: LOGIN_TITLE
 * @param languageArray The language array to check for languages. Will take browser as default if not present
 */
export const translate = (stringToTranslate: string, languageArray?: string[]) => {
  // navigator.language is the current browser language.
  const language: string = getAvailableLanguage(languageArray);
  const languageCategory = STRING_CONF[language];
  const defaultCategory = STRING_CONF.en;
  // Supplied language from browser
  if (containsKey(stringToTranslate, languageCategory)) return languageCategory[stringToTranslate];
  // Default to the English version if the supplied langauge doesn't exist
  if (containsKey(stringToTranslate, defaultCategory)) return defaultCategory[stringToTranslate];
  // If the supplied version and the 'en' version don't exist, return a warning string
  return `String ${stringToTranslate} is not initialized inside of config`;
};

export const titleCase = (string: string) => string.charAt(0).toUpperCase() + (string.slice(1)).toLowerCase();
