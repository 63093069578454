import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { GenericTransactionDetail } from '../transactions-page-types';
import { formatNumberAsMoney } from '../transactions-page-helpers';
import { ValidatedCell } from '../datagrid-cells/ValidatedCell';

// These are the columns for the TransactionDetail datagrid
const columns: GridColDef[] = [
  {
    field: 'rate',
    headerName: 'Amount',
    flex: 0.25,
    align: 'right',
    renderCell: (
      params: GridRenderCellParams<GenericTransactionDetail>,
    ) => <ValidatedCell value={formatNumberAsMoney(params.row.rate)} />,
  },
  {
    field: 'property',
    headerName: 'Property',
    flex: 0.5,
    renderCell: (
      params: GridRenderCellParams<GenericTransactionDetail>,
    ) => <ValidatedCell value={params.row.property} />,
  },
  {
    // unit is not required so we don't use ValidatedCell
    field: 'unit',
    headerName: 'Property Unit',
    flex: 0.25,
    align: 'right',
  },
  {
    field: 'glAccount',
    headerName: 'GL Account',
    flex: 0.5,
    renderCell: (
      params: GridRenderCellParams<GenericTransactionDetail>,
    ) => <ValidatedCell value={params.row.glAccount} />,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    renderCell: (
      params: GridRenderCellParams<GenericTransactionDetail>,
    ) => <ValidatedCell value={params.row.description} />,
  },
];

/**
 * Generates a deterministic ID for a given transaction detail row.
 * @param row the transaction detail we are generating a row ID for
 */
const generateRowId = (row: GenericTransactionDetail) => {
  const keys = Object.keys(row) as (keyof GenericTransactionDetail)[];
  // sort in-place
  keys.sort();
  return keys.map((key) => row[key]).join('');
};

/**
 * A Datagrid displaying a transaction's details (line items).  Shows up when transaction is expanded in parent Datagrid.
 * @param details transaction details (line items)
 */
// eslint-disable-next-line import/prefer-default-export
export const TransactionDetail = function ({ details }:{ details: GenericTransactionDetail[] }) {
  return (
    <DataGridPro
      columns={columns}
      rows={details}
      sx={{ overflowX: 'scroll' }}
      // data should always be quite short so stringify is an acceptable solution
      getRowId={(row: GenericTransactionDetail) => generateRowId(row)}
    />
  );
};
