import React from 'react';
// @ts-ignore
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Pie } from '@nivo/pie';
import { AnalyticColors } from '../../../api/analyticis-api/AnalyticsAPI';
import { IDValue } from '../../../api/types/AnalyticsTypes';

const PieChart = function ({ data }: { data: IDValue[] }) {
  return (
    <AutoSizer>
      {/* eslint-disable-next-line react/no-unused-prop-types */}
      {({ height, width }: { height: number, width: number }) => (
        <Pie
          height={height}
          width={width}
          data={data}
          margin={{
            top: 75, right: 0, bottom: 180, left: 0,
          }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={AnalyticColors.pieChart}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.2,
              ],
            ],
          }}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                2,
              ],
            ],
          }}
          legends={[
            {
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: 40,
              translateY: 110,
              itemsSpacing: 0,
              itemWidth: 165,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
        />
      )}
    </AutoSizer>

  );
};

export default PieChart;
