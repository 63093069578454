/**
 * Generates modal style.
 * @param width width of modal in pixels
 */
// eslint-disable-next-line import/prefer-default-export
export const getModalStyle = (width: number) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
});
