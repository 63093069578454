import React from 'react';
import { ScoreCardType } from '../../../api/types/AnalyticsTypes';

const ScoreCard = function ({ image, description, value } : ScoreCardType) {
  return (
    <div className="bg-white flex rounded-md justify-center mt-2 p-8 shadow-lg">
      <img
        className="
        sm:w-16
        md:w-16
        lg:w-16
        xl:w-11
        2xl:w-16
        mr-12"
        src={image}
        width="64"
        alt="A robot slightly smiling"
      />
      <div>
        <div className="font-semibold mt-1 text-3xl">{value}</div>
        <div className="text-sm mt-2">{description}</div>
      </div>
    </div>
  );
};

export default ScoreCard;
