import React from 'react';
import { Data } from '../../api/types/JobStatusTypes';
import JobModal from '../job-modal/JobModal';
import JobRow from '../job-row/JobRow';

/**
 * TableBody is a wrapper that passes row data along to the
 * JobRow component, all the while creating a JobModal that will
 * allow for expanded information upon clicking the JobRow.
 * @param row The set of data to provide to a single line item.
 * @constructor
 */
const TableBody = function ({ row }: { row: Data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { jobId } = row;

  return (
    <>
      <JobRow row={row} handleOpen={handleOpen} />
      <JobModal
        jobId={jobId}
        open={open}
        handleClose={handleClose}
        row={row}
      />
    </>

  );
};

export default TableBody;
