import React, { MouseEvent, useEffect, useState } from 'react';
import {
  Autocomplete,
  Table, TableContainer, TablePagination, TextField,
} from '@mui/material';
import { CenteredCircularProgress, Container, DatePicker } from '../helper';
import { getCollectionDocs, watchCollection } from '../api/document-api/DocumentAPI';
import { useAdmin, useUser } from '../api/user-api/UserAPI';
import TableHeader from './table-header/TableHeader';
import { Data, Order } from '../api/types/JobStatusTypes';
import TableBody from './table-body/TableBody';
import { firebaseDateConvert } from '../api/time-api/TimeAPI';
import { getComparator } from '../api/status-api/StatusAPI';
import { QueryType } from '../api/types/FirebaseTypes';
import JobStatusFilter from './job-status-filter/JobFilter';
import { JobTypeFilter } from './job-type-filter/JobTypeFilter';

/**
 * Job Status is designed to provide a visual display for all
 * registered jobs and their last known status.
 * @constructor
 */
const JobStatus = function () {
  const user = useUser();
  const isAdmin = useAdmin();

  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState<Data[]>();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('timestamp');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusName, setStatusName] = React.useState<string[]>([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);
  const [selectedUserFilter, setSelectedUserFilter] = useState<string>('');
  const [userOptions, setUserOptions] = useState<string[]>([]);

  const handleStartDateChange = (newDate : Date | null) => setStartDate(newDate);
  const handleEndDateChange = (newDate : Date | null) => setEndDate(newDate);
  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const updateSelectedJobTypes = (jobType: string[]) => setSelectedJobTypes(jobType);

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data | 'blank') => {
    if (property === 'blank') return () => {};
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    return setOrderBy(property);
  };

  useEffect(() => {
    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 59);
    }
  }, []);

  useEffect(() => {
    if (!user?.uid) return () => {};
    const queries: QueryType[] = [
      { fieldPath: 'timestamp', opString: '>=', value: firebaseDateConvert(startDate) },
      { fieldPath: 'timestamp', opString: '<=', value: firebaseDateConvert(endDate) },
    ];
    // Only let the user see jobs they own if they're not an admin
    if (!isAdmin) queries.push({ fieldPath: 'uid', opString: '==', value: `${user.uid}` });
    if (statusName.length > 0) queries.push({ fieldPath: 'status', opString: 'in', value: statusName });
    if (selectedJobTypes.length) queries.push({ fieldPath: 'jobType', opString: 'in', value: selectedJobTypes });
    if (selectedUserFilter && isAdmin) {
      queries.push({ fieldPath: 'displayName', opString: '==', value: selectedUserFilter });
    }

    const unsubscribe = watchCollection(
      setIsLoading,
      setJobs,
      'jobStatus',
      queries,
      { field: 'timestamp', direction: 'desc' },
    );

    return () => unsubscribe();
  }, [startDate, endDate, statusName, user, selectedJobTypes, selectedUserFilter]);

  // gets users for job filtering purposes
  useEffect(() => {
    getCollectionDocs('users')
      .then((docs) => {
        const users = docs.map((doc) => doc.displayName);
        setUserOptions(users);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Container>
      {isLoading && <CenteredCircularProgress />}
      {!isLoading && jobs && (
        <div>
          <TableContainer>
            <Table>
              <TableHeader onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
              {jobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .sort(getComparator(order, orderBy))
                .map((job) => (
                  <TableBody key={job.jobId} row={job} />
                ))}
            </Table>
          </TableContainer>
          <div className="mt-4 flex flex-row-reverse items-center">
            <TablePagination
              count={jobs.length}
              component="div"
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DatePicker
              date={endDate}
              handleChange={handleEndDateChange}
              label="End Date"
              additionalClass="w-44"
            />
            <DatePicker
              date={startDate}
              handleChange={handleStartDateChange}
              label="Start Date"
              additionalClass="w-44"
            />
            <JobTypeFilter selectedJobTypes={selectedJobTypes} updateSelectedJobTypes={updateSelectedJobTypes} />
            <JobStatusFilter statusName={statusName} setStatusName={setStatusName} />
            <Autocomplete
              value={selectedUserFilter}
              onChange={(e, val) => setSelectedUserFilter(val || '')}
              className="w-56"
              options={userOptions}
              renderInput={(params) => <TextField {...params} label="User" />}
            />
            {/* This button basically re-runs all Docsumo jobs in the current view.  Leaving it commented as it's possible that this will */}
            {/* be requested in the future, though it should be noted that this would have to be tweaked to filter out Divvy/Ramp jobs. */}
            {/* <ReuploadToDocsumo jobIds={jobs.map((job) => job.jobId)} /> */}
          </div>
        </div>
      )}
    </Container>
  );
};

export default JobStatus;
