import React from 'react';
import ScoreCard from '../../visualizations/score-card/ScoreCard';
import invoiceUpload from '../../../../assets/analytics/invoiceUpload.svg';
import autoAssociation from '../../../../assets/analytics/autoAssociation.svg';
import manualAssoc from '../../../../assets/analytics/manualAssoc.svg';
import outstandingAssoc from '../../../../assets/analytics/outstandingAssoc.svg';
import { JobStatus } from '../../../api/types/JobStatusTypes';

const ScoreCardGrouping = function ({ jobStatus }: { jobStatus: JobStatus[] }) {
  const scoreCards = [
    {
      image: invoiceUpload,
      description: 'Total Invoices Uploaded',
      value: jobStatus.length,
    },
    {
      image: outstandingAssoc,
      description: 'Outstanding Associations',
      value: jobStatus.filter((job) => job.status === 'AWAITING ASSOCIATION').length,
    },
    {
      image: autoAssociation,
      description: 'Automatic Associations',
      value: jobStatus.filter((job) => job.requiredAssociation === false).length,
    },
    {
      image: manualAssoc,
      description: 'Manual Associations',
      value: jobStatus.filter((job) => job.requiredAssociation === true).length,
    },
  ];

  return (
    <div className="
    sm:flex-col sm:basis-3/4
    xl:flex-row
    flex justify-between"
    >
      {scoreCards.map((scoreCard) => (
        <ScoreCard image={scoreCard.image} description={scoreCard.description} value={scoreCard.value} />
      ))}
    </div>

  );
};

export default ScoreCardGrouping;
