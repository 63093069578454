import { Box, FormControl, Modal } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { httpsCallable } from 'firebase/functions';
import { enqueueSnackbar } from 'notistack';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { watchCollection } from '../../../../api/document-api/DocumentAPI';
import { functions } from '../../../../../firebase-config';
import { EditPositionCodeMappingsModalParams } from '../gl-category-params';
import { getModalStyle } from './modal-helpers';
import {
  GlAccount, GlCategoryType, PositionCodeMapping, PositionGLRow,
} from '../gl-category-types';
import { AssignGlCodeCell } from './cells/AssignGlCodeCell';

// modal style object
const style = getModalStyle(800);

/**
 * Generates columns for position code mappings DataGrid.
 * @param glOptions all GL code options
 * @param positionCodeMappings existing position code mappings
 * @param selectedGlCategory the current GL category
 */
const getColumns = (
  glOptions: string[],
  positionCodeMappings: PositionCodeMapping[],
  selectedGlCategory: string,
): GridColDef[] => [
  {
    field: 'PositionCode',
    headerName: 'Position Code',
    width: 100,
    valueGetter: (value, row: PositionGLRow) => row.positionCode.PositionCode,
  },
  {
    field: 'PositionName',
    headerName: 'Position Name',
    flex: 1,
    valueGetter: (value, row: PositionGLRow) => row.positionCode.PositionName,
  },
  {
    field: 'divisionCode',
    headerName: 'Division Code',
    flex: 1,
    valueGetter: (value, row: PositionGLRow) => row.divisionCode?.DivisionCode || '',
  },
  {
    field: 'setGlCode',
    headerName: 'GL Code',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<PositionGLRow>) => (
      <AssignGlCodeCell
        row={row}
        positionCodeMappings={positionCodeMappings}
        selectedGlCategory={selectedGlCategory}
        glOptions={glOptions}
      />
    ),
  },
];

/**
 * Retrieves required position code/division codes and GL codes options.
 */
const loadData = async () => {
  // get position codes
  const posCodes = await httpsCallable(functions, 'getPositionCodes')();
  const positions = posCodes.data as PositionGLRow[];
  const glsRes = await httpsCallable(functions, 'getGls')();
  const gls = glsRes.data as GlAccount[];
  const glCodes = gls.map((gl) => gl.AccountNumber);
  return { positions, glCodes };
};

/**
 * Modal which allows user to map GL codes to position/division codes for a given GL category.
 * @param setEditPositionCodes setter for boolean value which controls whether modal is open
 * @param glCategories gl categories
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const EditPositionCodeMappingsModal = function (
  { setEditPositionCodes, glCategories }: EditPositionCodeMappingsModalParams,
) {
  // mappings of gl codes to position/division codes and gl category
  const [positionCodeMappings, setPositionCodeMappings] = useState<PositionCodeMapping[]>([]);
  const [loadingPositionCodeMappings, setLoadingPositionCodeMappings] = useState<boolean>(true);
  // all gl code options
  const [glOptions, setGlOptions] = useState<string[]>([]);
  // only "per department" categories should be used in mapping position codes
  const perDeptCategories = glCategories.filter(({ rules }) => rules.glType === GlCategoryType.PER_DEPARTMENT);
  // the currently selected gl category under which we are editing position code mappings
  const [selectedGlCategory, setSelectedGlCategory] = useState<string>(perDeptCategories[0]?.pdocId || '');
  // all required position code/division code combinations
  const [positionCodes, setPositionCodes] = useState<PositionGLRow[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);

  // get gl codes and position codes
  useEffect(() => {
    setLoadingData(true);
    loadData()
      .then((data) => {
        const { positions, glCodes } = data;
        setGlOptions(glCodes);
        setPositionCodes(positions);
      })
      .catch(() => { enqueueSnackbar('Failed to load data', { variant: 'error' }); })
      .finally(() => setLoadingData(false));
  }, []);

  // watch position code mappings collection
  useEffect(() => {
    const unsubscribe = watchCollection(
      setLoadingPositionCodeMappings,
      setPositionCodeMappings,
      'payroll/positionCodes/mappings',
    );
    return () => unsubscribe();
  }, []);

  const loadingDatagrid = loadingPositionCodeMappings || loadingData;

  const columns = useMemo(
    () => getColumns(glOptions, positionCodeMappings, selectedGlCategory),
    [glOptions, positionCodeMappings, selectedGlCategory],
  );

  const getRowId = (row: PositionGLRow) => `${row.positionCode.PositionCode}-${row.divisionCode?.DivisionCode || ''}`;

  return (
    <Modal
      open
      onClose={() => setEditPositionCodes(false)}
    >
      <Box sx={style}>
        {/* Modal header */}
        <div style={{ marginBottom: '20px' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
          >
            <div style={{ fontSize: '20px' }}>Edit Position Code Mappings</div>
            {/* GL Category selector */}
            <FormControl>
              <InputLabel>GL Category</InputLabel>
              <Select
                value={selectedGlCategory}
                label="GL Category"
                style={{ width: '300px' }}
                onChange={(e) => setSelectedGlCategory(e.target.value)}
              >
                {perDeptCategories.map(
                  ({ pdocId, displayName }) => <MenuItem value={pdocId}>{displayName}</MenuItem>,
                )}
              </Select>
            </FormControl>
          </div>
        </div>
        {/* Position code mappings Datagrid */}
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            loading={loadingDatagrid}
            rows={selectedGlCategory ? positionCodes : []}
            rowHeight={80}
            columns={columns}
            getRowId={getRowId}
          />
        </Box>
      </Box>
    </Modal>
  );
};
