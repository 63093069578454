import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../firebase-config';
import { getCheckDateDoc } from './payroll-periods-helper';
import { UploadInvoiceButtonParams } from './payroll-periods-params';

/**
 * Manually triggers invoice upload for given company ID and check date
 * @param selectedCompanyId selected FEIN
 * @param selectedCheckDate selected check date
 */
const triggerInvoiceUpload = async (selectedCompanyId: string, selectedCheckDate: string) => {
  const uploadPayroll = httpsCallable(functions, 'generatePayrollInvoice');
  const config = await getCheckDateDoc(selectedCompanyId, selectedCheckDate);
  if (!config) {
    throw new Error(`Failed to get check date data for company ${selectedCompanyId} and date ${selectedCheckDate}`);
  }
  await uploadPayroll(config);
};

/**
 * Button which manually triggers invoice upload for given FEIN and check date.
 * @param selectedCompanyId FEIN
 * @param selectedCheckDate check date
 * @param disableManualUpload whether to disable upload button
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const UploadInvoiceButton = function ({
  selectedCompanyId, selectedCheckDate, disableManualUpload,
}: UploadInvoiceButtonParams) {
  const [loadingUploadInvoice, setLoadingUploadInvoice] = useState<boolean>(false);

  /**
     * Manually triggers invoice upload for selected FEIN and check date.
     */
  const uploadPayrollInvoice = () => {
    if (!selectedCompanyId || !selectedCheckDate) return;
    setLoadingUploadInvoice(true);
    triggerInvoiceUpload(selectedCompanyId, selectedCheckDate)
      .then(() => { enqueueSnackbar('Invoice upload attempted - see logs for status', { variant: 'success' }); })
      .catch(() => { enqueueSnackbar('Failed to upload invoice', { variant: 'error' }); })
      .finally(() => setLoadingUploadInvoice(false));
  };

  return (
    <LoadingButton
      onClick={uploadPayrollInvoice}
      variant="contained"
      loading={loadingUploadInvoice}
      disabled={disableManualUpload}
      className="bg-green-500 disabled:bg-green-300"
    >
      Upload invoice
    </LoadingButton>
  );
};
