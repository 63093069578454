import React from 'react';
import { Predictions } from '../../api/types/AssociationTypes';

// eslint-disable-next-line import/prefer-default-export
export function AssociationPrediction({ prediction } : { prediction: Predictions }) {
  return (
    <div className="text-sm ml-12 mt-1">
      Predictive information:
      {' '}
      {prediction.label}
      {' '}
      with a confidence of
      {' '}
      {Math.round(prediction.confidence * 100)}
      %
    </div>
  );
}
