import {
  AnalyticsAutoAssociation,
  FilterProperties, JobCount,
  Operator,
  OperatorFunction,
  Stats,
  UserStat,
  ValidStatFilters,
} from 'components/api/types/AnalyticsTypes';
import { JobStatus, JobStatusType } from 'components/api/types/JobStatusTypes';

export const AnalyticColors = {
  lineChart: [
    '#064B67',
    '#2D92BC',
  ],
  sankey: [
    '#024C73',
    '#0B806E',
    '#4F8FEE',
    '#0B2580',
    '#2D66BC',
    '#36D388',
    '#82D2F3',
    '#4A28AA',
    '#13678A',
    '#004A3D',
    '#34B5BD',
    '#6645C4',
    '#00121b',
    '#0B806B',
    '#3DA0D8',
    '#1B0066',
    '#2D92BC',
    '#57F0D8',
    '#63BBDF',
    '#4D4D7B',
    '#448EAC',
    '#45C4B0',
    '#A5C9FF',
    '#BAAFE3',
    '#4644C1',
    '#0B8033',
    '#DAFDBA',
    '#6D72A1',
    '#9AEBA3',
    '#9896F4',
  ],
  barChart: [
    '#13678A',
    '#01344F',
  ],
  pieChart: [
    '#45C4B0',
    '#9AEBA3',
    '#13678A',
    '#01344F',
    '#28AA96',
    '#DAFDBA',
  ],
};

const AutoJobState = {
  REQUIRED_ASSOCIATION: 'Required Association',
  AUTO_ASSOCIATED: 'Auto Associated',
};

/**
 * Takes an array of document information provided by the analytics information
 * within firebase and generates a user usable output for display purpose.s
 * @param stats The firebase document array.
 */
export const generateStat = (stats: Stats) => (
  Object.entries(stats).map(([documentCategory, statValue]) => (
    Object.values(statValue).map((dataContent) => ({ documentCategory, ...dataContent }))
  )).flat(1)
);

/**
 * Merge stats takes the provided output from generateStats
 * and sorts them on a valid property name. Allowing for a summation on a
 * singular point
 * @param userStats The list of arrayed analytical data
 * @param propertyMergeName The property name upon which to orient the data
 */
export const mergeOnField = (userStats: UserStat[], propertyMergeName: ValidStatFilters) => {
  const userStatsMerged : { [key: string] : UserStat } = {};
  userStats.forEach((user) => {
    const key = user[propertyMergeName];
    const inProgress = user.inProgress || 0;
    const completedJobs = user.completedJobs || 0;

    // Create the entry into userStatsMerged if it doesn't yet exist
    if (!(key in userStatsMerged)) {
      userStatsMerged[key] = {
        documentCategory: user.documentCategory,
        name: user.name,
        completedJobs: 0,
        inProgress: 0,
      };
    }

    // Add the jobs to the running total
    userStatsMerged[key].inProgress += inProgress;
    userStatsMerged[key].completedJobs += completedJobs;
  });

  // Return the expected format as a sorted array
  return Object.values(userStatsMerged).sort((a, b) => b[propertyMergeName].localeCompare(a[propertyMergeName]));
};

export const getJobBreakdown = (jobStatus: JobStatus[], filterJob?: JobStatusType[]) => (
  jobStatus.reduce((
    jobCount: JobCount,
    job: JobStatus,
  ) => {
    if (filterJob && !filterJob.includes(job.status)) return jobCount;
    const updatedObject = { ...jobCount };
    const updatedAmount = updatedObject[job.status]?.value
      ? updatedObject[job.status].value + 1
      : 1;

    updatedObject[job.status] = {
      id: job.status,
      value: updatedAmount,
    };

    return updatedObject;
  }, {}));

export const getAutoAssociationAnalytic = (jobStatus: JobStatus[]) => (
  jobStatus.reduce((jobCount: AnalyticsAutoAssociation, job: JobStatus) => {
    const updatedObject: AnalyticsAutoAssociation = { ...jobCount };
    if (job.requiredAssociation) {
      updatedObject.requiredAssociation.value += 1;
    } else if (job.requiredAssociation === false) {
      // We want strict comparison this ^
      updatedObject.autoProcessed.value += 1;
    }
    return updatedObject;
  }, {
    autoProcessed: { value: 0, id: 'Auto Processed' },
    requiredAssociation: { value: 0, id: 'Required Association' },
  }));

const operators : Operator = {
  LOGICAL: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    AND: (x: any, y: any) => x && y,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    OR: (x: any, y: any) => x || y,
    EQ: (x: number, y: number) => x === y,
    NOT_EQ: (x: number, y: number) => x !== y,
    GT: (x: number, y: number) => x > y,
    LT: (x: number, y: number) => x < y,

  },
};

const operatorHelper = (itemOne: any, itemTwo: any, operator: OperatorFunction) => (
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  operator(itemOne, itemTwo)
);

const reduceHelper = (jobStatus: JobStatus[], filtersProperties: FilterProperties[]) => {
  const adjustedFiltersProperties = filtersProperties
    .filter((filter) => filter.property !== 'uploaded' as any)
    .map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.value === 'Required Association') updatedItem.value = true;
      if (updatedItem.value === 'Auto Associated') updatedItem.value = false;
      return updatedItem;
    });

  return jobStatus.reduce((prev, curr) => {
    const areFiltersValid = adjustedFiltersProperties.every((filter) => (
      operatorHelper(curr[filter.property], filter.value, filter.operator)
    ));
    return areFiltersValid
      ? prev + 1
      : prev;
  }, 0);
};

const createDataPoints = (jobStatus: JobStatus[]) => {
  const mapPoints : { [key: string] : any[] } = {
    uploaded: ['uploaded'],
    origin: jobStatus.map((job) => job.origin).filter((v, i, a) => a.indexOf(v) === i),
    trackingName: jobStatus.map((job) => job.trackingName || 'Pending').filter((v, i, a) => a.indexOf(v) === i),
    requiredAssociation: [AutoJobState.REQUIRED_ASSOCIATION, AutoJobState.AUTO_ASSOCIATED],
    status: jobStatus.map((job) => job.status).filter((v, i, a) => a.indexOf(v) === i),
  };

  const mapFlow: { previous: keyof JobStatus, target: keyof JobStatus }[] = [
    {
      // This one is superficially added
      previous: 'uploaded' as keyof JobStatus,
      target: 'origin',
    },
    {
      previous: 'origin',
      target: 'requiredAssociation',
    },
    {
      previous: 'requiredAssociation',
      target: 'trackingName',
    },
    {
      previous: 'trackingName',
      target: 'status',
    },
  ];

  const links = mapFlow.map((flow) => {
    const previousOptions = mapPoints[flow.previous];
    const currentOptions = mapPoints[flow.target];

    return currentOptions.map((targetOption: string) => previousOptions.map((previousOption: string) => {
      const filterProperties : FilterProperties[] = [
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { property: flow.previous, value: previousOption, operator: operators.LOGICAL.EQ },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { property: flow.target, value: targetOption, operator: operators.LOGICAL.EQ },
      ];

      const flowCount = reduceHelper(jobStatus, filterProperties);
      return {
        source: previousOption,
        target: targetOption,
        value: flowCount,
      };
    }));
  })
    .flat(3)
    // Remove values that are 0
    .filter((link) => link.value !== 0);

  const nodeArray = links.reduce((runningNodes, point) => {
    runningNodes.push(point.source);
    runningNodes.push(point.target);
    return runningNodes;
  }, [] as string[])
    // Remove duplicate nodes
    .filter((v, i, a) => a.indexOf(v) === i);

  // Map the node to the required Sankey schema
  const nodes = nodeArray.map((node) => ({
    id: node,
  }));

  return {
    nodes,
    links,
  };
};

export const getSankeyAnalytic = (jobStatus: JobStatus[]) => createDataPoints(jobStatus);

export const generatePercentageOf = (jobStatus: JobStatus[]) => {
  const data: { xAxis: string, required: number, auto: number }[] = Object.values(
    jobStatus.reduce((aggregate, job) => {
      const updatedAggregate: { [key: string]: { auto: number, required: number, xAxis: string } } = { ...aggregate };
      const timestamp = job.timestamp.toDate().toDateString();
      if (!(timestamp in updatedAggregate)) {
        updatedAggregate[timestamp] = {
          xAxis: timestamp,
          required: 0,
          auto: 0,
        };
      }
      if (job.requiredAssociation) {
        updatedAggregate[timestamp].required += 1;
      }
      // We want to be explicit
      if (job.requiredAssociation === false) {
        updatedAggregate[timestamp].auto += 1;
      }

      console.log(updatedAggregate);
      return updatedAggregate;
    }, {}),
  );

  const requiredData = data.map((plot) => ({ x: plot.xAxis, y: plot.required }));
  const autoData = data.map((plot) => ({ x: plot.xAxis, y: plot.auto }));

  return [
    { id: AutoJobState.AUTO_ASSOCIATED, data: autoData },
    { id: AutoJobState.REQUIRED_ASSOCIATION, data: requiredData },
  ];
};
