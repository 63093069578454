import { withStyles } from '@mui/styles';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { Check, Error } from '@mui/icons-material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Data, JobStatusType } from '../../api/types/JobStatusTypes';
import AssociationPdfButton from '../../association-page/association-pdf-button/AssociationPdfButton';

// Styling for hovering over a row.
const HoveredRow = withStyles(() => ({
  root: {
    '&$hover:hover': {
      backgroundColor: '#ededed',
      transition: 'all .13s ease-in-out;',
    },
  },
  selected: {},
  hover: {
    transition: 'all .2s ease-in-out;',
  },
}))(TableRow);

/**
 * Returns relevant icon given job status
 * @param status job status
 */
export const getJobStatusIcon = (status: JobStatusType) => {
  switch (status) {
    case 'ERROR': return (<Error className="text-red-500" fontSize="small" />);
    case 'UPLOAD-DUPLICATE': return (<Check className="text-yellow-400" fontSize="small" />);
    case 'UPLOAD-ERROR': return (<Error className="text-red-500" fontSize="small" />);
    case 'THRESHOLD-EXCEEDED': return (<Error className="text-red-500" fontSize="small" />);
    case 'UPLOAD-SUCCESS': return (<Check className="text-green-500" fontSize="small" />);
    case 'UNKNOWN': return (<Error className="text-yellow-400" fontSize="small" />);
    case 'PROCESSING ASSOCIATION': return (<HourglassBottomIcon className="text-orange-400" fontSize="small" />);
    case 'AWAITING ASSOCIATION': return (<HourglassTopIcon className="text-orange-400" fontSize="small" />);
    case 'QUEUED': return (<ScheduleIcon className="text-orange-400" fontSize="small" />);
    default: return (<div />);
  }
};

/**
 * JobRow displays the information for a given set of data. Takes the open handler
 * from the prior TableBody component for use in opening up the informational breakdown.
 * @param row The set of data to provide to a single line item.
 * @param handleOpen The TableBody shared open state. Used for handling the modal.
 * @constructor
 */
const JobRow = function ({ row, handleOpen }: { row: Data, handleOpen: () => void; }) {
  const {
    displayName, jobId, jobType, status, timestamp,
  } = row;

  return (
    <HoveredRow
      hover
      tabIndex={-1}
      key={jobId}
    >
      <TableCell
        onClick={handleOpen}
        component="th"
        scope="row"
        padding="none"
      >
        {jobId}
      </TableCell>
      <TableCell onClick={handleOpen}>
        {displayName}
      </TableCell>
      <TableCell onClick={handleOpen}>
        {jobType }
      </TableCell>
      <TableCell onClick={handleOpen}>
        <div className="flex items-center">
          {status}
          <div className="ml-3">{getJobStatusIcon(status)}</div>
        </div>
      </TableCell>
      <TableCell onClick={handleOpen}>
        {timestamp.toDate().toString()}
      </TableCell>
      <TableCell>
        <AssociationPdfButton jobId={row.jobId} />
      </TableCell>
    </HoveredRow>
  );
};

export default JobRow;
