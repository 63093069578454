import React from 'react';
import { motion } from 'framer-motion';
import { Button, Paper } from '@mui/material';
import { Container, TitleSubtitle } from '../helper';
import { staggeredEntryContainer, staggeredEntryItem } from '../api/framer-motion-api/FramerMotionAPI';
import navigateTo from '../api/navigation-api/NavigationAPI';

/**
 * The administrative page is the location where admin/superadmin users can navigate to modify information
 * such as previously assigned associations, user roles, and who owns a job
 *
 * This page is protected by firebase security rules. Even if a user navigates to this page, the information will
 * not display as they will not have the requisite permissions to view the contents.
 * @constructor
 */
const AdminPage = function () {
  const adminOptions = [
    { title: 'Adjust associations', location: 'adjustAssociations', isDisabled: false },
    { title: 'Adjust user', location: 'adjustUser', isDisabled: false },
    { title: 'Swap Jobs', location: 'swapJobs', isDisabled: false },
  ];

  return (
    <Container>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={staggeredEntryContainer}
        className="font-montserrat"
      >
        <TitleSubtitle title="ADMIN_TITLE" subtitle="ADMIN_SUBTITLE" />
        <ul>
          {adminOptions?.map((option) => (
            <motion.li className="list-none" key={option.title} variants={staggeredEntryItem}>
              <Paper className="flex justify-between items-center mt-4 p-4 w-2/5" elevation={3}>
                <div>{option.title}</div>
                <Button
                  disabled={option.isDisabled}
                  className="westland-red"
                  onClick={() => navigateTo(`admin/${option.location}`)}
                >
                  Click here!
                </Button>
              </Paper>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </Container>
  );
};

export default AdminPage;
