import React, { useState } from 'react';
import { Container, SnackbarAlert } from '../helper';
import { snackbarMessage, snackbarSeverity } from '../api/notification-api/NotificationAPI';
import AssociationPage from '../association-page/AssociationPage';

/**
 * Jobs is responsible for displaying the pending jobs that have
 * been created as a result of uploading a document to docsumo through the upload page.
 * Security rules on jobListing is public:read to anyone. This allows us to filter and view
 * jobs where uid = document.uid. Viewing an individual job though, is restricted
 * @constructor
 */
const Jobs = function () {
  const [snackbarOpen, setSnackbarOpen] = useState(!!snackbarMessage());

  return (
    <Container>
      <SnackbarAlert
        message={snackbarMessage()}
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        status={snackbarSeverity()}
      />
      <AssociationPage />
    </Container>
  );
};

export default Jobs;
