import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete, FormControlLabel, Switch, TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { enqueueSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import {
  CategoryRules, CategoryRuleSelect, CategoryRuleToggle, GlAccount, GlCategoryType,
} from '../gl-category-types';
import { functions } from '../../../../../firebase-config';
import { GlCategoryRulesParams } from '../gl-category-params';

/**
 * Generates objects which will be rendered as toggles (aka switches) and GL code selectors which will control category rules.
 * @param rules the rules of the current GL category
 */
const getSelectors = (rules: CategoryRules) => {
  // these will render as toggles aka switches
  const toggles: CategoryRuleToggle[] = [
    { value: rules.calculatedInBillBack, field: 'calculatedInBillBack', label: 'Calculated in bill back' },
    { value: rules.reconcilesToCash, field: 'reconcilesToCash', label: 'Reconciles to cash' },
    { value: rules.passthrough, field: 'passthrough', label: 'Passthrough category' },
    { value: rules.erContributions, field: 'erContributions', label: 'ER Contributions' },
    { value: rules.offsetFromCash, field: 'offsetFromCash', label: 'Offset from cash' },
  ];
  // this will render as gl code selectors
  const glSelectors: CategoryRuleSelect[] = [
    { value: rules.glCode, field: 'glCode', label: 'GL Code' },
    { value: rules.offsetGl, field: 'offsetGl', label: 'Offset GL' },
    { value: rules.reimbursementGl, field: 'reimbursementGl', label: 'Reimbursement GL' },
  ];
  return { toggles, glSelectors };
};

/**
 * Rules editor for a given GL Category.
 * @param rules rules of current GL category
 * @param setRules setter for rules of current GL category
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const GlCategoryRules = function ({ rules, setRules }: GlCategoryRulesParams) {
  // gl code options
  const [glOptions, setGlOptions] = useState<string[]>([]);
  const [loadingGls, setLoadingGls] = useState<boolean>(true);

  // get gl code options
  useEffect(() => {
    setLoadingGls(true);
    const getGlCodes = httpsCallable(functions, 'getGls');
    getGlCodes()
      .then((result) => {
        const gls = result.data as GlAccount[];
        const glCodes = gls.map((gl) => gl.AccountNumber);
        setGlOptions(glCodes);
      })
      .catch(() => { enqueueSnackbar('Failed to get GL options', { variant: 'error' }); })
      .finally(() => setLoadingGls(false));
  }, []);

  // Generate toggle and selector data
  const { toggles, glSelectors } = useMemo(() => getSelectors(rules), [rules]);

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', rowGap: '10px',
    }}
    >
      {/* Boolean rules toggles */}
      {toggles.map((toggle) => (
        <FormControlLabel
          control={(
            <Switch
              checked={toggle.value}
              onChange={(e) => {
                const rulesCopy: CategoryRules = { ...rules };
                rulesCopy[toggle.field] = e.target.checked;
                setRules(rulesCopy);
              }}
            />
            )}
          label={toggle.label}
          labelPlacement="end"
        />
      ))}
      {/* GL Type selector */}
      <FormControl fullWidth>
        <InputLabel>GL Type</InputLabel>
        <Select
          value={rules.glType}
          label="GL Type"
          variant="standard"
          onChange={(e) => {
            const rulesCopy: CategoryRules = { ...rules, glType: e.target.value as GlCategoryType };
            setRules(rulesCopy);
          }}
        >
          {Object.values(GlCategoryType).map((value) => <MenuItem value={value}>{value}</MenuItem>)}
        </Select>
      </FormControl>
      {/* GL Code selectors */}
      {glSelectors.map((selector) => (
        <Autocomplete
          options={glOptions}
          loading={loadingGls}
          value={selector.value}
          loadingText="Loading..."
          renderInput={(params) => <TextField {...params} label={selector.label} />}
          onChange={(e, glCode) => {
            const rulesCopy: CategoryRules = { ...rules };
            rulesCopy[selector.field] = glCode || '';
            setRules(rulesCopy);
          }}
        />
      ))}
    </div>
  );
};
