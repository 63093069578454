import React, { useEffect, useMemo, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import { AlertTitle } from '@mui/lab';
import { functions } from '../../../../firebase-config';
import { watchCollection } from '../../../api/document-api/DocumentAPI';
import { CheckDateDoc, PayrollCompany } from './payroll-period-types';
import { UploadInvoiceButton } from './UploadInvoiceButton';
import { UploadLogsDataGrid } from './UploadLogsDataGrid';
import { PeriodConfig } from './PeriodConfig';

/**
 * Gets the upcoming check date (including if it is today).
 * @param checkDates all check dates
 */
const getNextCheckDate = (checkDates: string[]) => {
  const today = dayjs().format('MM-DD-YYYY');
  const todayIsCheckDate = checkDates.includes(today);
  // check dates are returned in descending order, next check date will always be first date in list EXCEPT if today is a check date
  return todayIsCheckDate ? today : checkDates[0];
};

/**
 * Gets text describing missing config docs for upcoming check date.
 * @param companies all companies
 * @param nextCheckDate upcoming check date
 * @param checkDateDocs all check date docs
 */
const getMissingConfigsText = (companies: PayrollCompany[], nextCheckDate: string, checkDateDocs: CheckDateDoc[]) => {
  const configuredCompanies = checkDateDocs
    .filter(({ checkDate }) => checkDate === nextCheckDate)
    .map(({ fein }) => fein);
  const missingCompanies = companies.filter(({ clientId }) => !configuredCompanies.includes(clientId));
  return missingCompanies.map(({ clientName }) => clientName).join(', ');
};

/**
 * Gets all check dates and all companies.  These are used when browsing/selecting configurations.
 */
const getPeriodSelectionOptions = async () => {
  const datesRes = await httpsCallable(functions, 'getCheckDates')();
  const datesData = datesRes.data as string[];
  const companiesRes = await httpsCallable(functions, 'getCompanies')();
  const companiesData = companiesRes.data as PayrollCompany[];
  return { datesData, companiesData };
};

/**
 * Component that displays payroll periods information and allows user to configure values for these periods (values
 * which are later used to generate payroll invoices).
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const PayrollPeriods = function () {
  // check date (represents a given payroll period)
  const [selectedCheckDate, setSelectedCheckDate] = useState<string>('');
  // fein/id of the company whose payroll we are looking at for the given period
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  // config document for selected check date/fein
  const [selectedCheckDateDoc, setSelectedCheckDateDoc] = useState<CheckDateDoc | null>(null);
  // all config documents for all check dates/feins
  const [checkDateDocs, setCheckDateDocs] = useState<CheckDateDoc[]>([]);
  const [checkDates, setCheckDates] = useState<string[]>([]);
  // Westland companies that run payroll
  const [companies, setCompanies] = useState<PayrollCompany[]>([]);
  const [loadingPeriodSelectionOptions, setLoadingPeriodSelectionOptions] = useState<boolean>(true);
  // whether the current config has already successfully uploaded an invoice in the past
  const [alreadyUploaded, setAlreadyUploaded] = useState<boolean>(true);
  // whether to disable the ability to manually upload invoice
  const [disableManualUpload, setDisableManualUpload] = useState<boolean>(false);

  const updateAlreadyUploaded = (uploaded: boolean) => setAlreadyUploaded(uploaded);
  const updateDisableManualUpload = (disable: boolean) => setDisableManualUpload(disable);

  // the upcoming check date
  const nextCheckDate = useMemo(() => getNextCheckDate(checkDates), [checkDates]);
  // text describing if there are any configurations missing for any companies for the upcoming check date
  const missingConfigsText = useMemo(
    () => getMissingConfigsText(companies, nextCheckDate, checkDateDocs),
    [companies, nextCheckDate, checkDateDocs],
  );

  // retrieves all check dates (payroll periods) and all companies
  useEffect(() => {
    setLoadingPeriodSelectionOptions(true);
    getPeriodSelectionOptions()
      .then(({ datesData, companiesData }) => {
        setCheckDates(datesData);
        setCompanies(companiesData);
      })
      .catch(() => { enqueueSnackbar('Failed to load period selection options', { variant: 'error' }); })
      .finally(() => setLoadingPeriodSelectionOptions(false));
  }, []);

  // watch check date config docs collection
  useEffect(() => {
    const unsubscribe = watchCollection(() => {}, setCheckDateDocs, 'payroll/checkDate/configuration');
    return () => unsubscribe();
  }, []);

  // when check date or FEIN changes (or if logs update), get the relevant check date configuration doc (if it exists)
  useEffect(() => {
    if (!selectedCheckDate || !selectedCompanyId) return;
    // get check date doc for selected check date and FEIN (if it exists)
    const relevantCheckDateDoc = checkDateDocs.find(
      ({ checkDate, fein }) => checkDate === selectedCheckDate && fein === selectedCompanyId,
    );
    setSelectedCheckDateDoc(relevantCheckDateDoc || null);
  }, [selectedCheckDate, selectedCompanyId, checkDateDocs]);

  return (
    <div>
      {/* Description */}
      <div className="mt-4 text-lg">Configure manual checks and cash amount for a given payroll run.</div>
      <div className="flex flex-row mt-4 gap-x-10">
        {/* Select FEIN/Company */}
        <FormControl className="w-96">
          <InputLabel>FEIN</InputLabel>
          <Select
            value={selectedCompanyId}
            label="FEIN"
            onChange={(e) => { setSelectedCompanyId(e.target.value); }}
          >
            {companies.map(({ clientId, clientName, propertyCode }) => (
              <MenuItem value={clientId}>{`${clientName} (${clientId}) (${propertyCode})`}</MenuItem>
            ))}
            {loadingPeriodSelectionOptions && !companies.length && (<MenuItem>Loading...</MenuItem>)}
          </Select>
        </FormControl>
        {/* Select check date */}
        <FormControl className="w-96">
          <InputLabel>Check Date</InputLabel>
          <Select
            value={selectedCheckDate}
            label="Check Date"
            onChange={(e) => setSelectedCheckDate(e.target.value)}
          >
            {checkDates.map((checkDate) => (<MenuItem value={checkDate}>{checkDate}</MenuItem>))}
            {loadingPeriodSelectionOptions && !checkDates.length && (<MenuItem>Loading...</MenuItem>)}
          </Select>
        </FormControl>
        {/* Alert that displays if missing configs for next check date */}
        {missingConfigsText && (
        <Alert severity="warning">
          <AlertTitle>{`Next check date is ${nextCheckDate}`}</AlertTitle>
          {`Missing configs for ${missingConfigsText} for ${nextCheckDate}`}
        </Alert>
        )}
      </div>
      <div className="flex flex-row gap-x-8 mt-8">
        {/* Config inputs */}
        <PeriodConfig
          alreadyUploaded={alreadyUploaded}
          selectedCheckDate={selectedCheckDate}
          selectedCompanyId={selectedCompanyId}
          companies={companies}
          selectedCheckDateDoc={selectedCheckDateDoc}
          setDisableManualUpload={updateDisableManualUpload}
        />
        <Divider orientation="vertical" flexItem />
        {/* Upload logs datagrid */}
        <div className="flex flex-col gap-y-4 min-h-96 w-full">
          <UploadLogsDataGrid
            selectedCompanyId={selectedCompanyId}
            selectedCheckDate={selectedCheckDate}
            setAlreadyUploaded={updateAlreadyUploaded}
          />
          {/* Manual invoice upload button */}
          <UploadInvoiceButton
            selectedCompanyId={selectedCompanyId}
            selectedCheckDate={selectedCheckDate}
            disableManualUpload={disableManualUpload}
          />
        </div>
      </div>
    </div>
  );
};
