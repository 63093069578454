import React from 'react';
import {
  ListItemText,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';

import {
  AdminPanelSettings, Analytics, Approval, Home, Logout, PendingActions,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import WestlandOnlyLogo from '../../assets/imgs/WestlandOnlyLogo.svg';
import navigateTo from '../api/navigation-api/NavigationAPI';
import { useAdmin } from '../api/user-api/UserAPI';
import { signOutHandler } from '../api/log-in-out-api/LogInOutAPI';

/**
 * The Navigation component is the grouping of different routes
 * throughout the application.
 * Utilizes the navigateTo call from the NavigationAPI to process routes
 * @constructor
 */
const NavigationBar = function () {
  const isAdmin = useAdmin();
  const location = useLocation();
  const drawerWidth = 265;
  const navigationItems = [
    {
      destination: '/',
      name: 'Home',
      adminRestricted: false,
      icon: <Home />,
    },
    {
      destination: '/associations',
      name: 'Pending Associations',
      adminRestricted: false,
      icon: <PendingActions />,

    },
    {
      destination: '/status',
      name: 'Job Status',
      adminRestricted: false,
      icon: <Approval />,

    },
    {
      destination: '/analytics',
      name: 'Analytics',
      adminRestricted: false,
      icon: <Analytics />,
    },
    {
      destination: '/admin',
      name: 'Admin Menu',
      adminRestricted: true,
      icon: <AdminPanelSettings />,
    },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#14171B',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <div
        className="mt-2 mr-4 flex justify-center"
        onKeyDown={() => navigateTo('/')}
        tabIndex={0}
        role="button"
        onClick={() => navigateTo('/')}
      >
        <img width="175" src={WestlandOnlyLogo} alt="Westland company logo" />
      </div>
      <Divider />
      <List>
        {navigationItems.map((navData) => {
          if (navData.adminRestricted && !isAdmin) return null;
          return (
            <ListItem
              className={location.pathname === navData.destination ? 'bg-black nav-button' : 'nav-button'}
              key={navData.name}
              disablePadding
            >
              <ListItemButton onClick={() => navigateTo(navData.destination)}>
                <ListItemIcon className="text-white">
                  {navData.icon}
                </ListItemIcon>
                <ListItemText primary={navData.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem
          className="nav-button"
          disablePadding
        >
          <ListItemButton onClick={signOutHandler}>
            <ListItemIcon className="text-white">
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>

  );
};

export default NavigationBar;
