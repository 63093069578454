import { Launch } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

/**
 * This cell is just a link to the transaction itself on its site of origin (e.g. so if this cell is in a Divvy transaction
 * row, it will link to the actual transaction on the Divvy website).
 * @param url link to transaction on site of origin
 */
// eslint-disable-next-line import/prefer-default-export
export const TransactionLinkCell = function ({ url }:{ url: string }) {
  return (
    <IconButton
      onClick={() => { window.open(url); }}
    >
      <Launch />
    </IconButton>
  );
};
