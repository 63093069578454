import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from 'notistack';
import {
  AdjustAssociation,
  AdjustUser,
  AdminPage,
  AnalyticsPage,
  ErrorPage,
  Jobs, JobStatus,
  LandingPage,
  LoginPage, NotFound, UploadPage, TransactionsPage,
} from './components';

import { CenteredCircularProgress } from './components/helper';
import history from './components/api/history/History';
import errorAPI from './components/api/error-api/ErrorAPI';
import { useUser } from './components/api/user-api/UserAPI';
import SwapJobs from './components/admin-page/swap-jobs/SwapJobs';
import { PayrollPage } from './components/payroll-page/PayrollPage';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || '');

/**
 * Provides the route navigation to all other pages.
 * Utilizes history to track and maintain page history.
 * @constructor
 */
const App = function () {
  const user = useUser();

  const navigate = useNavigate();
  const [historyEnabled, setHistoryEnabled] = useState(false);

  useEffect(() => {
    history.listen((location) => {
      navigate(
        location.location.pathname,
        { state: location.location.state },
      );
    });
    setHistoryEnabled(true);
  }, []);

  return (
    <>
      {historyEnabled && (
      <>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} />
        <Routes>
          <Route path="/">
            <Route path="login" element={<LoginPage />} />
            {errorAPI.doesErrorExist() && (<Route path="error" element={<ErrorPage />} />)}
            { user
              && (
              <>
                <Route index element={<LandingPage />} />
                <Route path="associations">
                  <Route index element={<Jobs />} />
                </Route>
                {/* Allows the 404 Page to be rendered if the user manually searches up the error page */}
                <Route path="admin">
                  <Route index element={<AdminPage />} />
                  <Route path="adjustAssociations" element={<AdjustAssociation />} />
                  <Route path="adjustUser" element={<AdjustUser />} />
                  <Route path="swapJobs" element={<SwapJobs />} />
                </Route>
                <Route path="analytics" element={<AnalyticsPage />} />
                <Route path="status" element={<JobStatus />} />
                <Route path="jobs" element={<Jobs />} />
                <Route path="upload" element={<UploadPage />} />
                <Route path="ramp-transactions" element={<TransactionsPage location="ramp-invoice" />} />
                <Route path="divvy-transactions" element={<TransactionsPage location="divvy-invoice" />} />
                <Route path="payroll-invoice" element={<PayrollPage />} />
                <Route path="*" element={<NotFound />} />
              </>
              )}
          </Route>
        </Routes>
      </>
      )}
      {!historyEnabled && (<CenteredCircularProgress />)}
    </>
  );
};

export default App;
