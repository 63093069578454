import { Box, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { LastRunStatusModalParams } from '../transactions-page-params';
import { UploadStatusMetadata } from '../../api/types/JobStatusTypes';
import { getCollectionDocs, getJobDoc } from '../../api/document-api/DocumentAPI';
import { TransactionJobAssociations } from '../transactions-page-types';
import { OptionType } from '../../api/types/AssociationTypes';

// Modal styling
const boxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  wordBreak: 'break-word',
};

/**
 * Generates fields to display based off upload status metadata
 * @param statusData last run status metadata
 * @param associations associations for related job
 */
const generateFields = (statusData: UploadStatusMetadata, associations: TransactionJobAssociations) => {
  const {
    severity, title, link, batchNumber, message, postFlightFlags,
  } = statusData;
  return [
    {
      name: 'Status: ', value: severity, conditional: false, link: false,
    },
    {
      name: 'Message: ', value: title, conditional: false, link: false,
    },
    {
      name: 'Message detail: ', value: message, conditional: false, link: false,
    },
    {
      name: 'Id: ', value: batchNumber?.toString(), conditional: true, link: false,
    },
    {
      name: 'Upload Link: ', value: link, conditional: true, link: true,
    },
    {
      name: 'Post-upload flag message: ', value: postFlightFlags, conditional: true, link: false,
    },
    {
      name: 'Associated Vendor: ', value: associations.payeeName, conditional: false, link: false,
    },

  ];
};

/**
 * Given the job id, retrieve the values for the associations made for this job i.e. the associated vendor ID
 * and the associated property ID.
 * @param jobId id of the job we are retrieving associations for
 */
const getAssociationValues = async (jobId: string) => {
  // using the job ID, get the related job doc that should contain the associations
  const jobDoc = await getJobDoc(jobId);
  if (!jobDoc) throw new Error(`No job doc found for job id ${jobId}`);
  // extract the associations as an array from the job doc.  this will probably only work for Transactions jobs.
  const associations = Object.values(
    jobDoc.associationSchema.association.jobs,
  ).flat() as { engine: string, value: string[] }[];
  // retrieve the associated payee/vendor ID
  const payeeAssociation = associations.find(({ engine }) => engine === 'payee');
  if (!payeeAssociation) throw new Error('Payee association not found in job');
  // payeeId here is actually {vendorId}-{locationId}, which is used to identify a specific vendor and vendor location
  const [payeeId] = payeeAssociation.value;
  return { payeeId };
};

/**
 * Given the job id, retrieve the display names for the associations made for this job i.e. the associated vendor name
 * and the associated property name.
 * @param jobId id of the job we are retrieving associations for
 */
const getJobAssociations = async (jobId: string) => {
  // retrieve  payee ID from related job
  const { payeeId } = await getAssociationValues(jobId);
  // retrieve payee association options from Firestore
  const payeeOptions = await getCollectionDocs('engine/payee/associations') as OptionType[];
  // using IDs, retrieve display names for associations.  If option can't be found, just short circuit to the ID
  const payeeName = payeeOptions.find((option) => option.value === payeeId)?.label || payeeId;
  return { payeeName };
};

// Default values for associations while they are loading
const associationsLoadingDefault = { payeeName: 'Loading...' };

/**
 * Displays metadata of the last run status for a given transaction.
 * @param statusData status metadata
 * @param modalOpen whether the modal is open
 * @param updateModalOpen modal open state setter
 * @param jobId id of the job that created this status
 */
// eslint-disable-next-line import/prefer-default-export
export const LastRunStatusModal = function ({
  statusData, modalOpen, updateModalOpen, jobId,
}: LastRunStatusModalParams) {
  // associations that were made for the job that this status is from
  const [associations, setAssociations] = useState<TransactionJobAssociations>(associationsLoadingDefault);

  const rowStyle = { marginBottom: '20px' };
  const fieldNameStyle = { fontWeight: '500' };
  const headerStyle = { fontSize: '20px', marginBottom: '20px' };

  const fields = generateFields(statusData, associations);

  // get associations made for this job
  useEffect(() => {
    getJobAssociations(jobId)
      .then((assocs) => setAssociations(assocs))
      .catch(() => { enqueueSnackbar('Failed to retrieve associations for this job', { variant: 'error' }); });
  }, []);

  return (
    <Modal
      open={modalOpen}
      onClose={() => updateModalOpen(false)}
    >
      <Box sx={boxStyle} className="flex flex-col">
        <div style={headerStyle}>Upload Response</div>
        {fields.map(({
          conditional, value, name, link,
        }) => {
          // conditional = conditionally display field if there is a value
          if (conditional && !value) return null;
          return (
            <div style={rowStyle}>
              <span style={fieldNameStyle}>{name}</span>
              {/* If the value is a hyperlink, format it as an anchor element */}
              {link && value ? <a href={value} target="_blank" rel="noreferrer">{value}</a> : value}
            </div>
          );
        })}
      </Box>
    </Modal>
  );
};
