import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { listCollections } from '../../../api/document-api/DocumentAPI';
import { translate } from '../../../api/string-api/StringAPI';

/**
 * SelectEngine is designed to retrieve a list of valid engines and return them to the user
 * for selection
 * @constructor
 */
const SelectEngine = function ({ engine, setEngine }:
{
  engine: string | null,
  setEngine: React.Dispatch<React.SetStateAction<string | null>>
}) {
  const [enginesList, setEnginesList] = useState<string[]>([]);

  /**
   * listCollections will retrieve the list of collection names within Firebase at a given path
   * Using this information, we can give the user a dropdown list of acceptable options to pick
   */
  useEffect(() => {
    const unsubscribe = listCollections(() => {}, setEnginesList, 'engine');
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {/* A simple Autocomplete to take a list of valid engines from the user */}
      {/* We take the new value, and tell the setSelection function to update 'selection.engine' to be the value */}
      <Autocomplete
        className="mt-4"
        disablePortal
        id="auto-complete-association-engine"
        noOptionsText={translate('LOADING_AUTOCOMPLETE')}
        renderInput={(params) => (
          <TextField {...params} label="Engine" />
        )}
        onChange={(event, value) => setEngine(value)}
        options={enginesList.sort()}
        value={engine}
      />
    </div>
  );
};

export default SelectEngine;
