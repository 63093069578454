import React from 'react';
import {
  TableCell, TableRow, TableHead, Box, TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Data, HeadCell, TableHeaderProps } from '../../api/types/JobStatusTypes';

/**
 * The table header is responsible for viewing the header values.
 * Additionally, provides the sort handler when applicable.
 * @param props
 * @constructor
 */
const TableHeader = function (props: TableHeaderProps) {
  const { order, orderBy, onRequestSort } = props;

  const headCells: readonly HeadCell[] = [
    {
      id: 'jobId',
      numeric: false,
      disablePadding: true,
      label: 'Job ID',
    },
    {
      id: 'displayName',
      numeric: false,
      disablePadding: false,
      label: 'User',
    }, {
      id: 'jobType',
      numeric: false,
      disablePadding: false,
      label: 'Job Type',
    }, {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status Code',
    },
    {
      id: 'timestamp',
      numeric: false,
      disablePadding: false,
      label: 'Timestamp',
    },
    {
      id: 'image',
      numeric: false,
      disablePadding: false,
      label: 'Image',
    },
  ];

  const createSortHandler = (property: keyof Data | 'blank') => (event: React.MouseEvent<unknown>) => {
    if (property === 'blank') return () => {};
    return onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
