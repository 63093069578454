/**
 * Represents the "GLType" of a GL Category.
 */
export enum GlCategoryType {
  OVERHEAD = 'Overhead',
  PER_DEPARTMENT = 'Per Department',
}

/**
 * Represents the boolean rules of a GL category.
 */
export interface BooleanCategoryRules {
  calculatedInBillBack: boolean,
  reconcilesToCash: boolean,
  passthrough: boolean,
  erContributions: boolean,
  offsetFromCash: boolean,
}

/**
 * Represents the GLCode rules of a GL category.
 */
export interface SelectGlCategoryRules {
  glCode: string,
  offsetGl: string,
  reimbursementGl: string
}

/**
 * Represents all rules of a GL category.
 */
export interface CategoryRules extends BooleanCategoryRules, SelectGlCategoryRules {
  glType: GlCategoryType,
}

/**
 * Represents a GL category, which is used in calculating payroll invoices.
 */
export interface GlCategory {
  displayName: string,
  rules: CategoryRules
}

/**
 * Represents a GL category document, including the doc ID (which is the ID of the GL category).
 */
export interface GlCategoryDoc extends GlCategory {
  pdocId: string
}

/**
 * Represents a payroll code mappings document, including the doc ID (payroll code) and the gl category ID that the
 * code is mapped to.
 */
export interface PayrollCodeMappingDoc {
  pdocId: string,
  glCategory: string
}

/**
 * Represents a payroll code.
 */
export interface PayrollCodeRow {
  code: string
}

/**
 * Represents a position code/division code combination that we map to a GL code, for payroll purposes.
 */
export interface PositionGLRow {
  positionCode: PositionCode,
  divisionCode: DivisionCode | null
}

/**
 * Represents a full position code mapping, including position code, division code, gl category ID and the GL code
 * that we are mapping to.
 */
export interface PositionCodeMapping {
  positionCode: string,
  glCategory: string,
  divisionCode: string,
  glCode: string
}

/**
 * Represents a position code.
 */
interface PositionCode {
  PositionCode: number,
  PositionName: string
}

/**
 * Represents a division code.
 */
interface DivisionCode {
  DivisionCode: string,
  DivisionName: string
}

/**
 * Represents a GL Account (pulled from Entrata).
 */
export interface GlAccount {
  '@attributes': { Id: string, BranchId: string },
  AccountNumber: string,
  AccountName: string,
  GlGroupType: string,
  GroupLocation: string,
  Description: string | null,
  Status: string,
  IsSystem: number
}

/**
 * Represents an object used to render a toggle or switch component to control a boolean GL Category rule.
 */
export interface CategoryRuleToggle {
  value: boolean,
  field: keyof BooleanCategoryRules,
  label: string
}

/**
 * Represents an object used to render a Select component to control a string (i.e. GL Code) GL Category rule.
 */
export interface CategoryRuleSelect {
  value: string,
  field: keyof SelectGlCategoryRules,
  label: string
}

/**
 * Represents any missing mappings that are required to be made by the user to generate payroll invoices.
 */
export interface MissingMappings {
  missingGlCategories: string[],
  unmappedPayrollCodes: string[],
  unmappedPositions: { category: string, positionCode: number, divisionCode: string }[]
}
