export const staggeredEntryContainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

export const staggeredEntryItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const loadingJobTitle = {
  hidden: {
    x: -100, opacity: 1, scale: 0, display: 'none',
  },
  visible: {
    display: 'initial',
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export const loadingJobStatus = {
  hidden: {
    y: 20, opacity: 1, scale: 0, display: 'none',
  },
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delay: 1,
    },
    display: 'initial',
  },
};

export const loadingJobTip = {
  hidden: {
    x: -200, opacity: 1, scale: 0, display: 'none',
  },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delay: 1,
    },
    display: 'initial',
  },
  exit: {
    x: 200,
    opacity: 0,
    scale: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const AssociationText = {
  hidden: {
    y: 20, opacity: 0, scale: 0, display: 'none',
  },
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delay: 1,
    },
    display: 'initial',
  },
};
