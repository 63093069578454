import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CenteredCircularProgress, Container, TitleSubtitle } from '../../helper';
import { watchDocument } from '../../api/document-api/DocumentAPI';
import { User } from '../../api/types/UserTypes';
import { staggeredEntryContainer } from '../../api/framer-motion-api/FramerMotionAPI';
import SelectUsers from './select-user/SelectUsers';
import SelectRoles from './select-roles/SelectRoles';
import SubmitCloudButton from '../submit-cloud-button/SubmitCloudButton';

/**
 * AdjustUser is responsible for retrieving the currently active users within the
 * project and providing their data to its subcomponents. By providing this information,
 * This component will effectively render a dropdown list of users and their associated permissions/roles.
 *
 * The AdjustUser component is the parent wrapper to two subcomponents
 * "SelectUsers" and "SelectRoles".
 *
 * @constructor
 */
const AdjustUser = function () {
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
  // The list of users
  // The returned list of allowed roles divided into categories. Ex: {roles: ['admin'], administrative: ['docsumo']}
  const [availableRoles, setAvailableRoles] = useState<{ [key:string]: string[] }>({ roles: [] });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>([]);

  const updateSelectedRoles = (roles: string[]) => {
    setSelectedUserRoles([...roles]);
  };

  // The requirement for submission is that a user is selected
  const rejectionHandler = () => !selectedUser;

  /*
    We'll watch the general role document to get a list of available roles to select.
    Additionally, we'll watch the user collection to get a list of available users to adjust
  */
  useEffect(() => {
    const unsubscribeRoles = watchDocument(setLoadingRoles, setAvailableRoles, 'general/roleData');
    return () => { unsubscribeRoles(); };
  }, []);

  return (
    <Container>
      { loadingRoles && (<CenteredCircularProgress />)}
      { !loadingRoles && (
        <motion.div
          variants={staggeredEntryContainer}
          initial="hidden"
          animate="visible"
        >
          <TitleSubtitle title="ADMIN_CREATE_TITLE" subtitle="ADMIN_CREATE_SUBTITLE" />
          <div className="font-montserrat flex mt-12">
            <SelectUsers
              setUser={setSelectedUser}
              setUserRoles={updateSelectedRoles}
              user={selectedUser}
            />
            <SelectRoles
              availableRoles={availableRoles}
              userRoles={selectedUserRoles}
              setUserRoles={updateSelectedRoles}
            />
          </div>
          <div className="flex flex-row-reverse">
            <SubmitCloudButton
              docId={selectedUser?.uid}
              rejectionHandler={rejectionHandler}
              functionHandler="updateUser"
              path="users"
              updateValue={{ updateValue: selectedUserRoles }}
            />
          </div>
        </motion.div>
      )}
    </Container>
  );
};

export default AdjustUser;
