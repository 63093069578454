/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { translate } from '../../api/string-api/StringAPI';
import { createAssociation } from '../../api/association-api/AssociationAPI';
import { SelectedInfoObject } from '../../api/types/AssociationTypes';
import { SnackbarAlert } from '../../helper';
import AssociationsConfirm from '../associations-confirm/AssociationsConfirm';

/**
 * The exported button component for submitting the association into the cloud
 * @param selectedInfo The list of selected fields for the association to associate to.
 * @param jobId The job Identifier
 * @param oneTimeLink The key value pair of whether a specific row is a one time link
 * @param resetInputs The helper function to reset all entered data. Prevents lingering data from populating a new job
 * @param updateDisplay: (state: string) => void -> Used to switch display states in order to hide the selection UI to the loading UI
 * @constructor
 */
const AssociationSubmit = function (
  {
    selectedInfo, oneTimeLink, resetInputs, updateDisplay,
  }:
  {
    oneTimeLink: { [key:string]: boolean };
    selectedInfo: SelectedInfoObject;
    resetInputs: () => void;
    updateDisplay: (state: string) => void;
  },
) {
  // Controls the dialogue prompt if the user doesn't have all fields selected
  const [dialogueOpen, setDialogueOpen] = useState(false);
  // Override control for if the users wishes to proceed with null fields
  const [userConfirm, setUserConfirm] = useState(false);
  const [timerId, setTimerId] = useState<number | undefined>();
  const [errorActive, setErrorActive] = useState(false);
  const mountedRef = useRef(true);

  const reset = (timer: number) => (
    setTimeout(() => {
      resetInputs();
      updateDisplay('visible');
    }, timer)
  );

  // Kicks of the sequence to create associations
  const initiateAssociations = () => {
    updateDisplay('hidden');
    setUserConfirm(false);
    setDialogueOpen(false);
    createAssociation(
      { selectedInfo, oneTimeLink },
      () => {},
      mountedRef,
    );
    const id = reset(30000);
    // @ts-ignore
    setTimerId(id);
  };

  /**
   * Is valid goes through the list of provided associations and makes sure that the requiredKey
   * Is assigned.
   */
  const isValid = () => Object.values(selectedInfo).every((associations) => {
    const isValidSubmit = Object.values(associations).every((association) => (
      (association.value && association.optionTypeSelection) || !association.requiredKey
    ));
    setErrorActive(!isValidSubmit);
    return isValidSubmit;
  });

  useEffect(() => {
    if (userConfirm) {
      initiateAssociations();
    }
  }, [userConfirm]);

  return (
    <div className="mt-auto flex-end flex flex-row-reverse ">
      <SnackbarAlert
        message={translate('ERROR_REQUIRED_FIELDS')}
        open={errorActive}
        setOpen={setErrorActive}
        status="error"
      />
      <AssociationsConfirm open={dialogueOpen} setOpen={setDialogueOpen} setConfirmation={setUserConfirm} />
      <Button
        className="text-lg westland-red"
        onClick={() => (
          isValid()
            ? initiateAssociations()
            : setDialogueOpen(true)
        )}
      >
        {translate('ASSOCIATION_FINISH_BUTTON')}
      </Button>
    </div>
  );
};

export default AssociationSubmit;
