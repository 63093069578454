import React, { useState } from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Container, GenericAutocomplete, TitleSubtitle } from '../../helper';
import SubmitCloudButton from '../submit-cloud-button/SubmitCloudButton';

const SwapJobs = function () {
  const [fromUser, setFromUser] = useState<{ [key:string]: string }>({});
  const [toUser, setToUser] = useState<{ [key:string]: string }>({});

  return (
    <Container>
      <TitleSubtitle title="ADMIN_ADJUST_CATEGORIES_TITLE" subtitle="ADMIN_ADJUST_CATEGORIES_SUBTITLE" />
      <div className="flex items-center mt-6">
        <GenericAutocomplete
          className="w-1/4"
          keyName="displayName"
          label="From User"
          path="users"
          onChange={(_, value) => {
            setFromUser(value || {});
          }}
          sortKey="displayName"
          selected={fromUser}
        />
        <span className="ml-8"><DoubleArrowIcon /></span>
        <span className="text-2xl ml-2 mr-2">GOING TO</span>
        <span className="mr-8"><DoubleArrowIcon /></span>
        <GenericAutocomplete
          className="w-1/4"
          keyName="displayName"
          label="To User"
          path="users"
          onChange={(_, value) => {
            setToUser(value || {});
          }}
          sortKey="displayName"
          selected={toUser}
        />
      </div>
      <div className="flex flex-row-reverse">
        <SubmitCloudButton
          rejectionHandler={(updateValue) => !updateValue.fromUser || !updateValue.toUser}
          docId="toUser"
          functionHandler="swapJobs"
          path="users"
          updateValue={{ fromUser: fromUser.uid, toUser: toUser.uid }}
        />
      </div>
    </Container>
  );
};

export default SwapJobs;
