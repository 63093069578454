import { signOut, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FirestoreError } from 'firebase/firestore';
import { auth } from '../../../firebase-config';
import errorHistory from '../error-api/ErrorAPI';
import navigateTo from '../navigation-api/NavigationAPI';

/**
 * Provides a Google sign-in popup.
 */
export const signIn = () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'select_account',
  });
  signInWithPopup(auth, provider)
    .then(() => {
      navigateTo('/');
    })
    .catch((error: FirestoreError) => {
      const { code, message } = error;
      errorHistory.setAndSend(code, message);
    });
};

/**
 * Signs a user out of their login session
 */
export const signOutHandler = () => {
  signOut(auth)
    .then(() => navigateTo('/login'))
    .catch((error: FirestoreError) => {
      errorHistory.updateErrorCode(error.code);
      errorHistory.updateErrorMessage(error.message);
      navigateTo('/error');
    });
};
