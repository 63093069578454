import { Bar } from '@nivo/bar';
// @ts-ignore
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import React from 'react';
import { AnalyticColors } from '../../../api/analyticis-api/AnalyticsAPI';

/**
 * A bar chart provided by nivo.
 * Displays the given dataset.
 * @param data The provided data.
 * @param indexBy The method of indexing
 * @constructor
 */
const BarChart = function ({ data, indexBy } : { data: any[], indexBy: string }) {
  return (
    <AutoSizer>
      {/* eslint-disable-next-line react/no-unused-prop-types */}
      {({ height, width }: { height: number, width: number }) => (
        <Bar
          height={height}
          width={width}
          enableGridX
          enableGridY={false}
          layout="horizontal"
          data={data}
          keys={[
            'inProgress',
            'completedJobs',
          ]}
          indexBy={indexBy}
          margin={{
            top: 20, right: 130, bottom: 140, left: 120,
          }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={AnalyticColors.barChart}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6,
              ],
            ],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -115,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#ffffff"
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: -2,
              translateY: 75,
              itemsSpacing: 98,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,

              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Bar chart"
          barAriaLabel={(e) => `${e.id}: ${e.formattedValue}: ${e.indexValue}`}
        />
      )}
    </AutoSizer>
  );
};

export default BarChart;
