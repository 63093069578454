import React, { useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { enqueueSnackbar } from 'notistack';
import ImageIcon from '@mui/icons-material/Image';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { functions } from '../../../firebase-config';
import { locationMap } from '../transactions-page-helpers';
import { TransactionLocation, ValidatedGenericTransaction } from '../transactions-page-types';

/**
 * Cell which represents Receipt Image for a given transaction.
 * @param params Datagrid row params
 * @param location transaction type (e.g. divvy/ramp)
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const ReceiptCell = function ({ params, location }:{
  params: GridRenderCellParams<ValidatedGenericTransaction>,
  location: TransactionLocation
}) {
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const { id, receiptIds } = params.row;

  /**
   * Retrieves receipt image url for transaction and opens it in a new tab.
   */
  const onClick = () => {
    setLoadingImage(true);
    const functionName = locationMap[location].getReceipts;
    const cloudFunction = httpsCallable(functions, functionName);
    cloudFunction({ transactionId: id })
      .then((res) => {
        // we only pull the first image for convenience - user can access all images by using the link in the Link col
        const [receiptUrl] = res.data as string[];
        window.open(receiptUrl);
      })
      .catch(() => enqueueSnackbar('Failed to retrieve receipt(s)', { variant: 'error' }))
      .finally(() => setLoadingImage(false));
  };

  const icon = receiptIds.length ? <ImageIcon className="my-auto" /> : <ImageNotSupportedIcon className="my-auto" />;

  return (
    <div className="flex flex-col h-full my-auto">
      <Tooltip title="This is a one-image preview - view all receipts via the Link column">
        <IconButton
          className="w-fit h-full"
          disabled={!receiptIds.length}
          onClick={onClick}
        >
          { loadingImage ? <CircularProgress className="my-auto" size={20} /> : icon }
        </IconButton>
      </Tooltip>
    </div>
  );
};
